import React from "react";
import { Row, Col } from "reactstrap";

export default class StatsSection extends React.Component {
    render() {
        return(
            <>
            <section>
                <div className="know-more-stats">
                    <h2 className="font-weight-bold text-red">Prevalência das Perturbações Alimentares em Portugal</h2>
                    <div className="mt-5 container-fluid">
                        <div className="text-black row">
                            <div className="px-3 mb-4 col">
                                <img src={require("assets/img/know-more/stats-1.png")} alt="prevalência-1" />
                                <div>
                                    <h4 className="font-weight-bold">
                                        Prevalência em jovens mulheres (13-19 anos)
                                    </h4>
                                    <div className="px-4">
                                        <small className="font-weight-500">
                                            Estudo de Machado, Machado, Gonçalves &amp; Hoek, 2007
                                        </small>
                                    </div>
                                </div>
                            </div>
                            <div className="px-3 mb-4 col">
                                <img src={require("assets/img/know-more/stats-2.png")} alt="prevalência-2" />
                                <h4 className="font-weight-bold">
                                    Prevalência em alunos do ensino secundário e ensino superior
                                </h4>
                                <div className="px-4">
                                    <small className="font-weight-500">
                                        Estudo de Machado, Gonçalves &amp; Hoek, 2013
                                    </small>
                                </div>
                            </div>
                            <div className="px-3 mb-4 col">
                                <img src={require("assets/img/know-more/stats-3.png")} alt="prevalência-3" />
                                <h4 className="font-weight-bold">
                                    Prevalência em mulheres com idade igual ou superior a 65 anos
                                </h4>
                                <div className="px-4">
                                    <small className="font-weight-500">
                                        Estudo de Conceição et al. (2017)
                                    </small>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            </>
        )
    }
}