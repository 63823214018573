import React from "react";
import PropTypes from 'prop-types';
import { Avatar } from 'components/Common'
import classnames from 'classnames'
import { CheckCircle } from "components/icons"

class Messages extends React.Component {
    _renderMessages = () => {
        const { messages, user } = this.props;

        if (messages.length === 0) {
            return (<div className="empty-chat">
                        <h3> Ainda não foram trocadas mensagens ... </h3>
                    </div>)
        }
        // should treat messages
        return messages.map((message, index) => {
            const classMsg = classnames({
                'chat': true,
                'chat-left': (message.user_id !== user.id),
                'chat-outline': (message.user_id === user.id),
                [message.color]: true
            })

            return (
                <div className={classMsg} key={index}>
                    <div className="chat-avatar">
                        <Avatar url={message.avatar} color={message.color} size={63} />
                    </div>
                    <div className="chat-body">
                        {message.messages.map((chat, i) => {
                            return (
                                <div className="chat-content" key={chat.id}>
                                    {(i === 0 && chat.user.id !== user.id) &&
                                        <div className="author">{chat.user.name}</div>
                                    }
                                    <div className="content">
                                        <div className="text">{chat.message}</div>
                                        <div className="status">
                                            {chat.viewed &&
                                                <div className="viewed">
                                                    <CheckCircle />
                                                </div>
                                            }
                                            <div className="date">{chat.date}</div>
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div> 
            )
        })
    }
    render() {
        return (
            <>
                <this._renderMessages/>
            </>
        )
    }
}
Messages.propTypes = {
    messages: PropTypes.array.isRequired,
    user: PropTypes.object.isRequired
}

export default Messages;
