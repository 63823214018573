import React from "react";
import { Row, Col } from "reactstrap";
import ReactHtmlParser from "react-html-parser";

import {
  XYPlot,
  XAxis,
  YAxis,
  HorizontalGridLines,
  VerticalBarSeries,
  LabelSeries
} from "react-vis";

class Chart extends React.Component {

  _getWidth = () => {
    return 1050;
  }

  _getBarWidth = () => {
    let { questionnaire_reference } = this.props;
    switch (questionnaire_reference) {
      case 'EDE-Q':
        return 0.405;
      case 'ED-15':
        return 0.243;
      case 'EADS-21':
        return 0.1199;
      case 'CIA':
      case 'EADS-21':
      case 'WAI-SR':
        return 0.16;
      case 'EDRE':
        return 0.28;
      case 'IIP-32':
        return 0.36;
    }
  }

  _renderLabelSeries = (renderWhat, index) => {
    let { chart, questionnaire_reference } = this.props;
    let output = null;

    if (renderWhat === 'data') {
      if (typeof chart.data[1] !== "undefined") {
        output = chart.data[index].map(obj => {
          return { ...obj, label: obj.y.toString().replace('.', ',') }
        });
      }
      else {
        output = chart.data[index].map(obj => {
          return { ...obj, label: obj.y.toString().replace('.', ',') }
        });
      }
    }
    else if (renderWhat === 'style') {
      if (typeof chart.data[1] !== "undefined") {
        if (index === 0)
          output = { fill: '#25afc4' };
        else
          output = { fill: '#434343' };
      }
      else {
        if (index === 0)
          output = { fill: '#25afc4' };
        else
          output = { fill: '#434343' };
      }
    }
    else if (renderWhat === 'className') {
      if (index === 0)
        output = questionnaire_reference + " labelSeriesFirst";
      else
        output = questionnaire_reference + " labelSeriesSecond";
    }
    return output;
  }

  _renderHorizontalGridLines = (renderWhat, index) => {
    let { chart, questionnaire_reference } = this.props;
    let output = null;

    if (renderWhat === 'style') {
      if (typeof chart.data[1] !== "undefined") {
        if (index === 0)
          output = { fill: '#25afc4', fillOpacity: 0.5 };
        else
          output = { fill: '#434343', fillOpacity: 1 };
      }
      else {
        if (index === 0)
          output = { fill: '#25afc4', fillOpacity: 0.5 };
        else
          output = { fill: '#434343', fillOpacity: 1 };
      }
    }
    else if (renderWhat === 'className') {
      if (index === 0)
        output = 'first ' + questionnaire_reference;
      else
        output = 'second ' + questionnaire_reference;
    }
    return output;
  }

  _renderXAxis = () => {
    let { chart, questionnaire_reference } = this.props;
    switch (questionnaire_reference) {
      case 'EDE-Q':
      case 'ED-15':
      case 'CIA':
      case 'EADS-21':
      case 'EDRE':
      case 'WAI-SR':
      case 'IIP-32':
        return (<>
          <div className={`row justify-content-md-center`}>
            <div className={`xaxis ${questionnaire_reference}`}>
              {chart.data[0].map((d, index) => {
                return <div key={index} className="col">{ReactHtmlParser(d.x)}</div>
              })}
            </div>
          </div>
        </>);
      default:
        return (
          <XAxis tickLabelAngle={-45} />
        );
    }
  }

  render() {

    const { chart, questionnaire_reference } = this.props;

    if (typeof chart === 'undefined')
      return null;

    return (
      <>
        {chart.data.legend &&
          <>
            <Row className="chart-legend">
              {chart.data.legend[0] &&
                <Col xs="2"><div className="symbol" style={{ backgroundColor: chart.data.legend[0].color }}></div>{chart.data.legend[0].label}</Col>
              }
              {chart.data.legend[1] &&
                <Col xs="2"><div className="symbol" style={{ backgroundColor: chart.data.legend[1].color }}></div>{chart.data.legend[1].label}</Col>
              }
            </Row>
          </>
        }
        <XYPlot className={`chart ${questionnaire_reference}`} margin={{ bottom: 70 }} xType="ordinal" width={this._getWidth()} height={300} yDomain={[chart.data.y_range[0], chart.data.y_range[1]]}>
          <HorizontalGridLines />
          <YAxis tickSizeInner={0} />
          <XAxis tickSizeInner={-1} />
          {this._renderXAxis()}
          <VerticalBarSeries padding={50} className={this._renderHorizontalGridLines('className', 0)} barWidth={this._getBarWidth()} fill={"#25afc4"} stroke={"unset"}
            data={chart.data[0]} style={this._renderHorizontalGridLines('style', 0)}
          />
          {chart.data[1] &&
            <VerticalBarSeries className={this._renderHorizontalGridLines('className', 1)} barPadding="10" barWidth={this._getBarWidth()} fill={"#d8d8d8"} stroke={"unset"}
              data={chart.data[1]}
            />
          }
          <LabelSeries
            data={this._renderLabelSeries('data', 0)}
            style={this._renderLabelSeries('style', 0)}
            className={this._renderLabelSeries('className', 0)}
            labelAnchorX="middle"
            labelAnchorY="text-after-edge" />
          {chart.data[1] &&
            <LabelSeries
              data={this._renderLabelSeries('data', 1)}
              style={this._renderLabelSeries('style', 1)}
              className={this._renderLabelSeries('className', 1)}
              labelAnchorX="middle"
              labelAnchorY="text-after-edge" />
          }
        </XYPlot>
      </>
    );
  }
}

export { Chart };
