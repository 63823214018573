import React, { memo } from 'react'
import {
    FacebookShareButton,
    LinkedinShareButton,
    TwitterShareButton,
    WhatsappShareButton,
    PinterestShareButton,
    RedditShareButton,
    TelegramShareButton,
    FacebookIcon,
    TwitterIcon,
    WhatsappIcon,
    LinkedinIcon,
    PinterestIcon,
    TelegramIcon,
    RedditIcon,
  } from 'react-share';

const ShareMedia = memo(({title, text, url, image}) => {
    return (
        <div className="container-share">
            <FacebookShareButton
                url={url}
                quote={title}
                className="network__share-button">
                <FacebookIcon size={32} round />
            </FacebookShareButton>
            <TwitterShareButton
                url={url}
                title={title}
                className="network__share-button">
                    <TwitterIcon size={32} round />
            </TwitterShareButton>
            <TelegramShareButton
                url={url}
                title={title}
                className="network__share-button">
                    <TelegramIcon size={32} round />
            </TelegramShareButton>
            <WhatsappShareButton
                url={url}
                title={title}
                separator=":: "
                className="network__share-button">
                    <WhatsappIcon size={32} round />
            </WhatsappShareButton>
            <LinkedinShareButton
                url={url}
                summary={text}
                windowWidth={750}
                windowHeight={600}
                className="network__share-button">
                    <LinkedinIcon size={32} round />
            </LinkedinShareButton>
            <PinterestShareButton
                url={String(window.location)}
                media={`${String(window.location)}/${image}`}
                windowWidth={1000}
                windowHeight={730}
                className="network__share-button">
                    <PinterestIcon size={32} round />
            </PinterestShareButton>
            <RedditShareButton
                url={url}
                title={title}
                windowWidth={660}
                windowHeight={460}
                className="network__share-button">
                    <RedditIcon size={32} round />
            </RedditShareButton>
        </div>
    )
})

export { ShareMedia };