import React from "react";
// reactstrap components
import { Modal, ModalFooter, Button } from "reactstrap";
import PropTypes from 'prop-types';
import { deleteObject } from 'services/requests_admin'
import { notify } from 'helpers'

class ModalDelete extends React.Component {

    state = {
        visible: false,
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (prevState.visible !== nextProps.visible) {
            return ({ visible: nextProps.visible })
        }
        return null;
    }

    render() {

        if (this.state.visible === false) {
            return null;
        }
        const { toggleModalDelete, reloadData, data, object } = this.props;

        const onDelete = async (id, object) => {

            toggleModalDelete();

            await deleteObject({id, object}).then(response => {
                reloadData(false); // reload data on list view
                notify('success', 'Eliminado com sucesso!');
            }).catch(error => {
                notify('error', error.message);
            })
        }

        return (
            <>
                <Modal className="modal-dialog-centered modal-app" modalClassName="animated fadeIn" isOpen={this.state.visible} fade={false} backdrop="static" toggle={() => toggleModalDelete()}>
                    <div className="modal-header">
                        <h5 className="modal-title"><span className="admin">•</span>Eliminar {data.name}</h5>
                    </div>
                    <div className="modal-body" style={{ fontSize: "1.5rem" }}>
                        Tem a certeza que pretende eliminar?
                </div>
                    <ModalFooter>
                        <Button color="danger" onClick={() => onDelete(data.id, object)}>Sim</Button>
                        <Button color="secondary" onClick={() => toggleModalDelete()}>Cancelar</Button>
                    </ModalFooter>
                </Modal>
            </>
        )
    }
}

ModalDelete.propTypes = {
    visible: PropTypes.bool.isRequired,
    toggleModalDelete: PropTypes.func.isRequired,
    reloadData: PropTypes.func.isRequired,
    object: PropTypes.string.isRequired
}

export default ModalDelete