import React, { useState, useEffect } from 'react'
import useForm from 'react-hook-form'
import { Input, InputFile, InputCheckbox, InputSelect } from "components/Common"
import { saveUser } from 'services/requests_admin'
import { notify, throwErrorMessage } from 'helpers'
import moment from 'moment';
import { handleFile } from 'helpers'

const UserForm = (props) => {

    const { register, handleSubmit, errors, setError } = useForm()
    const [avatar, setAvatar] = useState(null);
    const { color, toggleModal, reloadData, action, user, role, degree_levels, therapists, updateAvatar } = props;
    const [labelCV, setLabel] = useState(
        action === 'edit' && user.curriculum_vitae !== null
            ?
            (user.curriculum_vitae.name !== null ? user.curriculum_vitae.name : 'upload CV aqui')
            : 'upload CV aqui');
    const [cv, setCV] = useState(null)
    const [submited, setSubmited] = useState(false);

    useEffect(() => {
        if (user != null) {
            setAvatar(user.avatar)
            updateAvatar(user.avatar)
        }
        else {
            setAvatar(null)
            updateAvatar(null)
        }
    }, [updateAvatar, user]);

    const onSubmit = (data) => {
        setSubmited(true);
        data['avatar'] = avatar;
        data['role'] = role;
        data['therapist_id'] = data['therapist_id'] === null ? -1 : data['therapist_id'];
        if (role === "therapist") data['cv'] = cv; // set cv of state
        if (action === "edit") data["id"] = user.id;
        data['show_on_team'] = (role === "scientific_community" ? (document.getElementById('show_on_team').checked ? 1 : 0) : 0);
        data['active'] = (document.getElementById('active').checked ? 1 : 0);
        data["action"] = action;

        if (data['show_on_team'] === 1 && data['active'] === 1 && avatar === null) {
            notify('error', 'Tem que definir a foto do utilizador, para ser visível na secção Equipa na HomePage')
            setSubmited(false);
            return;
        }

        saveUser(data).then(response => {
            reloadData() // reload data on list view
            notify('success', response.message)
            toggleModal()
        }).catch(response => {
            throwErrorMessage(response, setError)
            setSubmited(false);
        })
    }

    const handleAvatar = (files) => {
        if (files[0]) {
            setAvatar(files[0])
            updateAvatar(URL.createObjectURL(files[0]))
        }
    }

    const urlButton = require("assets/img/buttons/add-admin-2@2x.png");

    let patient_inputs, therapist_inputs, scientific_community_inputs, super_admin_inputs;

    if (role === 'patient') {

        patient_inputs =

            <>
                <Input type="text" placeholder="" label="Nome *" id="name" name="name" error={errors.name} register={register}
                    defaultValue={action === 'edit' ? user.name : ''} />

                <Input type="date" placeholder="" label="Data de Nascimento *" id="birth_date" name="birth_date" error={errors.birth_date} register={register}
                    defaultValue={action === 'edit' ? moment(user.birth_date, 'DD-MM-YYYY').format('YYYY-MM-DD') : ''} />

                <Input type="text" placeholder="" label="Profissão *" id="occupation" name="occupation" error={errors.occupation} register={register}
                    defaultValue={action === 'edit' ? user.info : ''} />

                <InputSelect label="Nível de escolaridade" id="degree_level_id" name="degree_level_id" error={errors.degree_level_id} register={register}
                    value={action === 'edit' ? user.degree_level_id : '1'} defaultValue="1" options={degree_levels} />

                <Input type="email" placeholder="" label="E-mail *" id="email" name="email" error={errors.email} register={register}
                    defaultValue={action === 'edit' ? user.email : ''} />

                <Input type="tel" placeholder="" label="Contacto móvel *" id="phone" name="phone" error={errors.phone} register={register}
                    defaultValue={action === 'edit' ? user.phone : ''} />

                <Input type="tel" placeholder="" label="Contacto alternativo" id="alternative_phone" name="alternative_phone" error={errors.alternative_phone} register={register}
                    defaultValue={action === 'edit' ? user.alternative_phone : ''} />

                <Input type="text" placeholder="" label="Diagnóstico" id="status" name="status" error={errors.status} register={register}
                    defaultValue={action === 'edit' ? user.info : ''} />

                <Input type="textarea" rows={5} placeholder="" label="Observações" id="bio" name="bio" error={errors.bio} register={register}
                    defaultValue={action === 'edit' ? user.bio : ''} />

                <InputSelect label="Terapeuta *" id="therapist_id" name="therapist_id" error={errors.therapist_id} register={register}
                    defaultValue={action === 'edit' ? user.therapist_id : -1} options={therapists} />

            </>
    }

    if (role === 'therapist') {

        therapist_inputs =
            <>
                <Input type="text" placeholder="" label="Nome *" id="name" name="name" error={errors.name} register={register}
                    defaultValue={action === 'edit' ? user.name : ''} />

                <Input type="date" placeholder="" label="Data de Nascimento *" id="birth_date" name="birth_date" error={errors.birth_date} register={register}
                    defaultValue={action === 'edit' ? moment(user.birth_date, 'DD-MM-YYYY').format('YYYY-MM-DD') : ''} />

                <Input type="text" placeholder="" label="Profissão *" id="occupation" name="occupation" error={errors.occupation} register={register}
                    defaultValue={action === 'edit' ? user.info : ''} />

                <Input type="text" placeholder="" label="Número de Cédula Profissional, Ordem de Psicólogos ou Ordem dos Médicos *" id="professional_certificate" name="professional_certificate" error={errors.professional_certificate} register={register}
                    defaultValue={action === 'edit' ? user.professional_certificate : ''} />

                <Input type="email" placeholder="" label="E-mail *" id="email" name="email" error={errors.email} register={register}
                    defaultValue={action === 'edit' ? user.email : ''} />

                <Input type="tel" placeholder="" label="Contacto móvel *" id="phone" name="phone" error={errors.phone} register={register}
                    defaultValue={action === 'edit' ? user.phone : ''} />

                <Input type="text" placeholder="" label="Local de trabalho *" id="workplace_address" name="workplace_address" error={errors.workplace_address} register={register}
                    defaultValue={action === 'edit' ? user.info : ''} />

                <Input type="textarea" rows={5} placeholder="" label="Observações" id="bio" name="bio" error={errors.bio} register={register}
                    defaultValue={action === 'edit' ? user.bio : ''} />

                <InputFile file={user != null && typeof user.curriculum_vitae != 'undefined' ? user.curriculum_vitae : null} style={{label_file:{color:"#b3021c"}}} icon_color="#b3021c" show_change_icon={true} show_download_icon={user != null && user.curriculum_vitae != null && user.curriculum_vitae.url_download != null ? true : false}  label="CV" labelFile={labelCV} id="cv" name="cv" error={errors.curriculum_vitae} register={register}
                    onChange={(e) => { handleFile(e.target.files, setCV, setLabel) }} />

            </>
    }

    if (role === 'scientific_community') {

        scientific_community_inputs =
            <>
                <Input type="text" placeholder="" label="Nome *" id="name" name="name" error={errors.name} register={register}
                    defaultValue={action === 'edit' ? user.name : ''} />

                <Input type="date" placeholder="" label="Data de Nascimento *" id="birth_date" name="birth_date" error={errors.birth_date} register={register}
                    defaultValue={action === 'edit' ? moment(user.birth_date, 'DD-MM-YYYY').format('YYYY-MM-DD') : ''} />

                <Input type="text" placeholder="" label="Profissão *" id="occupation" name="occupation" error={errors.occupation} register={register}
                    defaultValue={action === 'edit' ? user.info : ''} />

                <InputSelect label="Nível de escolaridade" id="degree_level_id" name="degree_level_id" error={errors.degree_level_id} register={register}
                    value={action === 'edit' ? user.degree_level_id : '1'} defaultValue="1" options={degree_levels} />

                <Input type="text" placeholder="" label="Número de Cédula Profissional, Ordem de Psicólogos ou Ordem dos Médicos *" id="professional_certificate" name="professional_certificate" error={errors.professional_certificate} register={register}
                    defaultValue={action === 'edit' ? user.professional_certificate : ''} />

                <Input type="email" placeholder="" label="E-mail *" id="email" name="email" error={errors.email} register={register}
                    defaultValue={action === 'edit' ? user.email : ''} />

                <Input type="tel" placeholder="" label="Contacto móvel *" id="phone" name="phone" error={errors.phone} register={register}
                    defaultValue={action === 'edit' ? user.phone : ''} />

                <Input type="text" placeholder="" label="Local de trabalho *" id="workplace_address" name="workplace_address" error={errors.workplace_address} register={register}
                    defaultValue={action === 'edit' ? user.info : ''} />

                <Input type="textarea" rows={5} placeholder="" label="Observações" id="bio" name="bio" error={errors.bio} register={register}
                    defaultValue={action === 'edit' ? user.bio : ''} />

                <Input type="text" placeholder="" label="Especialidade" id="expertise" name="expertise" error={errors.expertise} register={register}
                    defaultValue={action === 'edit' ? user.expertise : ''} />
                {therapist_inputs}

                <InputCheckbox info="Se visível, tem que definir a foto do utilizador." label="Visível na HomePage" id="show_on_team" name="show_on_team" error={errors.show_on_team} register={register}
                    defaultValue={action === 'edit' ? (user.show_on_team === 1 ? true : false) : false} />
            </>

    }

    if (role === 'super_admin') {
        super_admin_inputs =
            <>
                <Input type="text" placeholder="" label="Nome *" id="name" name="name" error={errors.name} register={register}
                    defaultValue={action === 'edit' ? user.name : ''} />

                <Input type="email" placeholder="" label="E-mail *" id="email" name="email" error={errors.email} register={register}
                    defaultValue={action === 'edit' ? user.email : ''} />
            </>
    }

    return (

        <form onSubmit={handleSubmit(onSubmit)}>

            {patient_inputs}

            {therapist_inputs}

            {scientific_community_inputs}

            {super_admin_inputs}

            <InputCheckbox label="Ativo" id="active" name="active" error={errors.active} register={register}
                defaultValue={action === 'edit' ? (user.active === 1 ? true : false) : true} />

            <Input id="avatar-user" type="file" name="file" className="input-file" onChange={(e) => { handleAvatar(e.target.files) }} accept="image/jpg, image/jpeg, image/png" invisible={true} />

            <div className="button-form">
                <button disabled={submited} type="submit" className={`button-submit ${color}`}>
                    <img src={urlButton} alt="guardar" />
                    <div className="title">Guardar</div>
                </button>
            </div>
        </form>
    )
}

export default UserForm;