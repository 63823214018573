import React, { useState, useEffect } from "react";
import useForm from "react-hook-form";
import { Input, InputFile } from "components/Common";
import { saveArticle, updateArticle } from "services/requests";
import { notify, throwErrorMessage } from "helpers";

const AddArticleForm = props => {
  const { color, toggleModal, reloadData, mode, article } = props;
  const { register, handleSubmit, errors, setError, setValue } = useForm();
  const [readOnly, setReadOnly] = useState(
    article !== null && article.can_edit !== null ? !article.can_edit : false
  );
  const [labelFile, setLabel] = useState("upload artigo aqui");
  const [updated, setUpdate] = useState(false);
  const [file, setArticleFile] = useState(null);
  const [submited, setSubmited] = useState(false);

  useEffect(() => {
    setLabel(article !== null && article.file !== null
      ? article.file.name !== null
        ? article.file.name
        : (readOnly ? "Sem artigo." : "upload artigo aqui")
      : (readOnly ? "Sem artigo." : "upload artigo aqui"))
  }, [])

  const handleFile = (files) => {
    if(files[0]) {
      if(files[0].size < 10485760) { // lower than 5 mb
        setArticleFile(files[0])
        setLabel(files[0].name)
      }
    }
  }

  const onSubmit = data => {
    setSubmited(true);
    data.file = file; // set file of state
    if (mode === "edit") {
      data._method = "PATCH";
      setUpdate(true);
      updateArticle(article.id, data)
        .then(response => {
          toggleModal();
          reloadData(); // reload data on list view
          notify("success", response.message);
          setUpdate(false);
        })
        .catch(response => {
          throwErrorMessage(response, setError);
          setSubmited(false);
        });
    } else {
      saveArticle(data)
        .then(response => {
          reloadData(); // reload data on list view
          notify("success", response.message);
          toggleModal();
        })
        .catch(response => {
          throwErrorMessage(response, setError);
          setSubmited(false);
        });
    }
  };

  const urlButton = require("assets/img/buttons/add-orange-2@2x.png");

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      
      <Input
        type="text"
        placeholder="Título"
        label={!readOnly ? `Título *` : `Título`}
        id="title"
        name="title"
        error={errors.description}
        register={register}
        defaultValue={article != null ? article.title : ''}
        readOnly={readOnly}
        data={article != null ? article.title : 'Sem informação.'}
      />

      <Input
        type="textarea"
        rows={4}
        placeholder="Descrever material e métodos..."
        label={!readOnly ? `Material e Métodos *` : `Material e Métodos`}
        id="description"
        name="description"
        error={errors.description}
        register={register}
        defaultValue={article != null ? article.description : ''}
        readOnly={readOnly}
        data={article != null ? article.description : 'Sem informação.'}
      />

      <Input
        type="textarea"
        rows={4}
        placeholder="Descrever resultados..."
        label={!readOnly ? `Resultados *` : `Resultados`}
        id="result"
        name="result"
        error={errors.result}
        register={register}
        defaultValue={article != null ? article.result : ''}
        readOnly={readOnly}
        data={article != null ? article.result : 'Sem informação.'}
      />

      <Input
        type="textarea"
        rows={4}
        placeholder="Descrever conclusão e discussão..."
        label={!readOnly ? `Conclusão e Discussão *` : `Conclusão e Discussão`}
        id="conclusion"
        name="conclusion"
        error={errors.conclusion}
        register={register}
        defaultValue={article != null ? article.conclusion : ''}
        readOnly={readOnly}
        data={article != null ? article.conclusion : 'Sem informação.'}
      />

      <Input
        type="text"
        placeholder="Colocar embed link do vídeo..."
        label={!readOnly ? `Adicionar Vídeo *` : `Vídeo`}
        id="video_uri"
        name="video_uri"
        error={errors.video_uri}
        register={register}
        defaultValue={article != null ? article.video_uri : ''}
        readOnly={readOnly}
        data={article != null ? `<a style="color:#ff901b" target='_blank' href='${article.video_uri}' alt='${article.video_uri}'>${article.video_uri}` : 'Sem vídeo.'}
      />

      <InputFile
        file={article != null && typeof article.file != 'undefined' ? article.file : null} style={{label_file:{color:"#ff901b"}}} icon_color="#ff901b" show_change_icon={true} show_download_icon={article != null && article.file.url_download != null ? true : false} 
        label={/*!readOnly ? `Artigo *` : */`Artigo`}
        labelFile={labelFile}
        id="file"
        name="file"
        error={errors.file}
        register={register}
        onChange={e => {
          handleFile(e.target.files, setArticleFile, setLabel);
        }}
        readOnly={readOnly}
      />
      {!readOnly && (
        <div className="button-form">
          <button disabled={submited} type="submit" className={`button-submit ${color}`}>
            <img src={urlButton} alt="login" />
            <div className="title">Guardar</div>
          </button>
        </div>
      )}
    </form>
  );
};

export default AddArticleForm;
