import React from "react";
import { Modal } from "reactstrap";
import PropTypes from "prop-types";
import Fade from "react-reveal/Fade";
import AddArticleForm from "views/forms/AddArticleForm";

class AddArticle extends React.Component {
  state = {
    visible: false,
  };
  static getDerivedStateFromProps(nextProps, prevState) {
    if (prevState.visible !== nextProps.visible) {
      return { visible: nextProps.visible };
    }
    return null;
  }
  render() {
    if (this.state.visible === false) {
      return null;
    }
    const { toggleModal, color, reloadData, mode, article } = this.props;
    return (
      <>
        {this.state.visible && (
          <Fade duration={300}>
            <button
              className="button-close-modal-pride"
              aria-label="Close"
              data-dismiss="modal"
              type="button"
              onClick={() => toggleModal(null)}
            >
              <img
                src={require("assets/img/buttons/close.png")}
                alt="close"
                width={97}
                height={92}
              />
            </button>
          </Fade>
        )}
        <Modal
          className="modal-dialog-centered modal-app"
          modalClassName="animated fadeIn"
          isOpen={this.state.visible}
          fade={false}
          backdrop="static"
          toggle={() => toggleModal()}
        >
          <div className="modal-icon">
            <img
              src={require("assets/img/modals-icons/add-record.png")}
              alt="add-patient"
            />
          </div>
          {mode === "edit" && article != null && article.can_edit && (
            <>
              <div className="modal-header">
                <h5 className="modal-title">
                  <span className={color}>•</span>
                  Editar Artigo
                </h5>
              </div>
            </>
          )}
          {mode === "add" && (
            <>
              <div className="modal-header">
                <h5 className="modal-title">
                  <span className={color}>•</span>
                  Adicionar Artigo
                </h5>
              </div>
            </>
          )}
          <div className="modal-body">
            <AddArticleForm
              color={color}
              toggleModal={toggleModal}
              reloadData={reloadData}
              mode={mode}
              article={article}
            />
          </div>
        </Modal>
      </>
    );
  }
}

AddArticle.propTypes = {
  visible: PropTypes.bool.isRequired,
  toggleModal: PropTypes.func.isRequired,
  reloadData: PropTypes.func.isRequired,
  color: PropTypes.string.isRequired,
  mode: PropTypes.string,
  article: PropTypes.object,
};

export default AddArticle;
