import React from "react";

class SurveyQuestion extends React.Component {
    
    constructor(props) {
        super(props);
        this.state = {
            nextStepVisible: false,
            answer: null,
            value: 0
        };
    }

    _onOptionChanged = (answer, value) => {
        this.setState({ 
            nextStepVisible: true,
            answer: answer,
            value: value
        });
    };

    render() {

        const { visible, question, nextStep, prevStep } = this.props;
        const optionsHTML = [];

        question.options.forEach((option, index) => {
            optionsHTML.push(
                <li key={question.number+'-'+index}>
                    <input 
                        type="radio" 
                        value={index}
                        name={"question-"+question.number+"-checkboxes"}
                        id={"question-"+question.number+"-option-"+index} 
                        onChange={() => this._onOptionChanged(option.text, index)} 
                    />
                    <label 
                        htmlFor={"question-"+question.number+"-option-"+index}>
                        {option.text}
                    </label>
                </li>
            )
        })

        return(
            <section 
                id={"question-"+question.number+"-holder"} 
                className="surver-question" 
                style={visible ? {} : { display: 'none' }}>
                {question.number > 1 && 
                    <div className="survey-prev-button-holder">
                        <span id="prev-button" onClick={() => prevStep(question.number-1)}>
                            <svg 
                                xmlns="http://www.w3.org/2000/svg" 
                                width="15" 
                                height="15" 
                                viewBox="0 0 24 24" 
                                fill="none" 
                                stroke="currentColor" 
                                strokeWidth="3" 
                                strokeLinecap="round" 
                                strokeLinejoin="round" 
                                className="mr-2">
                                    <line x1="19" y1="12" x2="5" y2="12"></line>
                                    <polyline points="12 19 5 12 12 5"></polyline>
                            </svg>
                            Voltar
                        </span>
                    </div>
                }
                <div className="survey-title-holder">
                    <span className="px-2 py-1 rounded text-white label">Questionário de Triagem Rápido</span>
                </div>
                <div className="survey-question-holder">
                    <h1 className="font-weight-bold mr-2">{question.number}•</h1>
                    <div>
                        <h1 className="font-weight-bold">
                            {question.text}
                        </h1>
                        <p>
                            {question.description}
                        </p>
                    </div>
                </div>
                <div className="survey-answer-holder">
                    <ul>
                        {optionsHTML}
                    </ul>
                </div>
                <div className="survey-control-holder">
                    <p className="steps">{question.number}/6</p>
                    <div className="line"></div>
                    {this.state.nextStepVisible &&
                        <button 
                            type="button" 
                            id="next-button" 
                            className="survey-next-button" 
                            onClick={() => nextStep(question.number-1, this.state.answer, this.state.value)}>
                            <img src={require("assets/img/survey/next-button.png")} alt="next-icon" />
                            <p>Próximo</p>
                        </button>
                    }
                </div>
            </section>
        )
    }
}

export default SurveyQuestion;