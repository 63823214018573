import React from "react";
import ManualPatient from "views/platform/Patient/Manual";

class Manual extends React.Component {
    render() {
        const { role } = this.props;
        if(role === 'patient') {
            return <ManualPatient {...this.props} />
        }
        return null;
    }
}

export default Manual;