import axios from 'axios';
import { domainApi } from "./api";
import { SetRefreshToken, SetLogout } from "store/actions/auth.actions";
import store from 'store/store'
import { refreshToken } from 'services/requests'

let isRefreshing = false;
let subscribers = []

function onAccessTokenFetched(access_token) {
  subscribers = subscribers.filter(callback => callback(access_token))
}

function addSubscriber(callback) {
  subscribers.push(callback)
}

const http = axios.create({
  baseURL: domainApi,
  timeout: 25000,
  headers: {'Content-Type': 'application/json', 'Accept': 'application/json'},
});

//includes token store
http.interceptors.request.use(config => {
  let token = store.getState().auth.access_token;
  //if token exists
  if(token !== "" && token !== null) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
}, error => {
  const { response: { data } } = error;
  return Promise.reject(data);
})

http.interceptors.response.use(response => {
  return response;
}, error => {
  const { config, response: { data } } = error;
  const originalRequest = config;
  if (data.status === "Token is Expired") {
    //dispatch token auth refresh on store and get it
    if (!isRefreshing) {
      isRefreshing = true;
      let tokenSplit = config.headers.Authorization.split(" ");

      refreshToken(tokenSplit[1])
      .then(response => {
        store.dispatch(SetRefreshToken(response.access_token))
        .then(() => {
          isRefreshing = false;
          onAccessTokenFetched(response.access_token);
        })
      })
      .catch(error => {
        store.dispatch(SetLogout());
        return Promise.reject(error);
      });
    }

    //Promise to subscribe to the original request
    const retryOriginalRequest = new Promise((resolve) => {
      addSubscriber(access_token => {
        originalRequest.headers.Authorization = 'Bearer ' + access_token
        resolve(axios(originalRequest))
      })
    })

    return retryOriginalRequest
  }
  if(data.message === "User is not logged in.") {
    store.dispatch(SetLogout());
    return Promise.reject(error);
  }
  let dataError = data;
  dataError.status = error.response.status;
  return Promise.reject(dataError);
  
});

export { http }