import AdminLayout from './AdminLayout';

import Users from "views/admin/users/Users";
import Sections from 'views/admin/contents/Sections';
import Notices from 'views/admin/notices/Notices';
import Events from 'views/admin/events/Events';
import Faqs from 'views/admin/contents/Faqs';
// const Clinic = React.lazy(() => import('views/platform/Clinic'));

// https://github.com/ReactTraining/react-router/tree/master/packages/react-router-config
const routes = [
  { path: '/admin', exact: true, name: 'Admin', component: AdminLayout, protected: true },
  { path: '/admin/sections', exact: true, name: 'Sections', component: Sections, protected: true },
  { path: '/admin/notices', exact: true, name: 'Notices', component: Notices, protected: true },
  { path: '/admin/events', exact: true, name: 'Events', component: Events, protected: true },
  { path: '/admin/faqs', exact: true, name: 'Faqs', component: Faqs, protected: true },
  { path: '/admin/users', exact: true, name: 'Users', component: Users, protected: true },
];

export default routes;