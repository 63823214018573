import React, { useState } from 'react'
import useForm from 'react-hook-form'
import { Input } from "components/Common"
import { savePatient } from 'services/requests'
import { notify, throwErrorMessage } from 'helpers'

const AddPatientForm = (props) => {
  const { register, handleSubmit, errors, setError } = useForm()
  const [ avatar, setAvatar ] = useState(null);
  const { color, toggleModal, reloadData, updateAvatar } = props;

  const onSubmit = (data) => {
    data.avatar = avatar;
    //data.patient = true;
    savePatient(data).then(response => {
      reloadData() // reload data on list view
      notify('success', response.message)
      toggleModal()
    }).catch(response => {
      throwErrorMessage(response, setError)
    })
  }
  const handleAvatar = (files) => {
    if(files[0]) {
      setAvatar(files[0])
      updateAvatar(URL.createObjectURL(files[0]))
    }
  }

  const urlButton = require("assets/img/buttons/add-blue-2@2x.png");

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
        <Input type="text" placeholder="nome ou código" label="Nome ou Código do Utente *" id="name" name="name" error={errors.name} register={register} />

        <Input type="date" placeholder="selecionar data" label="Data de Nascimento *" id="birth_date" name="birth_date" error={errors.birth_date} register={register} />

        <Input type="text" placeholder="descrever profissão" label="Profissão *" id="occupation" name="occupation" error={errors.occupation} register={register} />

        <Input type="email" placeholder="e-mail de autenticação" label="E-mail do Utente *" id="email" name="email" error={errors.email} register={register} />

        <Input type="text" placeholder="contacto móvel" label="Contacto móvel *" id="phone" name="phone" error={errors.phone} register={register} />

        <Input type="text" placeholder="contacto alternativo" label="Contacto alternativo" id="alternative_phone" name="alternative_phone" error={errors.alternative_phone} register={register} />

        {/* <Input type="textarea" rows={3} placeholder="Diagonóstico do Utente" label="Diagonóstico" id="status" name="status" error={errors.status} register={register} /> */}
      
        <Input type="textarea" rows={5} placeholder="Descreva os seus últimos comportamentos e sintomas" label="Observações *" id="bio" name="bio" error={errors.bio} register={register} />

        <Input id="avatar-patient" type="file" name="file" className="input-file" onChange={(e) => { handleAvatar(e.target.files)}} accept="image/jpeg, image/png" invisible={true}  />

        <div className="button-form">
            <button type="submit" className={`button-submit ${color}`}>
              <img src={urlButton} alt="login" />
              <div className="title">Guardar</div>
            </button>
        </div>
    </form>
  )
}

export default AddPatientForm;