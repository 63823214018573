import React from "react";
import { Button } from 'components/Common'
import { Row, Col } from "reactstrap";

export default class AboutSection extends React.Component {
    render() {
        const { supportProject } = this.props;

        return(
            <>
            <section id="know-more-about">
                <div className="know-more-about">
                    <p className="text-header-stroke text-stroke-orange m-b-0 text-uppercase">Sobre o PRIDE</p>
                    <div className="p-0 mt-3 container-fluid">
                        <Row>
                            <Col>
                                <div className="col-content">
                                    <span>
                                        O PRIDE (Personalised Research Informed Interventions for Disordered Eating) é um projeto desenvolvido pelo Grupo de Estudos das Perturbações Alimentares (GEPA) da Escola de Psicologia da Universidade do Minho.
                                    </span>
                                    <div className="mt-5">
                                        <h3>A Missão PRIDE</h3>
                                        <span className="mt-4">
                                            Assentes nos princípios do rigor clínico, entusiamo pela procura de conhecimento baseado em evidência, e na colaboração entre investigadores, cuidadores e profissionais de saúde, pretendemos contribuir para o desenvolvimento do conhecimento na área das perturbações alimentares, na sua prevenção, diagnóstico e tratamento.
                                        </span>
                                    </div>
                                    <div className="mt-5">
                                        <h3>Valores PRIDE</h3>
                                        <span className="mt-4 ">
                                            Colaboração e partilha • Excelência e rigor • Inovação • Respeito e integridade • Dedicação e Compromisso
                                        </span>
                                        <br/>
                                        <br/>
                                        <div className="button-section m-t-28">
                                            <Button onPress={() => supportProject()} text={"Saiba + Como Apoiar"} color={"orange"} fontSize={20}/>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                            <Col>
                                <div className="col-content pillars-holder">
                                    <h3>Os pilares de atuação do PRIDE:</h3>
                                    <div className="background-orange text-white rounded mb-4 pillars">
                                        <span className="font-weight-bold">Investigação Científica •</span> 
                                        Desenvolver e disseminar investigação inovadora e relevante para informar a prática clínica dos profissionais de saúde mental.
                                    </div>
                                    <div className="background-orange text-white rounded mb-4 pillars">
                                        <span className="font-weight-bold">Intervenção Clínica •</span> 
                                        Disponibilizar o melhor tratamento clínico para pessoas com problemas do comportamento alimentar, procurando diagnósticos precoces e a oferta de tratamentos e práticas baseadas na evidência científica.
                                    </div>
                                    <div className="background-orange text-white rounded mb-4 pillars">
                                        <span className="font-weight-bold">Educação •</span> 
                                        Disseminar o conhecimento sobre as perturbações alimentares – através da sensibilização social e do treino e formação de terapeutas, da comunidade e de investigadores.
                                    </div>
                                    <div className="background-orange text-white rounded pillars">
                                        <span className="font-weight-bold">Políticas Públicas •</span> 
                                        Contribuir para informar o desenvolvimento de políticas públicas e sociais, através da tradução de descobertas científicas pioneiras em soluções que podem melhorar a qualidade de vida das pessoas com perturbações do comportamento alimentar.
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </div>
            </section>
            </>
        )
    }
}