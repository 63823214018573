import React from "react";
import { Container } from "reactstrap";
import Fade from 'react-reveal/Fade';
import Sidebar from './Chat/Sidebar';
import WriteMessage from './Chat/WriteMessage';
import Messages from './Chat/Messages';
import classnames from 'classnames';
import { throwErrorMessage } from 'helpers'
import { getContacts, getMessages, getChatId, refreshMessages } from "services/requests";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux'
import { SetChat } from "store/actions/chat.actions";
import { transformChatObject } from 'helpers'

class Colloquium extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            toggleSidebar: false,
            contacts: [],
            messages: [],
            hasChat: false,
            loading: false
        }
        this.timer = null;
    }
    _toggleSidebar = () => {
        this.setState({toggleSidebar: !this.state.toggleSidebar})
    }
    componentDidMount() {
        this._loadChat();
    }
    componentWillUnmount() {
        clearInterval(this.timer)
    }
    _loadChat = async(showBar = true, id = null) => {
        const { startPB, finishPB } = this.props;
        const { user_id } = this.props.chat;

        await this.setState({loading: true}, () => {
            if(showBar) { startPB() }
        })

        await getContacts().then(response => {
            this.setState({
                contacts: response,
                loading: false
            }, () => {
                if(user_id) {
                    this.setState({hasChat:true}, () => {
                        this._fetchMessages(user_id, true, false)
                    })
                } else {
                    if(showBar) { finishPB() }
                }
            })
        }).catch(error => {
            throwErrorMessage(error)
        })
    }
    _fetchMessages = async(id = null, reload = true, start = true) => {
        clearInterval(this.timer)
        const { hasChat } = this.state;
        const { startPB, finishPB } = this.props;
        const { chat_id } = this.props.chat;
        let chat_id_rsp = null;

        await this.setState({loading: true}, () => {
           if(start) { startPB(reload) }
        })

        await getChatId(id).then(response => {
            if(!hasChat) {
                this.setState({hasChat:true})
            }
            this.props.SetChat(id, response)
            chat_id_rsp = response;
        }).catch(error => {
            throwErrorMessage(error)
        })

        if(chat_id === chat_id_rsp && start) {
            this.setState({loading: false}, () => {
                finishPB(reload); 
            })
            return 
        }

        await getMessages(chat_id_rsp).then(response => {
            this.setState({
                messages: response,
                loading: false
            }, () => {
                finishPB(reload)
            })
        }).catch(error => {
            throwErrorMessage(error)
        })

        // boot refresh messages
        this._refreshMessages(chat_id_rsp)
        window.scrollTo(0,document.body.scrollHeight);
        
    }
    _addMessage = (message) => {
        this.setState({messages: [...this.state.messages, message]}, () => {
            window.scrollTo(0,document.body.scrollHeight);
        })
    }
    _scrollToBottom = () => {
        this.messagesEndRef.scrollIntoView({ behavior: "smooth" });
    }
    _refreshMessages = (chat_id) => {
        clearInterval(this.timer)
        this.timer = setInterval(() => 
            refreshMessages(chat_id).then(response => {
                if(response.length > 0) {
                    this.setState({messages: [...this.state.messages, ...response]}, () => {
                        window.scrollTo(0,document.body.scrollHeight);
                        this._refactorLastViews()
                    })
                }
            })
        , 3000)
    }
    _refactorLastViews = () => { // refactor last views messages
        let { messages } = this.state;
        messages = messages.map(message => {
            if(!message.viewed) {
                message.viewed = true;
            }
            return message;
        })
        this.setState({messages})
    }
    render() {
        const { toggleSidebar, contacts, hasChat, messages, loading } = this.state;
        const { color, isLoading, user } = this.props;
        const { chat_id, user_id } = this.props.chat;
        if(isLoading) {
            return ( <div className="loading-div"></div> )
        }
        const conversationClassNames = classnames({
            'conversation': true,
            'toggle': toggleSidebar,
            'empty': (!hasChat)
        })
        const messageBoxClassNames = classnames({
            'message-box': true,
            'toggle': toggleSidebar
        })
        return (
            <div className="position-relative">
                {/* shapes */}
                <section className={`section section-shaped section-app`}>
                    <div className={`shape shape-style-1 shape-app ${color}`}>
                        <span className="bottom-right" />
                    </div>
                    <Container className="d-flex">
                        {!loading &&
                            <Fade>
                                <div className={conversationClassNames}>
                                    {!hasChat ? (
                                        <div className="empty-chat">
                                            <h3> Selecione um utilizador para trocar mensagens </h3>
                                        </div>
                                    ) : (
                                        <Messages messages={transformChatObject(messages)} user={user} />
                                    )}
                                </div>
                            </Fade>
                        }
                        {hasChat &&
                            <WriteMessage color={color} classNames={messageBoxClassNames} chat_id={chat_id} addMessage={this._addMessage} />
                        }
                    </Container>
                    <Sidebar onToggle={this._toggleSidebar} color={color} contacts={contacts} fetchMessages={this._fetchMessages} userId={user_id} />
                </section>
            </div>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
      chat: state.chat
    }
}
const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({SetChat}, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(Colloquium);