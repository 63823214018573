import { http } from './http';

const getSection = async (section, multiple = false) => {

    let url;

    url = `home/get-section/${section}/${multiple}`;

    return http.get(url)
        .then(response => { return response.data })
        .catch(error => { throw error })
}

const getTeam = async () => {

    let url;

    url = `home/get-team`;

    return http.get(url)
        .then(response => { return response.data })
        .catch(error => { throw error })
}

const getFaqs = async (keyword = null) => {

    let url;

    if(keyword) {
        url = `home/get-faqs?search=${keyword}`;
    } else {
        url = `home/get-faqs`;
    }

    return http.get(url)
        .then(response => { return response.data })
        .catch(error => { throw error })
}

const subscriveNewsletter = async (data) => {

    let url;

    url = `newsletter/subscrive`;

    return http.post(url, data)
        .then(response => { return response.data })
        .catch(error => { throw error })
}

const getEventsThumbnails = async () => {

    let url;

    url = `home/get-events-thumbnails`;

    return http.get(url)
        .then(response => { return response.data })
        .catch(error => { throw error })
}

const getEventsCalendar = async () => {

    let url;

    url = `home/get-events-calendar`;

    return http.get(url)
        .then(response => { return response.data })
        .catch(error => { throw error })
}

const getEventsDateCalendar = async (date) => {

    let url;

    url = `home/get-events/${date}`;

    return http.get(url)
        .then(response => { return response.data })
        .catch(error => { throw error })
}

const getSingleEvent = async (id) => {

    let url;

    url = `home/get-event/${id}`;

    return http.get(url)
        .then(response => { return response.data })
        .catch(error => { throw error })
}

const getNoticesYears = async () => {

    let url;

    url = `home/get-notices-years`

    return http.get(url)
        .then(response => { return response.data })
        .catch(error => { throw error })
}

const getNoticesThumbnails = async (year) => {

    let url;

    url = `home/get-notices-thumbnails?year=${year}`

    return http.get(url)
        .then(response => { return response.data })
        .catch(error => { throw error })
}

const getNotice = async (id) => {

    let url;

    url = `home/get-notice/${id}`

    return http.get(url)
        .then(response => { return response.data })
        .catch(error => { throw error })

}

const getFooter = async () => {

    let url;

    url = `home/get-footer`

    return http.get(url)
        .then(response => { return response.data })
        .catch(error => { throw error })
}

const isValid = async (data, type) => {

    let url;

    url = `home/is-valid?type=${type}`

    return http.post(url, data)
        .then(response => { return response.data })
        .catch(error => { throw error })

}

export {
    getSection, getTeam, getFaqs, subscriveNewsletter, getEventsThumbnails, getEventsDateCalendar, getEventsCalendar, getSingleEvent, getNoticesYears, getNoticesThumbnails, getNotice, getFooter, isValid
}