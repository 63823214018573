import React from "react";
import { Row, Col } from "reactstrap";
import { Link } from "react-router-dom";

export default class TreatmentSection extends React.Component {
    render() {
        return(
            <>
            <section>
                <div className="know-more-treatment">
                    <h2 className="font-weight-bold text-center text-red">
                        Orientações para o Tratamento Psicológico das Perturbações Alimentares
                    </h2>
                    <div className="container-fluid">
                        <Row>
                            <Col>
                                <div className="col-content">
                                    <span className="mt-4 px-5 font-weight-500">
                                        Para pessoas com Perturbações Alimentares poderá ser necessário um acompanhamento multidisciplinar (e.g. médico/a de família, psicólogo/a, nutricionista, psiquiatra), que possa ajudá-lo/a durante todo o tratamento.
                                    </span>
                                    <span className="mt-4 d-block px-5 font-weight-500">
                                        A duração de um tratamento psicológico para as perturbações alimentares varia, em média, entre 20 a 40 sessões realizadas ao longo de um período de tempo que irá depender das características de cada caso clínico, à exceção da Anorexia Nervosa, cujo tratamento poderá prolongar-se.
                                    </span>
                                    <Link to="/questionario">
                                        <button className="background-red px-5 py-2 mt-4 text-white rounded font-weight-bold">
                                            Avaliar o meu risco
                                        </button>
                                    </Link>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </div>
            </section>
            </>
        )
    }
}