import axios from 'axios';
import { http } from './http';
import { domainApi } from './api';
import { convertToFormData } from 'helpers'

const refreshToken = async (token) => {
    return axios.patch(domainApi + "auth/refresh", [], { 'headers': { 'Authorization': 'Bearer ' + token } })
        .then(response => { return response.data })
        .catch(error => { throw error; });
}

// routes homepage modals
const addTherapist = async (data) => {
    return axios.post(domainApi + "home/propose", data)
        .then(response => { return response.data })
        .catch(error => {
            const { response: { data } } = error;
            return Promise.reject(data);
        })
}
const addSupport = async (data) => {
    return axios.post(domainApi + "home/support", data)
        .then(response => { return response.data })
        .catch(error => {
            const { response: { data } } = error;
            return Promise.reject(data);
        })
}

const savePatient = async (data) => {
    return http.post('patients/store', convertToFormData(data), { headers: { 'Content-Type': 'multipart/form-data' } })
        .then(response => { return response.data })
        .catch(error => { throw error })
}

const saveObservation = async (data) => {
    return http.post('notes/store', data)
        .then(response => { return response.data })
        .catch(error => { throw error })
}

const saveRecord = async (data) => {
    return http.post('records/store', data)
        .then(response => { return response.data })
        .catch(error => { throw error })
}

const updateRecord = async (id, data) => {
    return http.patch(`records/update/${id}`, data)
        .then(response => { return response.data })
        .catch(error => { throw error })
}

const getPatients = async (page = 1, order, orderBy, paginate = true) => {
    return http.get(`patients?page=${page}&order=${order}&orderBy=${orderBy}&paginate=${paginate}`)
        .then(response => { return paginate ? response.data : response })
        .catch(error => { throw error })
}

const getProfile = async (id) => {
    return http.get(`dashboard/${id}`)
        .then(response => { return response.data })
        .catch(error => { throw error })
}
const updatePatient = async (id, data) => {
    data._method = 'PATCH'
    return http.post(`dashboard/update/${id}`, convertToFormData(data), { headers: { 'Content-Type': 'multipart/form-data' } })
        .then(response => { return response.data })
        .catch(error => { throw error })
}

const getPatient = async (id) => {
    return http.get(`patients/${id}?with=records`)
        .then(response => { return response.data })
        .catch(error => { throw error })
}

const getRecordsPatient = async (id, page = 1, paginate = 3, filterBy) => {
    return http.get(`records?patient_id=${id}&with=notes&page=${page}&paginate=${paginate}&order=desc&orderBy=created_at&filterBy=${filterBy}`)
        .then(response => { return response.data })
        .catch(error => { throw error })
}

const getRecords = async (id, page = 1, order, orderBy) => {
    return http.get(`records?patient_id=${id}&with=notes&page=${page}&order=${order}&orderBy=${orderBy}`)
        .then(response => { return response.data })
        .catch(error => { throw error })
}

const getRecordNotes = async (id) => {
    return http.get(`notes?record_id=${id}`)
        .then(response => { return response.data })
        .catch(error => { throw error })
}

const saveArticle = async (data) => {
    return http.post('articles/store', convertToFormData(data), { headers: { 'Content-Type': 'multipart/form-data' } })
        .then(response => { return response.data })
        .catch(error => { throw error })
}

const updateArticle = async (id, data) => {
    return http.post(`articles/update/${id}`, convertToFormData(data), { headers: { 'Content-Type': 'multipart/form-data' } })
        .then(response => { return response.data })
        .catch(error => { throw error })
}

const getArticles = async (page = 1, order, orderBy) => {
    return http.get(`articles?page=${page}&order=${order}&orderBy=${orderBy}`)
        .then(response => { return response.data })
        .catch(error => { throw error })
}
const getArticle = async (id) => {
    return http.get(`articles/${id}`)
        .then(response => { return response.data })
        .catch(error => { throw error })
}

const getNotifications = async () => {
    return http.get('dashboard/alerts')
        .then(response => { return response.data })
        .catch(error => { throw error })
}

//chat
const getContacts = async () => {
    return http.get('chat/contacts')
        .then(response => { return response.data })
        .catch(error => { throw error })
}

const getMessages = async (id, not_viewed = false) => {
    return http.get(`chat/messages/${id}/${not_viewed}`)
        .then(response => { return response.data })
        .catch(error => { throw error })
}

const saveMessage = async (data) => {
    return http.post('chat/message', data)
        .then(response => { return response.data })
        .catch(error => { throw error })
}

const getChatId = async (user_id) => {
    return http.get(`chat/get-id/${user_id}`)
        .then(response => { return response.data })
        .catch(error => { throw error })
}
const refreshMessages = (id) => { // function to refresh the not viewed messages for the user on the chat
    return http.get(`chat/messages/${id}/true/true`)
        .then(response => { return response.data })
        .catch(error => { throw error })
}

const getQuestionnaires = async (page = 1) => {
    return http.get(`questionnaires?get=infos&paginate=5&page=${page}`)
        .then(response => { return response.data })
        .catch(error => { throw error })
}

const sendQuestionnaire = async (data) => {
    return http.post(`questionnaires/assign`, data)
        .then(response => { return response.data })
        .catch(error => { throw error })
}

const getQuestionnaire = async (action, reference, q_todo_id = -1) => {
    return http.get(`questionnaires/${action}/${reference}/${q_todo_id}`)
        .then(response => { return response.data })
        .catch(error => { throw error })
}

const getChart = async (id = null) => {
    return http.get(`questionnaires/resume-chart?id=${id}`)
        .then(response => { return response.data })
        .catch(error => { throw error })
}

const downloadFile = async (action, fileName, url) => {    
    return http.post(url, { name: fileName }, { responseType: 'arraybuffer' })
        .then(response => {
            var fileDownload = require('js-file-download');
            fileDownload(response.data, fileName);
        })
        .catch(error => { throw { message: 'Ficheiro não está disponível para descarregar.' } });
}

const submitQuestionnaire = async (reference, todo_id, data) => {
    return http.post(`questionnaires/submit/${reference}/${todo_id}`, data)
        .then(response => { return response.data })
        .catch(error => { throw error })
}

const submitSurvey = async (data) => {
    return axios.post("https://api.emailjs.com/api/v1.0/email/send", data)
        .then(response => { return response.data })
        .catch(error => {
            const { response: { data } } = error;
            return Promise.reject(data);
        })
}

const getManuals = async (role, page = 1) => {
    return http.get(`manuals/${role}?page=${page}`)
        .then(response => { return response.data })
        .catch(error => { throw error })
}

export {
    refreshToken,
    addTherapist,
    addSupport,
    savePatient,
    updatePatient,
    saveRecord,
    updateRecord,
    saveArticle,
    getPatients,
    getPatient,
    getRecordsPatient,
    saveObservation,
    getRecordNotes,
    getRecords,
    getArticles,
    getArticle,
    getNotifications,
    updateArticle,
    getProfile,
    getContacts,
    getMessages,
    saveMessage,
    getChatId,
    refreshMessages,
    getQuestionnaires,
    sendQuestionnaire,
    getQuestionnaire,
    submitQuestionnaire,
    submitSurvey,
    getChart,
    downloadFile,
    getManuals
}