import React from "react";
import { Row, Col } from "reactstrap";

export default class ProjectSection extends React.Component {
    render() {
        return(
            <>
            <section id="know-more-project">
                <div className="know-more-project">
                    <div className="container-fluid">
                        <Row className="">
                            <Col className="background-orange hero-col">
                                <img src={require("assets/img/know-more/semi-circle.png")} className="semi-circle top-right" alt="semi-circle" />
                                <div className="col-content">
                                    <div>
                                        <p className="m-0 text-left text-header-stroke text-stroke-white">
                                            PROJETO PRIDE
                                        </p>
                                        <p className="hero-text">
                                            O PRIDE é um projeto desenvolvido pelo Grupo de Estudos das Perturbações Alimentares da Escola de Psicologia da Universidade do Minho
                                        </p>
                                    </div>
                                    <button 
                                        className="know-more"
                                        onClick={() => this.props.handleClickScroll('know-more-about')}>
                                        <img src={require("assets/img/know-more/button.png")} alt="button" />
                                        <span>Saber +</span>
                                    </button>
                                </div>
                            </Col>
                            <Col className="background-red hero-col">
                                <img src={require("assets/img/know-more/semi-circle.png")} className="semi-circle bottom-right" alt="semi-circle" />
                                <div className="col-content">
                                    <div>
                                        <div>
                                            <p className="m-0 text-left text-uppercase text-header-stroke text-stroke-white">
                                                Perturbações
                                            </p>
                                            <p className="m-0 text-left text-uppercase text-header-stroke text-stroke-white">
                                                Alimentares
                                            </p>
                                        </div>
                                        <p className="hero-text">
                                            As Perturbações Alimentares são caracterizadas por alterações persistentes no padrão alimentar ou no comportamento associado à alimentação, comprometendo de forma significativa a saúde física e o funcionamento psicossocial
                                        </p>
                                    </div>
                                    <button 
                                        className="know-more" 
                                        onClick={() => this.props.handleClickScroll('know-more-whatis')}>
                                        <img src={require("assets/img/know-more/button.png")} alt="button" />
                                        <span>Saber +</span>
                                    </button>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </div>
            </section>
            </>
        )
    }
}