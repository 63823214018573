import React from "react";
import SurveyQuestion from "views/SurveySections/SurveyQuestion.jsx";
import SurveySubmit from "views/SurveySections/SurveySubmit.jsx";

class SurveySectionNew extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            steps: [
                {visible: true, answer: null},
                {visible: false, answer: null},
                {visible: false, answer: null},
                {visible: false, answer: null},
                {visible: false, answer: null},
                {visible: false, answer: null},
                {visible: false, answer: null}
            ]
        };
    }

    _nextStep = (step, answer, index) => {
        this.setState({ 
            steps: {
                ...this.state.steps,
                [step]: {
                    ['visible']: !this.state.steps[step].visible,
                    ['answer']: answer
                },
                [step+1]: {
                    ['visible']: !this.state.steps[step+1].visible
                }
            }
        });

        if(step > 4) {
            this.props.hideMenus();
        }

        this.props.updateFeedbackTotal(index, step);
    };

    _prevStep = (step) => {
        this.setState({ 
            steps: {
                ...this.state.steps,
                [step]: {
                    ['visible']: !this.state.steps[step].visible
                },
                [step-1]: {
                    ['visible']: !this.state.steps[step-1].visible
                }
            }
        });
    };

    render() {

        const { submitSurvey, feedbackA } = this.props;
        const questionsHTML = [];
        const questions = [
            {
                number: 1, 
                text: "Como é a sua relação com a comida?",
                description: "Por exemplo: a comida e a alimentação não geram nenhuma preocupação para si, ou é algo que está associado com preocupação e stress?",
                options: [
                    {text: "Sem preocupação ou stress"},
                    {text: "Um pouco problemática"},
                    {text: "Moderadamente problemática"},
                    {text: "Bastante problemática"},
                    {text: "Com muita preocupação e stress"}
                ]
            },
            {
                number: 2, 
                text: "O seu peso, corpo ou forma corporal fazem com que se sinta mal consigo?",
                description: "Por exemplo: o número na balança, a forma do seu corpo ou uma parte do seu corpo.",
                options: [
                    { text: "Nunca" },
                    { text: "Poucas vezes" },
                    { text: "Algumas vezes" },
                    { text: "Bastantes vezes" },
                    { text: "Sempre" }
                ]
            },
            {
                number: 3, 
                text: "Sente que a comida, o peso ou a forma corporal dominam a sua vida?",
                description: "Por exemplo: experienciando pensamentos constantes em torno da comida, peso ou do seu corpo.",
                options: [
                    { text: "Nunca" },
                    { text: "Poucas vezes" },
                    { text: "Algumas vezes" },
                    { text: "Bastantes vezes" },
                    { text: "Sempre" }
                ]
            },
            {
                number: 4, 
                text: "Sente ansiedade ou angústia quando não consegue controlar a sua alimentação?",
                description: "Por exemplo: quando outras pessoas cozinham ou preparam a refeição para si, ou come fora.",
                options: [
                    { text: "Nunca" },
                    { text: "Poucas vezes" },
                    { text: "Algumas vezes" },
                    { text: "Bastantes vezes" },
                    { text: "Sempre" }
                ]
            },
            {
                number: 5, 
                text: "Alguma vez sentiu que não iria ser capaz de parar de comer ou que perdeu o controlo sobre a alimentação?",
                description: "Por exemplo: sentimento de que não tem controlo sobre a comida, que come compulsivamente ou medo que possa comer compulsivamente.",
                options: [
                    { text: "Nunca" },
                    { text: "Poucas vezes" },
                    { text: "Algumas vezes" },
                    { text: "Bastantes vezes" },
                    { text: "Sempre" }
                ]
            },
            {
                number: 6, 
                text: "Quando pensa ter comido demasiado, faz alguma coisa para compensar?",
                description: "Por exemplo: saltar a próxima refeição, comer menos na refeição seguinte, compensar com exercício físico, provocar vómito ou usar laxantes, diuréticos ou produtos dietéticos.",
                options: [
                    { text: "Nunca" },
                    { text: "Poucas vezes" },
                    { text: "Algumas vezes" },
                    { text: "Bastantes vezes" },
                    { text: "Sempre" }
                ]
            },
        ];

        questions.forEach((question, index) => {
            questionsHTML.push(
                <SurveyQuestion 
                    key={index}
                    visible={this.state.steps[index].visible}
                    question={question}
                    nextStep={this._nextStep}
                    prevStep={this._prevStep}
                />
            )
        })
        
        return(
            <form
                id="survey-form">
                {questionsHTML}
                <SurveySubmit 
                    visible={this.state.steps[6].visible}
                    submitSurvey={submitSurvey}
                    answers={this.state.steps}
                    feedbackA={feedbackA}
                />
            </form>
        )
    }
}

export default SurveySectionNew;