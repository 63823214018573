import React, { memo } from 'react';

const Book = memo(() => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 50 50">
            <g fill="#25AFC4" fillRule="evenodd">
                <path d="M12.133 30.609l10.812 4.855V18.849L12.133 13.99V30.61zm12.38 8.843c-.22 0-.438-.043-.643-.134l-13.945-6.27A1.565 1.565 0 0 1 9 31.622V11.566a1.561 1.561 0 0 1 2.209-1.427l13.945 6.267c.563.252.925.812.925 1.426v20.056c0 .532-.27 1.028-.716 1.316a1.57 1.57 0 0 1-.85.248z"/>
                <path d="M25.922 18.85v16.614l10.812-4.855V13.99l-10.812 4.86zm-1.567 20.602c-.297 0-.593-.083-.85-.248a1.566 1.566 0 0 1-.716-1.316V17.832c0-.614.362-1.174.924-1.426L37.66 10.14a1.561 1.561 0 0 1 2.208 1.426v20.056c0 .614-.361 1.174-.924 1.426l-13.945 6.27c-.205.09-.424.134-.643.134z"/>
            </g>
        </svg>
    )
})

export { Book }