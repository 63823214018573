import React, { useState, useEffect } from "react";
import useForm from "react-hook-form";
import {
  Input,
  RichTextEditor,
  InputFile,
  InputCheckbox,
} from "components/Common";
import { saveEvent } from "services/requests_admin";
import { notify, throwErrorMessage } from "helpers";
import moment from "moment";
import { handleFile } from "helpers";

const EventForm = (props) => {
  const { register, handleSubmit, errors, setError } = useForm();
  const {
    color,
    toggleModal,
    reloadData,
    action,
    event,
    updateThumbnail,
  } = props;
  const [labelPoster, setLabel] = useState(
    action === "edit" && event.poster != null
      ? event.poster.name !== null
        ? event.poster.name
        : "upload poster aqui"
      : "upload poster aqui"
  );
  const [poster, setPoster] = useState(null);
  const [thumbnail, setThumbnail] = useState(null);
  const [submited, setSubmited] = useState(false);

  useEffect(() => {
    if (event != null) {
      setThumbnail(event.thumbnail);
      updateThumbnail(event.thumbnail);
    } else {
      setThumbnail(null);
      updateThumbnail(null);
    }
  }, [event, updateThumbnail]);

  const onSubmit = (data) => {
    setSubmited(true);
    data["thumbnail"] = thumbnail;
    data["poster"] = poster;
    let detail = document.getElementById("detail").value;

    if (action === "edit")
      data["detail"] = detail === "" ? event.detail : detail;
    else 
      data["detail"] = detail === "" ? detail : detail;

    if (detail.length > 1000000) {
      setSubmited(false);
      notify("error", 'O conteúdo do detalhe é demasiado grande.<br/>Evite colocar várias imagens de tamanho extenso.');
      return;
    }

    if (action === "edit") 
      data["id"] = event.id;

    data["action"] = action;
    data["active"] = document.getElementById("active").checked ? 1 : 0;

    saveEvent(data)
      .then((response) => {
        reloadData(); // reload data on list view
        notify("success", response.message);
        toggleModal();
      })
      .catch((response) => {
        setSubmited(false);
        throwErrorMessage(response, setError);        
      });
  };

  const handleThumbnail = (files) => {
    if (files[0]) {
      setThumbnail(files[0]);
      updateThumbnail(URL.createObjectURL(files[0]));
    }
  };

  const urlButton = require("assets/img/buttons/add-admin-2@2x.png");

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Input
          type="date"
          placeholder="selecionar data"
          label="Data de Publicação *"
          id="publish_at"
          name="publish_at"
          error={errors.publish_at}
          register={register}
          defaultValue={
            action === "edit"
              ? moment(event.publish_at, "DD-MM-YYYY").format("YYYY-MM-DD")
              : ""
          }
        />

        <Input
          type="date"
          placeholder="selecionar data"
          label="Data da Ação *"
          id="date"
          name="date"
          error={errors.date}
          register={register}
          defaultValue={
            action === "edit"
              ? moment(event.date, "DD-MM-YYYY").format("YYYY-MM-DD")
              : ""
          }
        />

        <Input
          type="text"
          placeholder="título"
          label="Título *"
          id="title"
          name="title"
          error={errors.title}
          register={register}
          defaultValue={action === "edit" ? event.title : ""}
        />

        <Input
          type="text"
          placeholder="tema"
          label="Tema *"
          id="subject"
          name="subject"
          error={errors.subject}
          register={register}
          defaultValue={action === "edit" ? event.subject : ""}
        />

        <Input
          type="number"
          step=".01"
          placeholder="gratuito"
          label={`Inscrição (€)`}
          id="cost"
          name="cost"
          error={errors.cost}
          register={register}
          defaultValue={action === "edit" ? event.cost : ""}
        />

        <Input
          type="number"
          placeholder=""
          label="Vagas *"
          id="openings"
          name="openings"
          error={errors.openings}
          register={register}
          defaultValue={action === "edit" ? event.openings : ""}
        />

        <RichTextEditor
          hiddenId="detail"
          id="detail-rte"
          name="detail-rte"
          label="Detalhe *"
          error={errors.detail}
          register={register}
          defaultValue={
            action === "edit" ? (event.detail === null ? "" : event.detail) : ""
          }
        />

        <InputFile
          file={event != null && typeof event.poster != 'undefined' ? event.poster : null} 
          style={{label_file:{color:"#434343"}}} 
          icon_color="#434343" 
          show_change_icon={true} 
          show_download_icon={event != null && event.poster != null && event.poster.url_download != null ? true : false}
          label="Poster"
          labelFile={labelPoster}
          id="poster"
          name="poster"
          error={errors.poster}
          register={register}
          onChange={(e) => {
            handleFile(e.target.files, setPoster, setLabel);
          }}
        />

        <InputCheckbox
          label="Ativa"
          id="active"
          name="active"
          error={errors.active}
          register={register}
          defaultValue={
            action === "edit" ? (event.active === 1 ? true : false) : true
          }
        />

        <input type="hidden" id="detail" name="detail" />

        <Input
          id="thumbnail-event"
          type="file"
          name="file"
          className="input-file"
          onChange={(e) => {
            handleThumbnail(e.target.files);
          }}
          accept="image/jpg, image/jpeg, image/png"
          invisible={true}
        />

        <div className="button-form">
          <button
            disabled={submited}
            type="submit"
            className={`button-submit ${color}`}
          >
            <img src={urlButton} alt="guardar" />
            <div className="title">Guardar</div>
          </button>
        </div>
      </form>
    </>
  );
};

export default EventForm;
