import React from "react";
import { Modal } from "reactstrap";
import PropTypes from 'prop-types';
import Fade from 'react-reveal/Fade';
import AddRecordForm from "views/forms/AddRecordForm";
import { Avatar } from "components/Common"

class AddRecordPatient extends React.Component {
    state = {
        visible: false,
    }
    static getDerivedStateFromProps(nextProps, prevState) {
        if(prevState.visible !== nextProps.visible) {
            return ({ visible: nextProps.visible })
        }
        return null;
    }
    render() {
        if(this.state.visible === false) {
            return null;
        }
        const { toggleModal, color, reloadData, patient, recordId, mode, record } = this.props;
        return (
            <>
            {this.state.visible &&
                <Fade duration={300}>
                    <button className="button-close-modal-pride" aria-label="Close" data-dismiss="modal" type="button" onClick={() => toggleModal()}>
                        <img src={require("assets/img/buttons/close.png")} alt="close" width={97} height={92} />
                    </button>
                </Fade>
            }
            <Modal className="modal-dialog-centered modal-app" modalClassName="animated fadeIn" isOpen={this.state.visible} fade={false} backdrop="static" toggle={() => toggleModal()}>
                <div className="modal-icon">
                    <div className="avatar-container">
                        <Avatar url={patient.avatar} color="blue" size={182} />
                    </div>
                </div>
                <div className="modal-header">
                    <h5 className="modal-title"><span className={color}>•</span>
                    {mode === 'add' ? (<React.Fragment>Adicionar Registo</React.Fragment>) : (<React.Fragment>Editar Registo</React.Fragment>)}</h5>
                </div>
                <div className="modal-body">
                    <AddRecordForm color="blue" toggleModal={toggleModal} reloadData={reloadData} patient={patient} recordId={recordId} mode={mode} record={record} />
                </div>
            </Modal>
            </>
        )
    }

}

AddRecordPatient.propTypes = {
    visible: PropTypes.bool.isRequired,
    toggleModal: PropTypes.func.isRequired,
    reloadData: PropTypes.func.isRequired,
    color: PropTypes.string.isRequired,
    mode: PropTypes.string,
    record: PropTypes.object,
}

export default AddRecordPatient