import React from "react";
import Slider from "react-slick";
import { Row, Col } from "reactstrap";
import { Link } from "react-router-dom";

function NextArrow(props) {
  const { className, onClick } = props;
  return (
    <button 
        className="button-slider-next"
        onClick={onClick}>
        <svg 
            xmlns="http://www.w3.org/2000/svg" 
            width="50" 
            height="50" 
            viewBox="0 0 24 24" 
            fill="none" 
            stroke="#fff" 
            strokeWidth="2" 
            strokeLinecap="round" 
            strokeLinejoin="round" >
                <polyline points="9 18 15 12 9 6"></polyline>
        </svg>
    </button>
  );
}

function PrevArrow(props) {
    const { className, onClick } = props;
    return (
      <button 
          className="button-slider-prev"
          onClick={onClick}>
          <svg 
              xmlns="http://www.w3.org/2000/svg" 
              width="50" 
              height="50" 
              viewBox="0 0 24 24" 
              fill="none" 
              stroke="#fff" 
              strokeWidth="2" 
              strokeLinecap="round" 
              strokeLinejoin="round" >
                  <polyline points="15 18 9 12 15 6"></polyline>
          </svg>
      </button>
    );  
}

export default class TypesSection extends React.Component {
    render() {
        const settings = {
            dots: true,
            infinite: true,
            speed: 500,
            slidesToShow: 3,
            slidesToScroll: 1,
            nextArrow: <NextArrow />,
            prevArrow: <PrevArrow />,
            responsive: [
              {
                breakpoint: 1024,
                settings: {
                  slidesToShow: 2,
                  slidesToScroll: 1,
                  infinite: true,
                  dots: true
                }
              },
              {
                breakpoint: 768,
                settings: {
                  slidesToShow: 1,
                  slidesToScroll: 1,
                  infinite: true,
                  dots: false
                }
              }
            ]
        };

        return(
            <>
            <section>
                <div className="know-more-types">
                    <img src={require("assets/img/know-more/top-left.png")} className="top-left" alt="top-left" />
                    <img src={require("assets/img/know-more/bottom-right.png")} className="bottom-right" alt="bottom-right" />
                    <h2 className="font-weight-bold text-white">
                        Perturbações Alimentares
                    </h2>
                    <span className="mt-4 text-white">
                        As Perturbações Alimentares são caracterizadas por alterações persistentes no padrão alimentar ou no comportamento associado à alimentação, comprometendo de forma significativa a saúde física e o funcionamento psicossocial. Para além disso, este tipo de perturbações carateriza-se pela preocupação excessiva com o peso, forma corporal e com a alimentação, resultando em comportamentos disfuncionais como forma de influenciar o peso e a forma corporal.
                    </span>
                    <div className="mt-5 px-5">
                        <Slider {...settings}>
                            <div className="h-28">
                                <div className="slide-item w-95">
                                    <h5>Anorexia Nervosa</h5>
                                    <small>
                                        É caracterizada pela restrição alimentar extrema, perda de peso significativa e medo intenso de ganhar peso. A restrição alimentar e a perda de peso resultam na carência alimentar e desnutrição, podendo levar a uma série de complicações físicas e psicológicas.
                                    </small>
                                    <button>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                                            <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path>
                                            <polyline points="7 10 12 15 17 10"></polyline>
                                            <line x1="12" y1="15" x2="12" y2="3"></line>
                                        </svg>
                                        <a 
                                            className="type-download"
                                            href="/files/DSN20200026-PRIDE-ManuaisPerturbacoesAlimentares-AnorexiaNervosa-P.01.pdf" 
                                            download="DSN20200026-PRIDE-ManuaisPerturbacoesAlimentares-AnorexiaNervosa-P.01.pdf">
                                            Descarregar
                                        </a>
                                    </button>
                                </div>
                            </div>
                            <div className="h-28">
                                <div className="slide-item w-95">
                                    <h5>Perturbação da Ingestão Alimentar Compulsiva</h5>
                                    <small>
                                        É caracterizada por episódios recorrentes de ingestão alimentar compulsiva. Estes episódios consistem na ingestão de uma quantidade de comida superior ao que a maioria das pessoas comeria num mesmo período de tempo, associada a uma sensação de perda de controlo.
                                    </small>
                                    <button>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                                            <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path>
                                            <polyline points="7 10 12 15 17 10"></polyline>
                                            <line x1="12" y1="15" x2="12" y2="3"></line>
                                        </svg>
                                        <a 
                                            className="type-download"
                                            href="/files/DSN20200026-PRIDE-ManuaisPerturbacoesAlimentares-BED-P.01.pdf" 
                                            download="DSN20200026-PRIDE-ManuaisPerturbacoesAlimentares-BED-P.01.pdf">
                                            Descarregar
                                        </a>
                                    </button>
                                </div>
                            </div>
                            <div className="h-28">
                                <div className="slide-item w-95">
                                    <h5>Bulimia Nervosa</h5>
                                    <small>
                                        É caraterizada por episódios de ingestão alimentar compulsiva, seguidos de comportamentos compensatórios com o objetivo de prevenir o aumento de peso (e.g., uso indevido de laxantes, diuréticos ou outras medicações).
                                    </small>
                                    <button>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                                            <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path>
                                            <polyline points="7 10 12 15 17 10"></polyline>
                                            <line x1="12" y1="15" x2="12" y2="3"></line>
                                        </svg>
                                        <a 
                                            className="type-download"
                                            href="/files/DSN20200026-PRIDE-ManuaisPerturbacoesAlimentares-BN-P.01.pdf" 
                                            download="DSN20200026-PRIDE-ManuaisPerturbacoesAlimentares-BN-P.01.pdf">
                                            Descarregar
                                        </a>
                                    </button>
                                </div>
                            </div>
                            <div className="h-28">
                                <div className="slide-item w-95">
                                    <h5>Considero que alguém que conheço possa ter uma Perturbação Alimentar</h5>
                                    <small>
                                        Familiares, amigos e cuidadores desempenham um papel crucial no cuidado, apoio e recuperação de pessoas com Perturbações Alimentares estando, em muitos casos, capazes de detetar os primeiros sinais e sintomas de uma Perturbação Alimentar e ajudar a procurar uma intervenção precoce.
                                    </small>
                                    <button>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                                            <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path>
                                            <polyline points="7 10 12 15 17 10"></polyline>
                                            <line x1="12" y1="15" x2="12" y2="3"></line>
                                        </svg>
                                        <a 
                                            className="type-download"
                                            href="/files/DSN20200026-PRIDE-ManuaisPerturbacoesAlimentares-IThinkSomeone-P.01.pdf" 
                                            download="DSN20200026-PRIDE-ManuaisPerturbacoesAlimentares-IThinkSomeone-P.01.pdf">
                                            Descarregar
                                        </a>
                                    </button>
                                </div>
                            </div>
                            <div className="h-28">
                                <div className="slide-item w-95">
                                    <h5>Perturbação da Alimentação e da Ingestão com Outra Especificação</h5>
                                    <small>
                                        É um termo utilizado para descrever comportamentos alimentares disfuncionais que têm um impacto significativo na vida do indivíduo, mas que não se enquadram noutro diagnóstico.
                                    </small>
                                    <button>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                                            <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path>
                                            <polyline points="7 10 12 15 17 10"></polyline>
                                            <line x1="12" y1="15" x2="12" y2="3"></line>
                                        </svg>
                                        <a 
                                            className="type-download"
                                            href="/files/DSN20200026-PRIDE-ManuaisPerturbacoesAlimentares-OSFED-P.01.pdf" 
                                            download="DSN20200026-PRIDE-ManuaisPerturbacoesAlimentares-OSFED-P.01.pdf">
                                            Descarregar
                                        </a>
                                    </button>
                                </div>
                            </div>
                            <div className="h-28">
                                <div className="slide-item w-95">
                                    <h5>Perturbações Alimentares: Mitos e Crenças</h5>
                                    <small>
                                        Existem vários mitos sobre as Perturbações Alimentares que podem comprometer a deteção precoce e o tratamento. É importante que todos compreendam os factos associados à saúde mental e às Perturbações Alimentares.
                                    </small>
                                    <button>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                                            <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path>
                                            <polyline points="7 10 12 15 17 10"></polyline>
                                            <line x1="12" y1="15" x2="12" y2="3"></line>
                                        </svg>
                                        <a 
                                            className="type-download"
                                            href="/files/DSN20200026-PRIDE-ManuaisPerturbacoesAlimentares-PA_Mitos-P.01.pdf" 
                                            download="DSN20200026-PRIDE-ManuaisPerturbacoesAlimentares-PA_Mitos-P.01.pdf">
                                            Descarregar
                                        </a>
                                    </button>
                                </div>
                            </div>
                            <div className="h-28">
                                <div className="slide-item w-95">
                                    <h5>Procurar ajuda o mais cedo possível</h5>
                                    <small>
                                        Se está preocupado/a com a sua relação com a alimentação ou com o seu corpo, é essencial receber aconselhamento, apoio e tratamento o mais rápido possível. Não deixe que as suas preocupações se transformem numa Perturbação Alimentar antes de procurar ajuda.
                                    </small>
                                    <button>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                                            <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path>
                                            <polyline points="7 10 12 15 17 10"></polyline>
                                            <line x1="12" y1="15" x2="12" y2="3"></line>
                                        </svg>
                                        <a 
                                            className="type-download"
                                            href="/files/DSN20200026-PRIDE-ManuaisPerturbacoesAlimentares-PA_Procurar-P.01.pdf" 
                                            download="DSN20200026-PRIDE-ManuaisPerturbacoesAlimentares-PA_Procurar-P.01.pdf">
                                            Descarregar
                                        </a>
                                    </button>
                                </div>
                            </div>
                            <div className="h-28">
                                <div className="slide-item w-95">
                                    <h5>Tenho uma Perturbação Alimentar?</h5>
                                    <small>
                                        Tentar combater uma Perturbação Alimentar sozinho/a não é fácil. É importante falar com alguém em quem confia, assim que notar que algo não está bem. Uma Perturbação Alimentar é uma doença que necessita de tratamento, tal como qualquer outra doença.
                                    </small>
                                    <button>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                                            <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path>
                                            <polyline points="7 10 12 15 17 10"></polyline>
                                            <line x1="12" y1="15" x2="12" y2="3"></line>
                                        </svg>
                                        <a 
                                            className="type-download"
                                            href="/files/DSN20200026-PRIDE-ManuaisPerturbacoesAlimentares-TenhoUmaPCA-P.01.pdf" 
                                            download="DSN20200026-PRIDE-ManuaisPerturbacoesAlimentares-TenhoUmaPCA-P.01.pdf">
                                            Descarregar
                                        </a>
                                    </button>
                                </div>
                            </div>
                        </Slider>
                    </div>
                </div>
            </section>
            </>
        )
    }
}