import React, { useState } from "react";
import useForm from "react-hook-form";
import { Input, InputCheckbox } from "components/Common";
import { saveFaq } from "services/requests_admin";
import { notify, throwErrorMessage } from "helpers";

const FaqForm = (props) => {
  const { register, handleSubmit, errors, setError } = useForm();
  const { color, toggleModal, reloadData, action, faq } = props;
  const [submited, setSubmited] = useState(false);

  const onSubmit = (data) => {
    setSubmited(true);
    data["content_type"] = "faq";
    if (action === "edit") data["id"] = faq.id;
    data["action"] = action;
    saveFaq(data)
      .then((response) => {
        reloadData(false); // reload data on list view
        notify("success", response.message);
        toggleModal();
      })
      .catch((response) => {
        throwErrorMessage(response, setError);
        setSubmited(false);
      });
  };

  const urlButton = require("assets/img/buttons/add-admin-2@2x.png");

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Input
        type="text"
        placeholder="questão"
        label="Questão *"
        id="title"
        name="title"
        error={errors.title}
        register={register}
        defaultValue={action === "edit" ? faq.question : ""}
      />

      <Input
        type="textarea"
        rows={10}
        label="Resposta *"
        id="detail"
        name="detail"
        error={errors.detail}
        register={register}
        defaultValue={action === "edit" ? faq.answer : ""}
      />

      <InputCheckbox
        label="Ativa"
        id="active"
        name="active"
        error={errors.active}
        register={register}
        defaultValue={
          action === "edit" ? (faq.active === 1 ? true : false) : true
        }
      />

      <div className="button-form">
        <button
          disabled={submited}
          type="submit"
          className={`button-submit ${color}`}
        >
          <img src={urlButton} alt="guardar" />
          <div className="title">Guardar</div>
        </button>
      </div>
    </form>
  );
};

export default FaqForm;
