import React from "react";
import { Link } from "react-router-dom";
import { Container } from "reactstrap";

class MobileRestricted extends React.Component {
    render() {
        return (
            <>
                <div className="app">
                    <div className="position-relative">
                    {/* shape Hero */}
                    <section className={`section section-shaped section-404`} style={{paddingTop: 0, paddingBottom:0}}>
                        <div className={`shape shape-style-1 shape-forbidden`}>
                            <span className="top-right" />
                            <span className="top-left" />
                            <span className="middle-right" />
                            <span className="middle-top" />
                            <span className="bottom" />
                            <span className="bottom-left" />
                        </div>
                        <Container className="container-forbidden">
                            <Link to="/" className="logo">
                                <img alt="logo" src={require("assets/img/brand/logotipo.png")} />
                            </Link>
                            <h5 className="message">
                                A plataforma só pode ser acedida por computador
                            </h5>
                            <Link to="/" className="link-return">
                                <div className="content">
                                    <img src={require("assets/img/buttons/back.png")} alt="back" width="50" />
                                    <span> Voltar à página inicial </span>
                                </div>
                            </Link>
                        </Container>
                    </section>
                    </div>
                </div>
            </>
        )
    }
}

export default MobileRestricted;