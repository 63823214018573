import React from "react";
import { Row, Col } from "reactstrap";
import { Button } from 'components/Common'

export default class FacilitiesSection extends React.Component {
    render() {
        const { supportProject } = this.props;

        return(
            <>
            <section id="unidades-de-tratamento">
                <div className="know-more-facilities">
                    <img src={require("assets/img/know-more/top-left.png")} className="top-left" alt="top-left" />
                    <img src={require("assets/img/know-more/bottom-right.png")} className="bottom-right" alt="bottom-right" />
                    <h2 className="font-weight-bold text-center text-white">
                        Unidades de Tratamento em Portugal
                    </h2>
                    <div className="container-fluid">
                        <Row>
                            <Col>
                                <div className="col-content">
                                    <span className="mt-4 font-weight-500">
                                        Em Portugal, o tratamento especializado nas Perturbações Alimentares ocorre, essencialmente, em três centros de tratamento especializados. As equipas que integram o tratamento são de caráter multidisciplinar, ou seja, disponibilizam acompanhamento em diversas vertentes, nomeadamente, na área da Psiquiatria, Psicologia, Nutrição e Enfermagem. Paralelamente, o internamento pode ser um outro tipo de tratamento também prestado nestas unidades hospitalares.
                                    </span>
                                    <span className="mt-4 d-block font-weight-500">
                                        Se precisar de auxílio para encontrar recursos na sua área de residência por favor contacte-nos através do formulário
                                    </span>
                                    <div className="button-section m-t-28">
                                        <Button onPress={() => supportProject()} text={"Saiba +"} color={"orange"} fontSize={20}/>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </div>
            </section>
            </>
        )
    }
}