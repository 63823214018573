import React from "react";
import { Link } from "react-router-dom";
import { connect } from 'react-redux'
import classnames from 'classnames';
import { UncontrolledCollapse, NavbarBrand, Navbar, NavLink, Nav, Row, Col } from "reactstrap";
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { Avatar } from "components/Common";
import { isMobile } from 'react-device-detect';

class PrideNavApp extends React.Component {
  state = {}
  componentDidMount() {
    // let headroom = new Headroom(document.getElementById("navbar-main"));
    // // // initialise
    // headroom.init();
  }
  _navClick = (section) => {
    this.setState({ selected: section }, () => {
      this.props.scrollSection(section)
    })
  }
  _renderNavElements = () => {
    const { role } = this.props.auth;
    const { pathname } = this.props.location;

    if (role === 'therapist') {
      return (
        <React.Fragment>
          <NavLink to="/app/library" tag={Link} className={classnames({ active: (pathname === '/app/library') })}> biblioteca </NavLink>
          <span className="shape-circle"> • </span>
          <NavLink to="/app/colloquium" tag={Link} className={classnames({ active: (pathname === '/app/colloquium') })}> colóquio científico </NavLink>
          <span className="shape-circle"> • </span>
          <NavLink to="/app/patients" tag={Link} className={classnames({ active: (pathname.includes('/app/patients') || pathname.includes('/app/questionnaires')) })}> consultório </NavLink>
        </React.Fragment>
      )
    } else if (role === 'scientific_community') {
      return (
        <React.Fragment>
          <NavLink to="/app/colloquium" tag={Link} className={classnames({ active: (pathname === '/app/colloquium') })}> colóquio científico </NavLink>
          <span className="shape-circle"> • </span>
          <NavLink to="/app/content" tag={Link} className={classnames({ active: (pathname === '/app/content') })}> conteúdo </NavLink>
        </React.Fragment>
      )
    } else { // patient
      return (<React.Fragment>
        <NavLink to="/app/library" tag={Link} className={classnames({ active: (pathname === '/app/library' || pathname === '/app/manual') })}> biblioteca </NavLink>
        {/* 
        NOTA:
        A pedido do cliente, deixa de haver a funcionalidade de registos.
        Caso volte a ter essa funcionalidade descomenta-se este bloco de código,
        para ficar na situação anterior em que devolve os registos com os questionários.
        Foram comentados os seguintes ficheiros:
            - pride-ui\src\views\platform\Patient\Library.jsx (dropdown de filtros)
            - pride-ui\src\views\platform\Patient\Patient.jsx (dropdown de filtros)
            - pride\app\Repositories\RecordRepository.php (obtenção dos dados)
        
        <span className="shape-circle"> • </span>
        <NavLink to="/app/monitor" tag={Link} className={classnames({ active: (pathname === '/app/monitor') })}> monitor </NavLink> */}
      </React.Fragment>
      )
    }
  }
  render() {

    if (isMobile) { // redirect forbidden
      this.props.history.push('/forbidden');
    }

    const { color, name, job, img_url } = this.props.auth;
    const { signOut, isLoading, history } = this.props;
    const { pathname } = this.props.location

    const navbarClass = classnames({
      "navbar-main": true,
      "navbar-light": true,
      "navbar-app": true,
      "fixed-top": true,
      [color]: color,
      "profile-shape": (pathname === '/app/profile')
    })

    return (

      <>
        <header className="header-global">
          <Navbar
            className={navbarClass} expand="lg" id="navbar-app">
            {!isLoading ? (
              <React.Fragment>
                <Nav className="navbar-nav-hover align-items-lg-center navbar-user" navbar>
                  {pathname !== '/app/profile' &&
                    <NavLink className="avatar-container" onClick={() => history.push('/app/profile')}>
                      <div className="marker">
                        <Avatar url={img_url} color={color} size={63} />
                      </div>
                      <div className="profile-details">
                        <div className="name">{name}</div>
                        <div className="job">{job}</div>
                      </div>
                    </NavLink>
                  }
                </Nav>
                <button className="navbar-toggler" id="navbar_global">
                  <span className="navbar-toggler-icon" />
                </button>
                <UncontrolledCollapse navbar toggler="#navbar_global">
                  <div className="navbar-collapse-header">
                    <Row>
                      <Col className="collapse-brand" xs="6">
                        <Link to="/">
                          <img alt="logo" src={require("assets/img/brand/logo-bold/logotipo.png")} />
                        </Link>
                      </Col>
                      <Col className="collapse-close" xs="6">
                        <button className="navbar-toggler" id="navbar_global">
                          <span />
                          <span />
                        </button>
                      </Col>
                    </Row>
                  </div>
                  <Nav className="navbar-nav navbar-center">
                    <this._renderNavElements />
                  </Nav>
                  <Nav className="align-items-lg-center ml-lg-auto navbar-right" navbar>
                    <NavbarBrand to={"/"} onClick={() => signOut()} tag={Link}>
                      <img alt="logo" src={require("assets/img/brand/logo-bold/logotipo.png")} />
                    </NavbarBrand>
                  </Nav>
                </UncontrolledCollapse>
              </React.Fragment>
            ) : (
                null
              )}
          </Navbar>
        </header>
      </>
    );
  }
}

PrideNavApp.propTypes = {
  user: PropTypes.object,
  signOut: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired
}

const mapStateToProps = (state, ownProps) => {
  return {
    auth: state.auth,
  }
}
const mapDispatchToProps = (dispatch) => {
  return {}
}

let NavbarPride = connect(mapStateToProps, mapDispatchToProps)(PrideNavApp);
export default withRouter(NavbarPride);
