import React from "react";
import Fade from "react-reveal/Fade";
// reactstrap components
import { Container, Row, Col } from "reactstrap";
import ReactHtmlParser from "react-html-parser";

import PropTypes from "prop-types";

class AnimatedSection extends React.Component {
  render() {
    const { color, shapes, content, labels } = this.props;
    return (
      <>
        <Fade>
          <div className="position-relative">
            <section
              className="section section-shaped section-scientific"
              id="section-scientific-investigation"
            >
              {/* Background Shapes Pride */}
              <div className={`shape shape-style-1 pride-shape ${color}`}>
                {shapes.map((shape, i) => (
                  <span className={shape} key={i} />
                ))}
              </div>
              <Container className="shape-container d-flex align-items-center">
                <div className="col px-0">
                  <Row className="align-items-center justify-content-center">
                    <Col className="text-center section-content" lg="12">
                      <p className="lead-large-title m-b-0">{content.title}</p>
                      <div className="description m-t-24">{content.detail}</div>
                      <div className="content-animated m-t-40">
                        <Fade left duration={1250}>
                          <div className="left-content">
                            <div className="text">{ReactHtmlParser(labels.label1)}</div>
                            <div className="circle"></div>
                          </div>
                        </Fade>
                        <Fade right duration={1250}>
                          <div className="right-content">
                            <div className="circle"></div>
                            <div className="text">{ReactHtmlParser(labels.label2)}</div>
                          </div>
                        </Fade>
                      </div>
                    </Col>
                  </Row>
                </div>
              </Container>
            </section>
          </div>
        </Fade>
      </>
    );
  }
}

AnimatedSection.propTypes = {
  color: PropTypes.string.isRequired
};

export default AnimatedSection;
