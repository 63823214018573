import React from "react";
import Fade from 'react-reveal/Fade';
import classnames from "classnames";
import { Container, Row, Col, FormGroup, InputGroup, Input, InputGroupAddon, InputGroupText } from "reactstrap";
import { Search } from 'react-feather';
import { getFaqs } from "services/requests_homepage";
import { throttle, debounce } from "throttle-debounce";

class Faq extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            selected: null,
            position: null,
            response: null,
            faqsLeft: [],
            faqsRight: [],
            faqs: [],
            q: ""
        }
        this.autocompleteSearchDebounced = debounce(500, this._autocompleteSearch);
        this.autocompleteSearchThrottled = throttle(500, this._autocompleteSearch);
    }
    componentDidMount() {
        this._fetchFaqs();
    }
    _fetchFaqs = async (keyword = null) => {
        await getFaqs(keyword).then(response => {
            let faqs = response;
            let halfFaqs = Math.floor(faqs.length / 2)
            let faqsLeft = (faqs.length > 1) ? faqs.slice(0, halfFaqs) : faqs;
            let faqsRight = (faqs.length > 1) ? faqs.slice(halfFaqs, faqs.length) : []
            this.setState({ faqsLeft, faqsRight, faqs });
        })
    }
    _filterFaq = (e) => {
        this.setState({ q: e.target.value }, () => {
            const q = this.state.q;
            if (q.length < 5) {
                this.autocompleteSearchThrottled(this.state.q);
            } else {
                this.autocompleteSearchDebounced(this.state.q);
            }
        });
    }
    _autocompleteSearch = (q) => {
        this._fetch(q)
    }
    _fetch = (q) => {
        this._fetchFaqs(q)
    }
    _selectQuestion = async (id, position, response = null) => {
        // verify if is click to close
        const { selected } = this.state;

        if (selected === id) {
            this.setState({ selected: null, position: null, response: null });
            return;
        }

        await this.setState({ response: null, position: null })
        this.setState({ selected: id, position, response })
    }
    render() {
        const { selected, position, response } = this.state;
        return (
            <>
                <Fade>
                    <div className="position-relative">
                        <section className="section section-shaped section-faq" id="section-faq">
                            {/* Background Shapes Pride */}
                            <div className="shape shape-style-1 pride-shape grey">
                                {/* <span className="span-150 top-left circle" /> */}
                                <span className="span-50 top-left m-5-shape m-3-l-shape" />
                                {/* <span className="span-500 left-center orange" /> */}
                                <span className="span-faq-left" />
                                <span className="span-faq-right" />
                            </div>
                            <Container className="shape-container d-flex align-items-center container-lg">
                                    <>
                                        <Row style={{ minWidth: '100%' }} className="faq-row">
                                            <Col className="text-center section-content" lg="12">
                                                <p className="lead-large-title m-b-0">
                                                    Em que podemos ajudar?
                                        </p>
                                                <div className="input-search-faq m-t-42">
                                                    <FormGroup className={classnames({ focused: this.state.search })} >
                                                        <InputGroup className="input-group-alternative mb-4">
                                                            <Input
                                                                type="text"
                                                                onFocus={e => this.setState({ search: true })}
                                                                onChange={this._filterFaq}
                                                                onBlur={e => this.setState({ search: false })}
                                                            />
                                                            <InputGroupAddon addonType="append">
                                                                <InputGroupText>
                                                                    <Search size={20} color={"#9c9898"} />
                                                                </InputGroupText>
                                                            </InputGroupAddon>
                                                        </InputGroup>
                                                    </FormGroup>
                                                </div>
                                            </Col>
                                            <Row style={{ minWidth: '100%', minHeight: 450 }} className="m-t-74">
                                                {(this.state.faqs.length > 0) ? (
                                                    <React.Fragment>
                                                        <Col lg="6" sm="12" className="has-divider questions-left">
                                                            <ul>
                                                                {position === "right" ? (
                                                                    <Fade down duration={300}>
                                                                        <li>{response}</li>
                                                                    </Fade>
                                                                ) : (
                                                                        <React.Fragment>
                                                                            {this.state.faqsLeft.map(faq => {
                                                                                return (
                                                                                    <li key={`faq-` + faq.id} onClick={() => this._selectQuestion(faq.id, "left", faq.detail)} className={classnames({ disabled: (selected !== faq.id) && selected })}>
                                                                                        {faq.title}
                                                                                    </li>
                                                                                )
                                                                            })}
                                                                        </React.Fragment>
                                                                    )}
                                                            </ul>
                                                        </Col>
                                                        <Col lg="6" sm="12" className="questions-right">
                                                            <ul>
                                                                {position === "left" ? (
                                                                    <Fade down duration={300}>
                                                                        <li>{response}</li>
                                                                    </Fade>
                                                                ) : (
                                                                        <React.Fragment>
                                                                            {this.state.faqsRight.map(faq => {
                                                                                return (
                                                                                    <li key={faq.id} onClick={() => this._selectQuestion(faq.id, "right", faq.detail)} className={classnames({ disabled: (selected !== faq.id) && selected })}>
                                                                                        {faq.title}
                                                                                    </li>
                                                                                )
                                                                            })}
                                                                        </React.Fragment>
                                                                    )}
                                                            </ul>
                                                        </Col>
                                                    </React.Fragment>
                                                ) : (
                                                        <Col lg="12" sm="12">
                                                            <h5 className="text-center no-results-white">
                                                                Sem FAQs para apresentar...</h5>
                                                        </Col>
                                                    )}
                                            </Row>
                                        </Row>
                                    </>
                                {/* {this.state.faqs.length <= 0 &&
                                    <Row>
                                        <Col lg="12" sm="12" className="section-header m-b-56">
                                            <h1 className="title">FAQ</h1>
                                            <div className="subtitle">
                                                Não existe informação para apresentar.
                                        </div>
                                        </Col>
                                    </Row>
                                } */}
                            </Container>
                        </section>
                    </div>
                </Fade>
            </>
        )
    }
}

export default Faq;