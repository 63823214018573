import React, { useState } from "react";
import useForm from "react-hook-form";
import { Input } from "components/Common";
import axios from "axios";
import { inviteUrl } from "services";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { SetLoginSuccess } from "store/actions/auth.actions";
import { throwErrorMessage } from "helpers";

const InviteForm = (props) => {
  const { register, handleSubmit, errors, setError } = useForm();
  const [submited, setSubmited] = useState(false);
  const { color } = props;
  const { params } = props;

  const onSubmit = (data) => {
    setSubmited(true);
    data["token"] = params.token;
    data["set_login"] = true;
    axios
      .post(inviteUrl, data)
      .then((response) => {
        let super_admin = false;
        //dispatch Login Success on store and set the session auth
        props.SetLoginSuccess(
          true,
          super_admin,
          response.data.access_token,
          response.data.user.id,
          response.data.user.name,
          response.data.user.email,
          response.data.user.avatar,
          response.data.user.info,
          response.data.user.address,
          response.data.user.color,
          response.data.user.roles[0]
        );

        super_admin = response.data.user.roles.includes("super_admin");

        if (super_admin) props.history.push("/admin");
        else {
          switch (response.data.user.roles[0]) {
            case "therapist":
              props.history.push("/app/patients");
              break;
            case "patient":
              props.history.push("/app/library");
              break;
            case "scientific_community":
              props.history.push("/app/content");
              break;
            default:
              props.history.push("/app");
              break;
          }
        }
      })
      .catch((error) => {
        setSubmited(false);
        if (error.response.status === 422)
          throwErrorMessage(
            {
              errors: error.response.data.errors,
              status: error.response.status,
            },
            setError
          );
        else props.history.push("/not-found");
      });
  };

  const urlButton = require("assets/img/buttons/right-" + color + "@2x.png");

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Input
        defaultValue={params.email}
        type="email"
        placeholder=""
        label="Email *"
        id="email"
        name="email"
        error={errors.email}
        register={register}
      />

      <Input
        type="password"
        placeholder=""
        label="Password *"
        id="password"
        name="password"
        error={errors.password}
        register={register}
      />

      <Input
        type="password"
        placeholder=""
        label="Confirmar password *"
        id="password_confirmation"
        name="password_confirmation"
        error={errors.password_confirmation}
        register={register}
      />

      <div className="button-form">
        <button
          disabled={submited}
          type="submit"
          className={`button-submit ${color}`}
        >
          <img src={urlButton} alt="login" />
          <div className="title">Entrar</div>
        </button>
      </div>
    </form>
  );
};

const mapStateToProps = (state, ownProps) => {
  return {};
};
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ SetLoginSuccess }, dispatch);
};

let InvitePage = connect(mapStateToProps, mapDispatchToProps)(InviteForm);
export default withRouter(InvitePage);
