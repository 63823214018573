import React from "react";
import { Container, Row, Col } from "reactstrap";
import Fade from "react-reveal/Fade";
import { Record, Book } from "components/icons";
import { Paginator, PaginatorFull } from "components/Common";
import { getManuals } from "services/requests";
import { throwErrorMessage } from "helpers";
import { Download } from "components/icons";
import { downloadFile } from "services/requests";
import ReactHtmlParser from "react-html-parser";

class Manual extends React.Component {
  state = {
    current_page: 1,
    max_page: 1,
    manual: {},
    loading: false
  };
  componentDidMount() {
    this._fetchManual();
  }
  _fetchManual = async (showBar = true) => {
    const { startPB, finishPB } = this.props;
    const { current_page } = this.state;

    await this.setState({ loading: true }, () => {
      if (showBar) {
        startPB();
      }
    });

    getManuals("patient", current_page)
      .then(response => {
        this.setState(
          {
            manual: response.data[0],
            max_page: response.meta.last_page,
            loading: false
          },
          () => {
            if (showBar) {
              finishPB();
            }
          }
        );
      })
      .catch(error => {
        throwErrorMessage(error);
      });
  };
  _movePage = async dir => {
    let { current_page } = this.state;
    let value = dir === "left" ? current_page - 1 : current_page + 1;
    this.setState({ current_page: value }, () => {
      this._fetchManual(false); // should not simulate progress bar
    });
  };
  _renderManualContent = manual => {
    let output = ReactHtmlParser(manual.content);

    if (manual.files) {
      output = (
        <>
          {output}
          {manual.files.data.map((f, index) => {
            return (
              <Row key={index} className="download-file blue">
                <div className="svg-icon">
                  <Download fill="#25AFC4" />
                </div>{" "}
                <span
                  onClick={() =>
                    this._downloadManual(f.name, manual.files.url)
                  }
                >
                  {f.name}
                </span>
              </Row>
            );
          })}
        </>
      );
    }
    return output;
  };
  _downloadManual = async (fileName, fileUrl) => {
    await downloadFile(null, fileName, fileUrl).catch(error => {
      throwErrorMessage(error);
    });
  };
  render() {
    const { max_page, current_page, manual } = this.state;
    const { color, isLoading, history } = this.props;

    // if (process.env.NODE_ENV === "production") {
    //   history.push("/soon");
    // }

    if (isLoading) {
      return <div className="loading-div"></div>;
    }
    return (
      <div className="position-relative">
        {/* shape Hero */}
        <section className={`section section-shaped section-app`}>
          <div className={`shape shape-style-1 shape-app ${color}`}>
            <span className="bottom-left-4" />
            <span className="bottom-right" />
          </div>
          <Fade>
            <Container className="d-flex container-lg">
              <Row
                style={{
                  width: "100%",
                  marginLeft: "5px",
                  marginRight: "25px"
                }}
              >
                <Col lg="9" className={`col-manual ${color}`} md="12">
                  <h1 className="title">
                    <span>•</span> {manual.title}
                  </h1>
                  <h5 className="legend">{manual.sub_title}</h5>
                  <div className="content-manual m-t-10">
                    {this._renderManualContent(manual)}
                  </div>
                  <div className="paginator">
                    <Paginator
                      color={"blue"}
                      currentPage={current_page}
                      maxPage={max_page}
                      movePage={this._movePage}
                    />
                    {/* <PaginatorFull /> */}
                  </div>
                </Col>
              </Row>
            </Container>
          </Fade>
          <div className={`menu-app ${color}`}>
            <Fade>
              <button
                className="button-menu"
                onClick={() => history.push("/app/library")}
              >
                <div className="container-icon">
                  <div className="svg-icon">
                    <Record />
                  </div>
                </div>
                <div className="text-button">
                  <span>•</span> Report do <br /> Utente
                </div>
              </button>
              <button className="button-menu active">
                <div className="container-icon">
                  <div className="svg-icon">
                    <Book />
                  </div>
                </div>
                <div className="text-button">
                  <span>•</span> Manuais de <br /> Autoajuda
                </div>
              </button>
            </Fade>
          </div>
        </section>
      </div>
    );
  }
}

export default Manual;
