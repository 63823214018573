import React from "react";
import {
  getPatient,
  getRecordsPatient,
  getQuestionnaire,
  getChart
} from "services/requests";
import { throwErrorMessage, setOpacityBackdropModal } from "helpers";
import { Container, Row, Col } from "reactstrap";
import { Record, Book } from "components/icons";
import { Avatar, Paginator, InputSelect, ResumeChart } from "components/Common";
import Fade from "react-reveal/Fade";
import RecordItem from "views/platform/Patient/RecordItem";
import { withRouter } from "react-router-dom";
import QuestionnaireItem from "views/platform/Shared/QuestionnaireItem";
import QuestionnaireModal from "views/modals/QuestionnaireModal";

class Library extends React.Component {
  state = {
    patient: {},
    records: [],
    current_page: 1,
    max_page: 1,
    loading: false,
    isOpen: false,
    collapse: null,
    filterBy: "all",
    modalQuestionnaire: false,
    action: "todo",
    questionnaire: {},
    questionnaire_page: 1,
    q_todo_id: null,
    chart: {},
    started_time: null
  };
  componentDidMount() {
    this._fetchPatient();
    this._fetchChart();
  }
  _toggleModalQuestionnaire = async (
    close = true,
    action = null,
    reference = null,
    q_todo_id = null
  ) => {
    if (!close) {
      await getQuestionnaire(action, reference, q_todo_id)
        .then(response => {
          this.setState({
            started_time: new Date(),
            questionnaire: response,
            action: action,
            q_todo_id: q_todo_id
          });
        })
        .catch(error => {
          throwErrorMessage(error);
        });
    }
    this.setState({ modalQuestionnaire: !this.state.modalQuestionnaire });
    setOpacityBackdropModal(
      this.state.modalQuestionnaire === true ? "1" : "0.95"
    );
  };
  _toggle = event => {
    this.setState({
      collapse: this.state.collapse === Number(event) ? 0 : Number(event)
    });
  };
  _fetchPatient = async (showBar = true, getPatientData = true) => {
    const { startPB, finishPB } = this.props;
    const { current_page, filterBy } = this.state;
    const id = this.props.user.id;

    await this.setState({ loading: true }, () => {
      if (showBar) {
        startPB();
      }
    });

    if (getPatientData) {
      // get patient
      await getPatient(id)
        .then(response => {
          this.setState({ patient: response });
        })
        .catch(error => {
          throwErrorMessage(error);
        });
    }

    // get records
    await getRecordsPatient(id, current_page, 8, filterBy)
      .then(response => {
        this.setState(
          {
            records: response.data,
            max_page: response.meta.last_page,
            collapse: response.data.length !== 0 ? response.data[0].id : null,
            loading: false
          },
          () => {
            if (showBar) {
              finishPB();
            }
          }
        );
      })
      .catch(error => {
        throwErrorMessage(error);
      });
  };
  _movePage = async dir => {
    let { current_page } = this.state;
    let value = dir === "left" ? current_page - 1 : current_page + 1;
    this.setState({ current_page: value }, () => {
      this._fetchPatient(false); // should not simulate progress bar
    });
  };
  _selectFilter = e => {
    this.setState({ filterBy: e.target.value }, () => {
      this._fetchPatient(false, false);
    });
  };
  _fetchChart = async () => {
    await getChart()
      .then(response => {
        this.setState({
          chart: response
        });
      })
      // .catch(error => {
      //   throwErrorMessage(error);
      // });
  };
  _renderRecords = () => {
    const { records, collapse, current_page, max_page } = this.state;
    if (records.length === 0) {
      return (
        <h6
          className="text-center"
          style={{ marginTop: "30px", fontSize: "14px" }}
        >
          {" "}
          Sem conteúdos disponíveis...{" "}
        </h6>
      );
    }

    return (
      <React.Fragment>
        {records.map((item, index) => {
          return (
            <React.Fragment key={index}>
              {item.item_type === "record" && (
                <RecordItem
                  toggle={this._toggle}
                  record={item}
                  collapse={collapse}
                  key={item.item_type + index}
                />
              )}
              {item.item_type === "questionnaire" && (
                <QuestionnaireItem
                  toggleModal={this._toggleModalQuestionnaire}
                  color={"blue"}
                  questionnaire={item}
                  collapse={collapse}
                  key={item.item_type + index}
                  role="patient"
                  q_todo_id={item.q_todo_id}
                />
              )}
            </React.Fragment>
          );
        })}
        <Paginator
          color={"blue"}
          currentPage={current_page}
          maxPage={max_page}
          movePage={this._movePage}
        />
      </React.Fragment>
    );
  };
  render() {
    const {
      patient,
      modalQuestionnaire,
      action,
      questionnaire,
      questionnaire_page,
      q_todo_id,
      chart,
      started_time
    } = this.state;
    const { color, isLoading, history } = this.props;
    if (isLoading) {
      return <div className="loading-div"></div>;
    }
    return (
      <div className="position-relative">
        {/* shape Hero */}
        <section className={`section section-shaped section-app`}>
          <div className={`shape shape-style-1 shape-app ${color}`}>
            <span className="bottom-left-2" />
            <span className="bottom-right" />
          </div>
          <Fade>
            <Container className="d-flex container-xl">
              <Row style={{ width: "100%" }}>
                <Col lg="10" className="col-patient" md="12">
                  <div className="header-patient">
                    <div className="avatar-container">
                      <Avatar url={patient.avatar} color="blue" size={182} />
                    </div>
                    <div className="patient-details">
                      <h1>
                        <span>•</span>
                        {patient.name}
                      </h1>
                      <p className="job">
                        {patient.occupation}
                        {/* {patient.occupation} <span>• quadro indefinido</span> */}
                      </p>
                    </div>
                  </div>
                  <Row className="m-t-25">
                    <Col lg="5" sm="12" className="resume">
                    <ResumeChart chart={chart.chart} questionnaire_reference={chart.reference} finished_at={chart.finished_at} />
                      <h5 className="title">
                        <span>•</span> Resumo do Utente
                      </h5>
                      <div className="content">{patient.bio}</div>
                    </Col>
                    <Col lg="6" sm="12" className="records">
                      {/* 
                      NOTA:
                      A pedido do cliente, deixa de haver a funcionalidade de registos.
                      Caso volte a ter essa funcionalidade descomenta-se este bloco de código,
                      para ficar na situação anterior em que devolve os registos com os questionários.
                      Foram comentados os seguintes ficheiros:
                          - pride-ui\src\components\Navbars\PrideNavApp.jsx (link na navbar)
                          - pride-ui\src\views\platform\Patient\Patient.jsx (dropdown de filtros)
                          - pride\app\Repositories\RecordRepository.php (obtenção dos dados)
                      
                      <Col md={{ span: 4, offset: 8 }} className="filter">
                        <InputSelect
                          id="filter"
                          value="all"
                          label="Filtrar •"
                          options={[
                            { id: "all", name: "Todos" },
                            { id: "questionnaires", name: "Questionários" },
                            { id: "records", name: "Registos" }
                          ]}
                          onChange={this._selectFilter}
                        />
                      </Col> */}
                      <this._renderRecords />
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Container>
          </Fade>
          <div className={`menu-app ${color}`}>
            <Fade>
              <button className="button-menu active">
                <div className="container-icon">
                  <div className="svg-icon">
                    <Record />
                  </div>
                </div>
                <div className="text-button">
                  <span>•</span> Report do <br /> Utente
                </div>
              </button>
              <button
                className="button-menu"
                onClick={() => history.push("/app/manual")}
              >
                <div className="container-icon">
                  <div className="svg-icon">
                    <Book />
                  </div>
                </div>
                <div className="text-button">
                  <span>•</span> Manuais de <br /> Autoajuda
                </div>
              </button>
            </Fade>
          </div>
        </section>
        {/* Modals */}
        <QuestionnaireModal
          visible={modalQuestionnaire}
          toggleModal={this._toggleModalQuestionnaire}
          color={color}
          questionnaire={questionnaire}
          questionnaire_page={questionnaire_page}
          q_todo_id={q_todo_id}
          reloadData={this._fetchPatient}
          role="patient"
          action={action}
          started_time={started_time}
        />
      </div>
    );
  }
}

export default withRouter(Library);
