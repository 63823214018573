import React from "react";
import { Modal } from "reactstrap";
import PropTypes from 'prop-types';
import Fade from 'react-reveal/Fade';

class TermsAndConditionsModal extends React.Component {
    state = {
        visible: false,
    }
    static getDerivedStateFromProps(nextProps, prevState) {
        if(prevState.visible !== nextProps.visible) {
            return ({ visible: nextProps.visible })
        }
        return null;
    }
    render() {
        if(this.state.visible === false) {
            return null;
        }
        const { toggleModal } = this.props;
        return (
            <>
            {this.state.visible &&
                <Fade duration={300}>
                <button className="button-close-modal-pride" aria-label="Close" data-dismiss="modal" type="button" onClick={() => toggleModal()}>
                    <img src={require("assets/img/buttons/close.png")} alt="close" width={97} height={92} />
                </button>
                </Fade>
            }
            <Modal 
                className="modal-dialog-centered modal-app terms-contions" 
                modalClassName="animated fadeIn" 
                isOpen={this.state.visible} 
                fade={false} 
                backdrop="static" 
                toggle={() => toggleModal()}>
                <div className="modal-header">
                    <h5 className="modal-title">
                        <span className="orange">•</span>
                        TERMOS DE UTILIZAÇÃO – <strong><em> Pride </em></strong> Geral
                    </h5>
                </div>
                <div className="modal-body mx-0 mt-5">
                    <h6>
                        Seja bem-vinda/o ao <strong><em> Pride </em></strong>.
                    </h6>
                    <p className="mt-4">
                        O presente documento estabelece as regras que regulam o acesso e utilização do portal 
                        <strong><em> Pride </em></strong> http://pride.cipsi.uminho.pt/. Estes Termos de Utilização são complementados e 
                        integrados, em tudo o que não estiver previsto neste documento, pela Política de 
                        Privacidade, que podem ser consultadas neste portal.
                    </p>
                    <p className="mt-4">
                        Por favor, leia os seguintes termos sobre a utilização deste portal, elaborados ao abrigo do 
                        novo Regulamento Geral de Proteção de Dados [RGPD], que entrou em vigor a 25 de maio 
                        de 2018. <strong>Ao utilizar o Pride, está a aceitar os presentes termos de utilização</strong>. O <strong><em> Pride </em></strong> 
                        reserva-se o direito de, a qualquer momento, sem necessidade de aviso prévio e com 
                        efeitos imediatos, alterar, adicionar, atualizar ou eliminar, parcial ou totalmente, os 
                        presentes Termos de Utilização. O Utilizador deve consultar periodicamente a página destes 
                        Termos de Utilização para confirmar se foram efetuadas quaisquer atualizações ou 
                        alterações. A utilização do <strong><em> Pride </em></strong> implica a sua concordância com os termos de utilização em 
                        vigor no momento da sua utilização.
                    </p>
                    <ol className="mt-4 pl-4">
                        <li className="pl-2">
                            O portal <strong><em> Pride </em></strong> foi desenvolvido pelo Grupo de Estudos das Perturbações Alimentares 
                            (GEPA) do Centro de Investigação em Psicologia da Universidade do Minho, no 
                            contexto de um projeto de investigação, e tem como um dos objetivos divulgar 
                            informação relevante sobre as perturbações alimentares e o seu tratamento. O 
                            GEPA-UMinho assume o compromisso de privacidade em relação aos dados que o 
                            utilizador nele depositar.
                        </li>
                        <li className="mt-3 pl-2">
                            O portal <strong><em> Pride </em></strong> não foi concebido para tratar perturbações alimentares ou outra 
                            perturbação mental. O diagnóstico e tratamento das perturbações alimentares 
                            requer acompanhamento médico e/ou psicológico por parte de um profissional de 
                            saúde (médico ou psicólogo). <strong>NENHUMA DA INFORMAÇÃO DESTE PORTAL 
                            SUBSTITUI O DIAGNÓSTICO OU TRATAMENTO POR UM PROFISSIONAL DE SAÚDE.</strong>
                        </li>
                        <li className="mt-3 pl-2">
                            O <strong><em> Pride </em></strong> não pretende fornecer acompanhamento psicológico. Apesar dos conteúdos 
                            deste portal terem sido desenvolvidos por profissionais de saúde mental da Escola 
                            de Psicologia da Universidade do Minho, fornecer estes conteúdos não cria uma 
                            relação terapêutica, nem constitui diagnóstico ou tratamento de qualquer condição 
                            clínica. <strong>SE ACHAR QUE PODE ESTAR A DESENVOLVER OU TEM UM PROBLEMA 
                            PSICOLÓGICO (quer do âmbito das perturbações alimentares ou de qualquer outra 
                            perturbação psicológica), DEVE PROCURAR UM PROFISSIONAL DE SAÚDE.</strong>
                        </li>
                        <li className="mt-3 pl-2">
                            Se tiver <strong>ideação suicida</strong> (i.e., ideias de fazer mal a si mesmo/a) <strong>ou se sentir que o 
                            seu comportamento representa um perigo para si ou para outra pessoa, a 
                            utilização do Pride não é recomendada. DEVE PEDIR AJUDA PROFISSIONAL ESPECIALIZADA!</strong>
                        </li>
                        <li className="mt-3 pl-2">
                            Ao utilizar o <strong><em> Pride </em></strong>, assume total responsabilidade pelo uso que faz da informação 
                            contida no portal. A equipa do <strong>Pride não se responsabiliza por qualquer 
                            reclamação, perda ou dano resultante do uso da informação</strong> contida no portal. A 
                            sua utilização do <strong><em> Pride </em></strong> constitui a sua concordância com as disposições da 
                            declaração de exoneração de responsabilidade.
                        </li>
                        <li className="mt-3 pl-2">
                            Os conteúdos e serviços incluídos no <strong><em> Pride </em></strong> podem ser objeto de direitos exclusivos, 
                            de propriedade industrial ou intelectual, pertencentes à Universidade do Minho, ou 
                            a outras entidades. É expressamente proibido copiar, reproduzir, modificar, exibir, 
                            transmitir ou divulgar, por qualquer forma ou para qualquer fim, os conteúdos do 
                            portal, sem autorização por escrito do GEPA-UMinho. Não tem permissão para 
                            incorporar código fonte do portal (ou qualquer reutilização de código por terceiros). 
                            Nenhuma parte do portal pode ser reproduzida ou armazenada em nenhum outro 
                            portal ou incluída em qualquer sistema de recuperação eletrónica sem a permissão 
                            prévia por escrito do GEPA-UMinho.
                        </li>
                        <li className="mt-3 pl-2">
                            No entanto, é permitido ao utilizador do <strong><em> Pride </em></strong> copiar o material informativo para o 
                            seu computador, desde que essa cópia se destine ao seu uso privado e não a fins 
                            comerciais e não elimine ou modifique, por qualquer forma, as referências aos 
                            direitos do GEPA-UMinho e das entidades que lhe são associadas, que incidam sobre 
                            os conteúdos.
                        </li>
                        <li className="mt-3 pl-2">
                            Todos os conteúdos existentes no <strong><em> Pride </em></strong> poderão ser modificados sem aviso prévio, a 
                            qualquer momento, desde que se tenham verificado alterações dos seus 
                            pressupostos legais ou tal seja do interesse do GEPA-UMinho ou das entidades a si 
                            associadas, para garantir a qualidade e eficácia do serviço prestado através deste 
                            portal.
                        </li>
                        <li className="mt-3 pl-2">
                            O <strong><em> Pride </em></strong> tem ainda o direito exclusivo de, a todo o tempo, encerrar, definitiva ou 
                            temporariamente, parcial ou totalmente, a qualquer momento, de acordo com a sua 
                            vontade, o portal ou qualquer uma das suas funcionalidades sem aviso prévio.
                        </li>
                        <li className="mt-3 pl-2">
                            Este portal contém uma variedade de hiperligações para outros <em>sites</em> na Internet, 
                            nacionais ou estrangeiros. Os links para <em>sites</em> terceiros no portal <strong><em> Pride </em></strong> são fornecidos 
                            apenas para sua conveniência. A inclusão de hiperligações (ligações electrónicas) a 
                            outros <em>sites</em> não deve ser interpretada como uma oferta do <strong><em> Pride </em></strong> de conteúdos ou 
                            serviços existentes no portal, nem como garantia da qualidade ou veracidade dos 
                            mesmos. Ao utilizar esses links, estará a sair do domínio do portal <strong><em> Pride </em></strong>. Ao aceder a 
                            estes <em>sites</em> o utilizador reconhece que o <strong><em> Pride </em></strong> não se responsabiliza pela política de 
                            segurança, privacidade, forma, conteúdo ou práticas desses mesmos <em>sites</em>, pelo que 
                            a utilização dos serviços e das informações obtidas através das hiperligações é feita 
                            sob inteira responsabilidade do utilizador. As políticas de privacidades e termos de 
                            utilização desses <em>sites</em> são da inteira responsabilidade das entidades que os 
                            controlam. Assim, deverá ler os mesmos para confirmar que aceita as condições por 
                            eles propostas.
                        </li>
                        <li className="mt-3 pl-2">
                            Visando proporcionar ao utilizador uma maior rapidez e personalização do serviço 
                            prestado, o <strong><em> Pride </em></strong> poderá recorrer a uma funcionalidade do "<em>browser</em>" conhecida 
                            como "cookie". Um "cookie" é um pequeno ficheiro de texto, automaticamente 
                            guardado pelo computador do utilizador, e que permite a sua identificação sempre 
                            que este volte a consultar, neste caso, o Portal <strong><em> Pride </em></strong>. Qualquer utilizador pode, no 
                            entanto, configurar o programa de navegação na web "<em>browser</em>" de forma a impedir 
                            a instalação de "cookies" no seu computador. Contudo, se o utilizador configurar 
                            esta opção poderá ficar impossibilitado de aceder a alguns serviços disponibilizados 
                            neste portal.
                        </li>
                        <li className="mt-3 pl-2">
                            Embora sejam feitos todos os esforços para garantir que o portal esteja disponível 
                            24 horas por dia, não seremos responsáveis se, por qualquer motivo, o portal estiver 
                            indisponível a qualquer momento ou período. O acesso ao portal pode ser suspenso 
                            temporariamente e sem aviso prévio no caso de falha, manutenção ou reparo do 
                            sistema ou por razões fora do nosso controlo.
                        </li>
                        <li className="mt-3 pl-2">
                            O <strong><em> Pride </em></strong> envida os seus melhores esforços para que o portal não tenha qualquer tipo 
                            de vírus ou outros elementos perigosos para o seu computador. No entanto, uma 
                            vez que o Pride não consegue controlar integralmente a circulação de informação 
                            através da Internet, não consegue garantir que os mesmos não contêm qualquer 
                            tipo de vírus ou outros elementos que possam danificar o seu computador.
                        </li>
                        <li className="mt-3 pl-2">
                            Todos os colaboradores do GEPA-UMinho estão abrangidos por uma obrigação de 
                            confidencialidade relativamente aos dados que tenham acesso no âmbito das 
                            operações da respectiva base informática, estando devidamente informados da 
                            importância do cumprimento desse dever legal de sigilo sendo responsáveis pelo 
                            cumprimento dessa obrigação de confidencialidade. Pela própria natureza e 
                            objectivos dos serviços interactivos e aqui disponibilizados é requerido ao utilizador 
                            o fornecimento de contactos e/ou de informações, que podem ser consideradas de 
                            carácter pessoal. O GEPA-UMinho garante, no entanto, ao utilizador que:
                        </li>
                        <div>
                            <ol type="a">
                                <li className="mt-3 pl-2">
                                    Nenhum dado pessoal será facultado a terceiros sem o prévio consentimento do seu titular; 
                                </li>
                                <li className="mt-3 pl-2">
                                    Nenhum dos dados pessoais que nos seja confiado será facultado, por via 
                                    gratuita ou comercial, a empresas de "marketing" directo ou outras 
                                    entidades que utilizem listas de "mailing" para publicitação dos seus 
                                    produtos e/ou serviços.
                                </li>
                                <li className="mt-3 pl-2">
                                    Todos as entidades responsáveis respeitarão sempre o previsto no 
                                    Regulamento Geral de Proteção de Dados [RGPD].
                                </li>
                            </ol>
                        </div>
                        <li className="mt-3 pl-2">
                            Cada utilizador dos serviços interactivos e transaccionais aqui disponibilizados é 
                            responsável e titular dos dados que transmita ao GEPA-UMinho, podendo controlar 
                            a quantidade de informação fornecida e quando (e em que circunstâncias) esta 
                            poderá, ou deverá ser facultada.
                        </li>
                        <li className="mt-3 pl-2">
                            O GEPA-UMinho garante ao utilizador registado a possibilidade de, nos termos da Lei 
                            nº 67/98, de 26 de Outubro, aceder aos seus dados pessoais através da sua página 
                            pessoal. 
                        </li>
                        <li className="mt-3 pl-2">
                            A alteração dos dados pessoais da conta de utilizador 
                            (excepto o nome de utilizador que é único) ou qualquer outra informação de carácter pessoal, como a 
                            identificação, contactos e/ou o perfil poderá a qualquer altura ser realizada pelo 
                            utilizador registado no portal, basta para tal o utilizador aceder à sua conta e, 
                            mediante a utilização do seu "nome de utilizador" e "palavra-chave", proceder à 
                            actualização e correcção dos seus dados pessoais, através da opção “Dados 
                            Pessoais”.
                        </li>
                        <li className="mt-3 pl-2">
                            Todo o utilizador registado no <strong><em> Pride </em></strong> compromete-se, expressamente, a 
                            respeitar a finalidade última dos serviços que subscreve, se inscreve ou se regista, 
                            responsabilizando-se pela correta utilização e confidencialidade do seu "nome de 
                            utilizador" e "palavra-chave".
                        </li>
                        <li className="mt-3 pl-2">
                            O GEPA-UMinho compromete-se a tratar os dados pessoais referentes ao utilizador 
                            nos termos da legislação aplicável à proteção de dados pessoais, podendo, no 
                            âmbito da sua atividade, recorrer a entidades por si subcontratadas para a 
                            prossecução das finalidades aqui indicadas.
                        </li>
                        <li className="mt-3 pl-2">
                            Sem prejuízo de outras formas de comunicação previstas nos presentes Termos e 
                            Condições de Utilização, todas as notificações efetuadas ao utilizador no âmbito 
                            destes Termos e Condições de Utilização, incluindo eventuais alterações aos 
                            mesmos, serão efetuadas para o endereço de correio eletrónico indicado pelo 
                            utilizador no registo. Caso o utilizador pretenda contactar com o GEPA-UMinho, 
                            poderá fazê-lo através do e-mail <strong>gepa@psi.uminho.pt</strong>.
                        </li>
                        <li className="mt-3 pl-2">
                            O utilizador obriga-se a comunicar ao <strong><em> Pride </em></strong> qualquer alteração do e-mail indicado no 
                            ato de registo. O incumprimento desta obrigação torna ineficaz o envio de 
                            notificações do Pride.
                        </li>
                        <li className="mt-3 pl-2">
                            Caso o <strong><em> Pride </em></strong> altere as suas práticas de privacidade, estas estarão sempre disponíveis 
                            neste local. Para esclarecimentos adicionais, por favor contacte <strong>pride_geral@psi.uminho.pt</strong>.
                        </li>
                    </ol>    
                </div>
            </Modal>
            </>
        )
    }
}

TermsAndConditionsModal.propTypes = {
    visible: PropTypes.bool.isRequired,
    toggleModal: PropTypes.func.isRequired,
}

export default TermsAndConditionsModal