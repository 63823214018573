import React from "react";
import { Row, Col } from "reactstrap";

export default class WhatIsSection extends React.Component {
    render() {
        return(
            <>
            <section id="know-more-whatis">
                <div className="know-more-whatis">
                    <img src={require("assets/img/know-more/top-left.png")} className="top-left" alt="top-left" />
                    <img src={require("assets/img/know-more/bottom-right.png")} className="bottom-right" alt="bottom-right" />

                    <div className="container-fluid">
                        <Row>
                            <Col>
                                <div className="col-content">
                                    <div className="text-white">
                                        <p className="text-header-stroke text-stroke-white text-uppercase text-center m-b-0">
                                            O que são as Perturbações Alimentares?
                                        </p>
                                        <span className="mt-4">
                                            As Perturbações Alimentares são caracterizadas por alterações persistentes no padrão alimentar ou no comportamento associado à alimentação, comprometendo de forma significativa a saúde física e o funcionamento psicossocial. Para além disso, este tipo de perturbações carateriza-se pela preocupação excessiva com o peso, forma corporal e com a alimentação, resultando em comportamentos disfuncionais como forma de influenciar o peso e a forma corporal.
                                        </span>
                                        <span className="mt-4">
                                            Qualquer pessoa pode desenvolver uma perturbação alimentar. Esta perturbação pode ocorrer em meninas e meninos, mulheres e homens, jovens e idosos, ricos e pobres, e em pessoas de todas as culturas. Contudo, a adolescência e o início da idade adulta são os períodos críticos para o desenvolvimento de uma perturbação alimentar.
                                        </span>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </div>
            </section>
            </>
        )
    }
}