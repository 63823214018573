import React from "react";
import Fade from "react-reveal/Fade";
import { Container, Row, Col } from "reactstrap";
import { Calendar } from "components/Common";
import Slider from "react-slick";
import { ArrowRight, ArrowLeft } from "components/icons";
import {
  getEventsThumbnails,
  getEventsCalendar,
  getEventsDateCalendar,
  getSingleEvent,
} from "services/requests_homepage";
import moment from "moment";
import { formatPrideDate } from "helpers";
import ReactHtmlParser from "react-html-parser";
import classNames from "classnames";
import { Scrollbars } from "react-custom-scrollbars";
import { isMobile, isTablet } from "react-device-detect";

function ArrowPrev(props) {
  const { onClick } = props;
  return (
    <div className={"slick-events-left"} onClick={onClick}>
      <ArrowLeft />
    </div>
  );
}

function ArrowNext(props) {
  const { onClick } = props;
  return (
    <div className={"slick-events-right"} onClick={onClick}>
      <ArrowRight />
    </div>
  );
}

class Events extends React.Component {
  state = {
    date: new Date(),
    thumbnails: [],
    events: [],
    eventsArray: [],
    eventsDate: [],
    active: null, // id of active event
  };
  componentDidMount() {
    this._bootComponent();
  }
  _bootComponent = async () => {
    await this._getEvent();
    await this._fetchEventsThumbnails();
    this._fetchEventsCalendar();
  };
  _fetchEventsThumbnails = async () => {
    await getEventsThumbnails().then((response) => {
      this.setState({ thumbnails: response });
    });
  };
  _fetchEventsCalendar = async () => {
    await getEventsCalendar().then((response) => {
      let events = [];
      response.forEach((event) => {
        events.push(event.date);
      });
      this.setState({ events, eventsArray: response });
    });
  };
  _getEvent = async (date = null) => {
    await getEventsDateCalendar(date ? date : -1).then((response) => {
      this.setState({ eventsDate: response });
    });
  };
  _getSingleEvent = (id) => {
    getSingleEvent(id).then((response) => {
      this.setState({ eventsDate: [].concat(response), active: id });
    });
  };
  _selectDay = (value) => {
    let date = moment(value).format("YYYY-MM-DD");
    //find event
    let events = this.state.eventsArray.filter((el) => el.date === date);
    if (events.length > 0) {
      this._getEvent(date);
    }
  };
  onChange = (date) => this.setState({ date });
  render() {
    const { content } = this.props;
    const { events, active } = this.state;
    var settingsSlider = {
      infinite: false,
      speed: 500,
      slidesToShow: 5,
      slidesToScroll: 1,
      className: "events-slider",
      nextArrow: <ArrowNext />,
      prevArrow: <ArrowPrev />,
      responsive: [
        {
          breakpoint: 1400,
          settings: {
            slidesToShow: 4,
          },
        },
        {
          breakpoint: 1200,
          settings: {
            slidesToShow: 3,
          },
        },
        {
          breakpoint: 760,
          settings: {
            slidesToShow: 2,
          },
        },
        {
          breakpoint: 500,
          settings: {
            slidesToShow: 1,
          },
        },
      ],
    };
    const settingsEvent = {
      dots: true,
      speed: 600,
      slidesToShow: 1,
      slidesToScroll: 1,
      className: "events-dotted",
      arrows: false,
    };
    return (
      <>
        <Fade>
          <div className="position-relative">
            <section
              className="section section-shaped section-events"
              id="section-events"
            >
              {/* Background Shapes Pride */}
              <div className="shape shape-style-1 shape-white pride-shape">
                {/* <span className="span-150 top-left circle" /> */}
                <span className="span-100 top-left m-5-shape" />
                <span className="span-100 top-middle m-17-shape m-5-neg" />
                <span className="span-150 bottom-center blue" />
                <span className="span-400 top-right right-dir" />
                {/* <span className="span-500 left-center orange" /> */}
              </div>

              <Container className="shape-container d-flex align-items-center container-xl">
                <Row style={{ width: "100%" }}>
                  <Col lg="12" sm="12" className="section-header m-b-56">
                    <h1 className="title">{content.title}</h1>
                    <div className="subtitle">
                      {this.state.eventsDate.length > 0
                        ? content.detail
                        : `Não existe informação para apresentar.`}
                    </div>
                  </Col>
                  {this.state.eventsDate.length > 0 && (
                    <>
                      <Col lg={(isMobile || isTablet) ? `12` : `6`} sm="12" md="12" className="event-details">
                        <Slider {...settingsEvent}>
                          {this.state.eventsDate.map((event, index) => {
                            return (
                              <div
                                className="event-details-container"
                                key={index}
                              >
                                <div className="event-header">
                                  <div className="event-details">
                                    <div className="title-and-date">
                                      <div className="title">
                                        <div className="circle" />
                                        {event.title}
                                      </div>
                                      <div className="date">
                                        {ReactHtmlParser(
                                          formatPrideDate(event.date, true)
                                        )}
                                      </div>
                                    </div>
                                    <div className="charges">
                                      <strong>vagas:</strong> {event.openings}
                                      <span className="circle-charges" />
                                      <strong>inscrição:</strong>
                                      {isNaN(event.cost) ? (
                                        <span> {event.cost}</span>
                                      ) : (
                                        <span>
                                          {" "}
                                          {event.cost} {event.currency}
                                        </span>
                                      )}
                                      <br />
                                      <strong>tema:</strong> {event.subject}
                                    </div>
                                  </div>
                                  {event.poster && (
                                    <div className="event-book">
                                      <img
                                        src={event.poster}
                                        alt="book-cover"
                                      />
                                    </div>
                                  )}
                                </div>
                                {(!isMobile && !isTablet) && (
                                  <Scrollbars
                                    className="event-descritpion"
                                    style={{ width: "100%", height: 350 }}
                                  >
                                    {ReactHtmlParser(event.detail)}
                                  </Scrollbars>
                                )}
                                {(isMobile || isTablet) && (
                                  <div className="event-descritpion">
                                    <div>{ReactHtmlParser(event.detail)}</div>
                                  </div>
                                )}
                              </div>
                            );
                          })}
                        </Slider>
                      </Col>
                      {!isMobile && !isTablet && (
                      <Col
                        lg="6"
                        sm="12"
                        className="events-datepicker d-block d-sm-none d-md-none d-xs-none d-lg-block"
                      >
                        <div className="container-calendar">
                          <div className="marker-calendar">
                            <Calendar
                              onChange={this.onChange}
                              selectDay={this._selectDay}
                              events={events}
                            />
                          </div>
                        </div>
                      </Col>
                      )}
                    </>
                  )}
                  {events.length > 0 && (
                    <>
                      <Col
                        lg="12"
                        sm="12"
                        className="section-next-events m-t-56"
                      >
                        <h1 className="title">
                          {" "}
                          <span className="circle" /> próximos eventos{" "}
                        </h1>
                        <div className="events-container">
                          <Slider {...settingsSlider}>
                            {this.state.thumbnails.map((t, index) => {
                              const classEvent = classNames({
                                "event-container": true,
                                active: t.id === active,
                              });
                              return (
                                <button
                                  key={`notice-` + index}
                                  className={classEvent}
                                  onClick={() => this._getSingleEvent(t.id)}
                                >
                                  <div className="rounded-marked-img">
                                    <div className="marker">
                                      <img src={t.thumbnail} alt={"event"} />
                                      <div className="overlay" />
                                    </div>
                                  </div>
                                  <div className="details-event">
                                    <div className="title">
                                      <span className="circle" />
                                      {t.title}
                                    </div>
                                    <div className="date">
                                      {formatPrideDate(t.date)}
                                    </div>
                                  </div>
                                </button>
                              );
                            })}
                          </Slider>
                        </div>
                      </Col>
                    </>
                  )}
                </Row>
              </Container>
            </section>
          </div>
        </Fade>
      </>
    );
  }
}
export default Events;
