import React from "react";
import { Link } from "react-router-dom";

export default class SurveySubmit extends React.Component {

    render() {
        const { visible, submitSurvey, answers, feedbackA } = this.props;

        if(!visible) {
            return null;
        }

        return(
            <section className="submit-holder">
                <div className="submit-title-holder">
                    <span className="px-2 py-1 rounded text-white label">Questionário de Triagem Rápido</span>
                </div>
                <div className="submit-body-holder">
                    {feedbackA &&
                        <>
                            <h3 className="font-weight-bold">
                                As suas respostas indicam que, no momento presente, tem um <span>risco baixo</span> de desenvolver uma perturbação alimentar.
                            </h3>
                            <p>
                                No entanto, algumas pessoas que completam estas questões de triagem, fazem-no por estarem preocupadas com a sua saúde e bem-estar. Para mais informações sobre perturbações alimentares, por favor aceda aos nossos materiais de apoio.
                            </p>
                            <div className="text-center submit-actions-holder">
                                <Link to="/sabermais" target="_blank">
                                    <button type="button" className="py-2 rounded d-block">
                                        Saber+
                                    </button>
                                </Link>
                            </div>
                        </>
                    }
                    {!feedbackA &&
                        <>
                            <h3 className="font-weight-bold">
                                As suas respostas indicam que, no momento presente, <span>poderá estar a experienciar sintomas</span> observados em pessoas com perturbações alimentares. 
                                <br />
                                É essencial que não ignore isto.
                            </h3>
                            <p>
                                Recomendamos que complete a avaliação online confidencial para que possamos compreender melhor o que está a acontecer e fornecer opções.
                                <br />
                                Ou poderá ainda contactar-nos para identificar recursos na sua área de residência que o/a possam ajudar imediatamente.
                            </p>
                            <div className="text-center submit-actions-holder">
                                <button type="button" onClick={() => submitSurvey(answers)} className="py-2 rounded d-block">
                                    Completar Avaliação
                                </button>
                                <Link to="/sabermais#unidades-de-tratamento" target="_blank">
                                    <button type="button" className="py-2 rounded d-block facilities">
                                        Unidades de Tratamento
                                    </button>
                                </Link>
                            </div>
                        </>
                    }
                </div>
                <div className="submit-holder">
                    <div className="mt-5">
                        <p>para efeitos de análise</p>
                        <p>interna clicar aqui para</p>
                        <p>o versão feedback B</p></div>
                </div>
            </section>
        )
    }
}