import React, { memo } from 'react';

const Download = memo((props) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" xmlns="http://www.w3.org/1999/xlink" width="20" height="20" viewBox={props.viewBox != null ? props.viewBox : "0 0 20 20"}>
            <defs>
                <path id="prefix__a" d="M18.897 8.542c.61 0 1.103.506 1.103 1.132v9.197c0 .623-.494 1.129-1.103 1.129H1.103C.493 20 0 19.494 0 18.87V9.675c0-.626.494-1.132 1.103-1.132.61 0 1.103.506 1.103 1.132v8.065h15.588V9.674c0-.626.494-1.132 1.103-1.132zM9.836 0c.61 0 1.11.506 1.11 1.13v9.292l3.648-3.652c.43-.444 1.215-.444 1.645 0 .43.44.43 1.158-.001 1.599l-5.46 5.598c-.216.219-.501.33-.783.33-.282 0-.57-.111-.785-.33L3.74 8.369c-.431-.441-.453-1.158-.022-1.599.43-.444 1.087-.444 1.517 0l3.491 3.667V1.129c0-.623.5-1.129 1.11-1.129z" />
                <path id="prefix__c" d="M0 0H86V86H0z" />
            </defs>
            <g fill="none" fillRule="evenodd">
                <mask id="prefix__b" fill="#fff">
                    <use href="#prefix__a" />
                </mask>
                <g mask="url(#prefix__b)">
                    <g transform="translate(-1.429 -1.429)">
                        <mask id="prefix__d" fill="#fff">
                            <use href="#prefix__c" />
                        </mask>
                        <path fill={props.fill == null ? "#B3021C" : props.fill} d="M0 0H86V86H0z" mask="url(#prefix__d)" />
                    </g>
                </g>
            </g>
        </svg>
    )
})

export { Download }