import React from 'react'
import { Input } from "components/Common"

class Search extends React.Component {

    _onSearch = () => {
        this.props.applySearch(document.getElementById('search').value);           
    }

    render() {

        const { placeholder } = this.props;
        
        return (
            <div>
                <div className="search">
                    <Input onChange={this._onSearch} type="text" id="search" placeholder={placeholder} name="search" />
                    <button onClick={this._onSearch} />
                </div>
            </div>
        )
    }
}

export { Search };