import React from "react";
import { Link } from "react-router-dom";
import classnames from "classnames";
// reactstrap components
import {
  UncontrolledCollapse,
  NavbarBrand,
  Navbar,
  NavLink,
  Nav,
  Row,
  Col
} from "reactstrap";
import PropTypes from "prop-types";
import Media from "react-media";
import classNames from "classnames";
import ScrollMenu from "react-horizontal-scrolling-menu";

class PrideNavKnowmore extends React.Component {
  state = {
    selected: "platform",
    transition: 0.3,
    nav: [
      { label: "platform", name: "plataforma" },
      { label: "highlights", name: "destaques" },
      { label: "events", name: "ações" },
      { label: "team", name: "equipa" },
      { label: "faq", name: "FAQ" }
    ]
  };

  _navClick = section => {
    this.setState({ selected: section }, () => {
      this.props.scrollSection(section);
    });
  };

  _epsi = () => {
    window.open('https://www.psi.uminho.pt/pt');
  }

  _renderNavDesktop = () => {
    const { selected } = this.state;
    const { showMenus, addTherapist, noSelected } = this.props;
    return (
      <React.Fragment>
        <NavbarBrand className="mr-lg-5" to="/" tag={Link}>
          <img
            alt="logo"
            src={require("assets/img/brand/logo-bold/logotipo.png")}
          />
        </NavbarBrand>
        <button className="navbar-toggler" id="navbar_global"></button>
        <UncontrolledCollapse navbar toggler="#navbar_global">
          <div className="navbar-collapse-header">
            <Row>
              <Col className="collapse-brand" xs="6">
                <Link to="/">
                  <img
                    alt="logo"
                    src={require("assets/img/brand/logo-bold/logotipo.png")}
                    srcSet={require("assets/img/brand/logo-bold/logotipo@2x.png")}
                  />
                </Link>
              </Col>
            </Row>
          </div>
          {showMenus && (
            <Nav className="navbar-nav navbar-center">
              {this.state.nav.map((nav, index, array) => {
                return (
                  <React.Fragment key={index}>
                    <NavLink
                      href="/"
                      onClick={() => this._navClick(nav.label)}
                    >
                      {" "}
                      {nav.name}{" "}
                    </NavLink>
                    {array.length - 1 !== index && (
                      <span className="shape-circle"> • </span>
                    )}
                  </React.Fragment>
                );
              })}
              <div className="nav-partition"></div>
              <NavLink className={"know-more "+(noSelected ? '' : 'active')} href="/sabermais">
                Saber +
              </NavLink>
              <NavLink className="register" onClick={() => addTherapist()}>
                {" "}
                registar terapeuta{" "}
              </NavLink>
            </Nav>
          )}
          <Nav className="align-items-lg-center ml-lg-auto navbar-right" navbar>
            <NavLink className="divider" style={{cursor: "pointer"}} onClick={() => this._epsi()}>EPsi | UMinho</NavLink>
          </Nav>
        </UncontrolledCollapse>
      </React.Fragment>
    );
  };

  _renderNavMobile = () => {
    const { selected, transition } = this.state;
    const { addTherapist, noSelected } = this.props;

    let buttons = this.state.nav.map((nav, index, array) => {
      return (
        <React.Fragment key={nav.label}>
          <NavLink color="link">
            {" "}
            {nav.name}{" "}
          </NavLink>
          {array.length - 1 !== index && (
            <span className="shape-circle"> • </span>
          )}
        </React.Fragment>
      );
    });

    // push buttons
    buttons.push(<div className="nav-partition"></div>);
    buttons.push(
      <NavLink key={`mobile-know-more`} className={"know-more "+(noSelected ? '' : 'active')} href="/sabermais">
        Saber +
      </NavLink>
    );
    buttons.push(
      <NavLink key={`mobile-register`} className="register" onClick={() => addTherapist()}>
        {" "}
        registar terapeuta{" "}
      </NavLink>
    );

    const Menu = (
      <ScrollMenu
        data={buttons}
        alignCenter={false}
        onSelect={this._navClick}
        transition={+transition}
        innerWrapperStyle={{ width: "100%" }}
      />
    );
    return (
      <div className="nav-mobile">
        <NavbarBrand className="mr-lg-5 responsive" to="/" tag={Link}>
          <img
            alt="logo"
            src={require("assets/img/brand/logotipo-small.png")}
            srcSet={require("assets/img/brand/logotipo-small@2x.png")}
            // style={{ position: "fixed" }}
          />
        </NavbarBrand>
        {Menu}
      </div>
    );
  };
  render() {
    return (
      <>
        <header className="header-global">
          <Media query="(max-width: 1100px)">
            {matches =>
              matches ? (
                <>
                  <Navbar
                    className="navbar-main navbar-light navbar-pride fixed-top hide-native-scrollbar"
                    style={{ overflowX: "scroll", overflowY: "hidden" }}
                    expand="lg"
                    id="navbar-main"
                  >
                    <this._renderNavMobile />
                  </Navbar>
                </>
              ) : (
                <>
                  <Navbar
                    className="navbar-main navbar-light navbar-pride fixed-top"
                    expand="lg"
                    id="navbar-main"
                  >
                    <this._renderNavDesktop />
                  </Navbar>
                </>
              )
            }
          </Media>
        </header>
      </>
    );
  }
}

PrideNavKnowmore.propTypes = {
  scrollSection: function(props, propName, componentName) {
    if (props["showMenus"] && !props["scrollSection"]) {
      return PropTypes.func.isRequired;
    }
  },
  showMenus: PropTypes.bool,
  addTherapist: PropTypes.func
};

export default PrideNavKnowmore;
