import React, { memo } from 'react';

const Record = memo(() => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40">
            <path fill="#25AFC4" fillRule="evenodd" d="M20.312 6.4c.403 0 .718.021.997.314l8.824 9.188c.262.276.267.662.267 1.044V32.53c0 .82-.377 1.87-1.193 1.87h-17.72c-.815 0-1.887-1.05-1.887-1.87V7.596c0-.82 1.072-1.196 1.888-1.196zm-1.798 2.987h-5.943v22.026H27.43v-12.32h-7.082c-.815 0-1.833-1.179-1.833-1.998V9.387zM23.11 26.56c.815 0 1.476.674 1.476 1.493 0 .82-.661 1.494-1.476 1.494h-7.088a1.488 1.488 0 0 1-1.477-1.494c0-.82.661-1.493 1.477-1.493zm-2.954-4.48c.816 0 1.477.487 1.477 1.307s-.661 1.306-1.477 1.306h-4.134c-.816 0-1.477-.486-1.477-1.306 0-.82.661-1.307 1.477-1.307zm.53-11.53v6.357h5.913l-5.913-6.358z"/>
        </svg>
    )
})

export { Record }