import React from "react";
import Slider from "react-slick";
import { Row, Col } from "reactstrap";

function NextArrow(props) {
  const { className, onClick } = props;
  return (
    <button 
        className="button-slider-next"
        onClick={onClick}>
        <svg 
            xmlns="http://www.w3.org/2000/svg" 
            width="50" 
            height="50" 
            viewBox="0 0 24 24" 
            fill="none" 
            stroke="#b3021c" 
            strokeWidth="2" 
            strokeLinecap="round" 
            strokeLinejoin="round" >
                <polyline points="9 18 15 12 9 6"></polyline>
        </svg>
    </button>
  );
}

function PrevArrow(props) {
    const { className, onClick } = props;
    return (
      <button 
          className="button-slider-prev"
          onClick={onClick}>
          <svg 
              xmlns="http://www.w3.org/2000/svg" 
              width="50" 
              height="50" 
              viewBox="0 0 24 24" 
              fill="none" 
              stroke="#b3021c" 
              strokeWidth="2" 
              strokeLinecap="round" 
              strokeLinejoin="round" >
                  <polyline points="15 18 9 12 15 6"></polyline>
          </svg>
      </button>
    );  
}

export default class WhatIsSlider extends React.Component {
    render() {
        const settings = {
            dots: true,
            infinite: true,
            speed: 500,
            className: "text-center",
            nextArrow: <NextArrow />,
            prevArrow: <PrevArrow />,
            responsive: [
              {
                breakpoint: 768,
                settings: {
                  slidesToShow: 1,
                  slidesToScroll: 1,
                  infinite: true,
                  dots: false
                }
              }
            ]
        };

        return(
            <>
            <section>
                <div className="know-more-whatis-slider">
                    <div className="px-5">
                        <Slider {...settings}>
                            <div className="slide-item w-100">
                                <h5>
                                    "As Perturbações Alimentares são perturbações psicológicas potencialmente graves e que podem ser fatais."
                                </h5>
                            </div>
                            <div className="slide-item w-100">
                                <h5>
                                    "As Perturbações Alimentares são caracterizadas por alterações nos comportamentos, pensamentos e atitudes em relação à comida, alimentação, peso ou forma corporal que interferem e têm um impacto negativo na vida do indivíduo."
                                </h5>
                            </div>
                            <div className="slide-item w-100">
                                <h5>
                                    "As Perturbações Alimentares podem ter um impacto negativo na vida física, emocional, profissional e social do indivíduo."
                                </h5>
                            </div>
                            <div className="slide-item w-100">
                                <h5>
                                    "Os sintomas associados às Perturbações Alimentares envolvem o controlo rígido da comida, peso e forma corporal, na tentativa de controlar sentimentos ou dificuldades psicológicas subjacentes. No entanto, estes comportamentos resultam exatamente no oposto: sentimentos de desesperança e avaliação negativa."
                                </h5>
                            </div>
                            <div className="slide-item w-100">
                                <h5>
                                    "As Perturbações Alimentares são muitas vezes uma forma de lidar com as dificuldades pessoais, emocionais e psicológicas."
                                </h5>
                            </div>
                            <div className="slide-item w-100">
                                <h5>
                                    "As Perturbações Alimentares podem ser experienciadas pela pessoa como forma de ajudar a atenuar as emoções, fornecendo distração ou sentimento de realização, ajudando a sentir-se em controlo e podendo tornar-se parte da sua identidade."
                                </h5>
                            </div>
                            <div className="slide-item w-100">
                                <h5>
                                    "As Perturbações Alimentares apresentam, muitas vezes comorbilidade com outras patologias nomeadamente, depressão e ansiedade."
                                </h5>
                            </div>
                        </Slider>
                    </div>
                </div>
            </section>
            </>
        )
    }
}