import React from 'react'
import useForm from 'react-hook-form'
import { Input } from "components/Common"
import { saveRecord, updateRecord } from 'services/requests'
import { notify, throwErrorMessage, formatPrideDate } from 'helpers'

const AddRecordForm = (props) => {
  const { register, handleSubmit, errors, setError } = useForm()
  const { color, toggleModal, reloadData, patient, mode, record } = props;

  const onSubmit = (data) => {
    if(mode === 'edit') {
      updateRecord(record.id, data).then(response => {
        reloadData() // reload data on list view
        notify('success', response.message)
        toggleModal()
      }).catch(response => {
        throwErrorMessage(response, setError)
      })
    } else {
      saveRecord(data).then(response => {
        reloadData() // reload data on list view
        notify('success', response.message)
        toggleModal()
      }).catch(response => {
        throwErrorMessage(response, setError)
      })
    }
  }

  const urlButton = require("assets/img/buttons/add-blue-2@2x.png");

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
        <div className="patient-form-details">
            <div className="header">
              <h1 className="name">{patient.name}</h1>
              <h5 className="address">{patient.address}</h5>
            </div>
            <div className="details">
              <div className="item">
                <span>Profissão •</span> {patient.info}
              </div>
              <div className="item">
                <span>Data de Admissão •</span> {formatPrideDate(patient.date)}
              </div>
            </div>
        </div>

        <Input type="textarea" rows={10} placeholder="Descreva os seus últimos comportamentos e sintomas" label="Descrever Registo *" id="description" name="description" error={errors.description} register={register} defaultValue={mode === 'edit' ? record.description : ''} />

        <div className="button-form">
            <button type="submit" className={`button-submit ${color}`}>
              <img src={urlButton} alt="login" />
              <div className="title">Guardar</div>
            </button>
        </div>
    </form>
  )
}

export default AddRecordForm;