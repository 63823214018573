import React from "react";
import { Modal } from "reactstrap";
import PropTypes from 'prop-types';
import Fade from 'react-reveal/Fade';
import SubmitSurveyForm from "views/forms/SubmitSurveyForm";

class PrivacyPolicyModal extends React.Component {
    state = {
        visible: false,
    }
    static getDerivedStateFromProps(nextProps, prevState) {
        if(prevState.visible !== nextProps.visible) {
            return ({ visible: nextProps.visible })
        }
        return null;
    }
    render() {
        if(this.state.visible === false) {
            return null;
        }
        const { toggleModal } = this.props;
        return (
            <>
            {this.state.visible &&
                <Fade duration={300}>
                <button className="button-close-modal-pride" aria-label="Close" data-dismiss="modal" type="button" onClick={() => toggleModal()}>
                    <img src={require("assets/img/buttons/close.png")} alt="close" width={97} height={92} />
                </button>
                </Fade>
            }
            <Modal 
                className="modal-dialog-centered modal-app privacy-policy" 
                modalClassName="animated fadeIn" 
                isOpen={this.state.visible} 
                fade={false} 
                backdrop="static" 
                toggle={() => toggleModal()}>
                <div className="modal-header">
                    <h5 className="modal-title">
                        <span className="orange">•</span>
                        POLÍTICA DE PRIVACIDADE – 
                        <strong><em> Pride </em></strong> 
                        Geral
                    </h5>
                </div>
                <div className="modal-body mx-0 mt-5">
                    <p className="mt-3">
                        O Grupo de Estudos das Perturbações Alimentares do Centro de Investigação em 
                        Psicologia da Universidade do Minho (GEPA-UMinho) garante aos visitantes deste 
                        portal (“utilizador” ou “utilizadores”) o respeito pela sua privacidade e a proteção dos 
                        seus dados pessoais.
                    </p>
                    <p className="mt-3">
                        A visita ao portal http://pride.cipsi.uminho.pt/, por si só, não implica o registo, de 
                        forma automática, de qualquer dado pessoal que identifique o utilizador. Existem, no 
                        entanto, áreas do portal onde é necessário que o utilizador forneça dados pessoais, de 
                        forma a usufruir dos serviços aí disponibilizados.
                    </p>
                    <p className="mt-3">
                        A presente Política de Privacidade faz parte integrante dos Termos de Utilização do 
                        portal e regula a recolha e o tratamento dos dados pessoais fornecidos pelos 
                        utilizadores, assim como o exercício dos seus direitos relativamente a estes dados, nos 
                        termos do Regulamento Geral sobre a Proteção de Dados, do Parlamento Europeu e 
                        do Conselho em vigor.
                    </p>
                    <ol className="mt-4 pl-4">
                        <li className="pl-2">
                            <strong>
                                Quais os dados que são objeto de tratamento? E para que finalidade?
                            </strong>
                        </li>
                        <div className="mt-2">
                            <p>
                                O GEPA-UMinho é a entidade responsável pela recolha e tratamento dos dados 
                                pessoais dos utilizadores. Os serviços do portal <strong><em> Pride </em></strong> que necessitam de introdução de 
                                dados pessoais por iniciativa ou demonstração de interesse do utilizador, são:
                            </p>
                            <ol type="a">
                                <li className="mt-3 pl-2">
                                    Registo para subscrição de alertas e newsletters; 
                                </li>
                                <li className="mt-3 pl-2">
                                    Registo para preenchimento de instrumentos de triagem;
                                </li>
                                <li className="mt-3 pl-2">
                                    Recomendar página; 
                                </li>
                                <li className="mt-3 pl-2">
                                    Contacto para receber mais informação sobre o projeto como apoiar o 
                                    <strong><em> Pride </em></strong>; 
                                </li>
                                <li className="mt-3 pl-2">
                                    Outros serviços.
                                </li>
                            </ol>    
                        </div>
                        <p className="mt-3">
                            A recolha de dados de identificação do utilizador será efetuada através do 
                            preenchimento de um formulário de registo online e ocorrerá de acordo com as mais 
                            estritas regras de segurança. Os dados recolhidos pelo Pride são introduzidos no 
                            sistema informático que os trata, onde serão processados automaticamente 
                            destinando-se os dados à gestão exclusiva interna de serviços do portal.  
                        </p>
                        <li className="mt-3 pl-2">
                            <strong>
                                A quem comunicamos os dados pessoais?
                            </strong>
                        </li>
                        <p className="mt-2">
                            O GEPA-UMinho poderá comunicar os seus dados pessoais no cumprimento de uma obrigação legal, de uma 
                            deliberação da Comissão Nacional de Proteção de Dados ou de 
                            uma ordem judicial.
                        </p>
                        <li className="mt-3 pl-2">
                            <strong>
                                Quais os direitos do utilizador?
                            </strong>
                        </li>
                        <p className="mt-2">
                            O utilizador tem direito, a todo o tempo, de obter do <strong><em> Pride </em></strong> a confirmação de que 
                            dados pessoais que lhe dizem respeito são objeto de tratamento, bem como o acesso 
                            aos mesmos, sendo-lhe disponibilizada, caso assim o solicite, uma cópia dos dados em 
                            questão.
                        </p>
                        <p>
                            Ao utilizador são igualmente reconhecidos, a todo o tempo, os direitos de retificação e 
                            eliminação dos seus dados pessoais, bem como o direito de suscitar a limitação ou de 
                            se opor ao tratamento dos seus dados.
                        </p>
                        <p>
                            Caso o utilizador solicite a eliminação dos seus dados, o <strong><em> Pride </em></strong> adota todos os 
                            mecanismos necessários por forma a garantir que todos os dados foram eliminados.
                        </p>
                        <p>
                            O utilizador poderá ainda solicitar quaisquer informações adicionais, nomeadamente 
                            informações sobre as finalidades do tratamento dos dados e o prazo previsto da sua 
                            conservação.
                        </p>
                        <p>
                            O utilizador tem o direito de transmitir os dados pessoais que lhe digam respeito e que 
                            tenha fornecido ao Pride num formato estruturado, de uso corrente e de leitura 
                            automática, a outro responsável pelo tratamento. Nestes termos, poderá o utilizador 
                            requerer, sempre que tecnicamente possível e quando legalmente aplicável, a 
                            transmissão dos seus dados pessoais diretamente entre os responsáveis pelo 
                            tratamento.
                        </p>
                        <p>
                            O utilizador poderá exercer os direitos referidos mediante pedido escrito dirigido ao 
                            <strong><em> Pride </em></strong>, para o endereço de email pride_geral@psi.uminho.pt.
                        </p>
                        <li className="mt-3 pl-2">
                            <strong>
                                Durante quanto tempo os dados pessoais são conservados?
                            </strong>
                        </li>
                        <p className="mt-2">
                            Os dados pessoais recolhidos serão conservados durante o período necessário para a 
                            prossecução destas finalidades, findo o qual os mesmos serão definitivamente 
                            eliminados.
                        </p>
                        <p>
                            Os utilizadores não registados poderão navegar livremente no portal, pelo que não 
                            lhes será solicitado que forneçam qualquer dado pessoal. Poderão, no entanto, 
                            necessitar de introdução de dados pessoais caso pretendam ter acesso aos serviços do 
                            Portal <strong><em> Pride </em></strong> descritos anteriormente.
                        </p>
                        <p>
                            Não obstante, o utilizador deverá estar ciente de que algumas informações a seu 
                            respeito poderão ser recolhidas, ainda que de forma anónima, através de processos 
                            automáticos (como cookies).
                        </p>
                        <li className="mt-3 pl-2">
                            <strong>
                                Quais as medidas de segurança adotadas?
                            </strong>
                        </li>
                        <p className="mt-2">
                            O Pride desenvolve os seus melhores esforços para proteger os dados pessoais dos 
                            utilizadores contra acessos não autorizados através da Internet. Para o efeito, utiliza 
                            sistemas de segurança, regras e outros procedimentos, de modo a garantir a proteção 
                            dos dados pessoais dos utilizadores, bem como para prevenir o acesso não autorizado 
                            aos dados, o seu uso impróprio, a sua divulgação, perda ou destruição.
                        </p>
                        <p>
                            É, no entanto, da responsabilidade do utilizador garantir e assegurar que o dispositivo 
                            que está a utilizar se encontra adequadamente protegido contra <em>softwares</em> nocivos, 
                            vírus informáticos e <em>worms</em>. Adicionalmente, deverá estar ciente que, sem a adoção de 
                            medidas de segurança adequadas (por exemplo, a configuração segura do programa 
                            de navegação, <em>software</em> antivírus atualizado, <em>software</em> de barreira de segurança e a não 
                            utilização de <em>software</em> de origem duvidosa), o risco dos dados pessoais e <em>passwords</em> 
                            serem acedidos por terceiros, sem autorização para tal, é agravado.
                        </p>
                        <p>
                            No entanto, note-se que, sempre que a recolha de dados seja realizada em redes 
                            abertas, como a Internet, os seus dados poderão circular sem condições de segurança, 
                            existindo o risco de serem vistos e utilizados por terceiros não autorizados.
                        </p>
                        <li className="mt-3 pl-2">
                            <strong>
                                Para apresentar reclamações
                            </strong>
                        </li>
                        <p className="mt-2">
                            Sempre que o utilizador considere existir uma violação do tratamento dos seus dados 
                            pessoais, tem direito a apresentar uma reclamação à Comissão Nacional de Proteção 
                            de Dados ou a outra autoridade de controlo competente nos termos da lei.
                        </p>
                        <li className="mt-3 pl-2">
                            <strong>
                                Para submeter questões e sugestões
                            </strong>
                        </li>
                        <p className="mt-2">
                            Se tiver quaisquer dúvidas ou questões sobre a forma como o <strong><em> Pride </em></strong> procede ao 
                            tratamento dos seus dados pessoais, por favor envie-nos o seu pedido de 
                            esclarecimento através do e-mail pride_geral@psi.uminho.pt.
                        </p>
                        <li className="mt-3 pl-2">
                            <strong>
                                Onde consultar as alterações à política de privacidade?
                            </strong>
                        </li>
                        <p className="mt-2">
                            O <strong><em> Pride </em></strong> poderá, a todo o momento, sem aviso prévio e com efeitos imediatos, alterar, 
                            acrescentar ou revogar, parcial ou totalmente, a presente Política de Privacidade.
                        </p>
                        <p>
                            Quaisquer alterações serão imediatamente divulgadas nesta mesma página online.
                        </p>
                    </ol>
                </div>
            </Modal>
            </>
        )
    }
}

PrivacyPolicyModal.propTypes = {
    visible: PropTypes.bool.isRequired,
    toggleModal: PropTypes.func.isRequired,
}

export default PrivacyPolicyModal