
/*eslint-disable*/
import React from "react";
// reactstrap components
import { Container, Row, Col } from "reactstrap";
import { Button } from 'components/Common'
import { getFooter } from "services/requests_homepage";
import ReactHtmlParser from 'react-html-parser';

class PrideFooter extends React.Component {

  state = {
    footer: []
  }

  componentDidMount() {
    this._fetchFooter();
  }
  _fetchFooter = async (keyword = null) => {
    await getFooter(keyword).then(response => {
      this.setState({ footer: response });
    })
  }
  _renderSection = (section) => {
    const { footer } = this.state;
    let value = footer.find(el => el.title === section)
    if (value) {
      if (value.detail.replace(/<\/?[^>]+(>|$)/g, "") !== '')
        return (
          <Col lg="4" sm="12" xs="12" className="col-footer">
            <h3 className="title">{section}</h3>
            {ReactHtmlParser(value.detail)}
          </Col>
        )
    }
    return null;
  }
  render() {
    const { supportProject, viewTermsAndConditions, viewPrivacyPolicy } = this.props;
    return (
      <>
        <footer className="footer">
          <Button onPress={() => supportProject()} text={"Apoiar"} color={"orange"} fontSize={20} minWidth={115} minHeight={60}/>
          <div className="position-relative">
            <section className="section section-shaped section-footer" id="section-footer">
              <div className="shape shape-style-1 pride-shape">
                <span className="span-footer-left" />
                <span className="span-footer-right" />
              </div>
              <Container className="shape-container container-lg">
                <Row className="row-grid">
                  <Col lg="6" sm="12" xs="12" className="col-footer">
                    <h3 className="title yellow">Projeto</h3>
                    <Row className="row-grid projects-holder">
                      <img src={require("assets/img/footer/04. Logo_PRIDE_small.svg")} height="60" width="150" />
                      <img src={require("assets/img/footer/05. Logo_GEPA.svg")} height="60" width="150" />
                      <img src={require("assets/img/footer/06. Universidade_do_Minho.svg")} height="60" width="60" />
                      <img src={require("assets/img/footer/07. Escola_de_Psicologia.svg")} height="60" width="60" />
                    </Row>
                    <Row className="row-grid links-holder">
                      {this._renderSection('Links')}
                    </Row>
                  </Col>
                  <Col lg="6" sm="12" xs="12" className="col-footer">
                    <h3 className="title yellow">Apoios</h3>
                    <Row className="row-grid supporters-holder">
                      <img src={require("assets/img/footer/01.FCT_V_cor.png")} height="60" width="60" />
                      <img src={require("assets/img/footer/02.Republica_Portuguesa.svg")} height="60" width="150" />
                      <img src={require("assets/img/footer/03. Coofinanciadores1.png")} height="50" width="200" />
                      <img src={require("assets/img/footer/03. Coofinanciadores2.png")} height="50" width="150" />
                    </Row>
                  </Col>
                </Row>
                <Row className="row-grid">
                  <Col lg="5" sm="5" xs="12" className="col-footer terms-conditions-holder">
                    <a 
                      href='javascript:void(0)' 
                      onClick={() => viewTermsAndConditions()}>
                      <span>Termos de Utilização</span>
                    </a>
                  </Col>
                  <Col lg="1" sm="1" xs="12" className="col-footer separator-holder">
                    <span>&nbsp;</span>
                  </Col>
                  <Col lg="5" sm="5" xs="12" className="col-footer privacy-policy-holder">
                    <a 
                      href='javascript:void(0)' 
                      onClick={() => viewPrivacyPolicy()}>
                      <span>Política de Privacidade</span>
                    </a>
                  </Col>
                </Row>
              </Container>
            </section>
          </div>
        </footer>
      </>
    );
  }
}

export default PrideFooter;
