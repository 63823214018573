import React from 'react'
import useForm from 'react-hook-form'
import { Input } from "components/Common"
import { submitSurvey } from 'services/requests'
import { notify, throwErrorMessage } from 'helpers'

const SubmitSurveyForm = (props) => {
  const { register, handleSubmit, errors, setError } = useForm()
  const { color, toggleModal } = props;

  const onSubmit = (data) => { 
    let payload = {
      "lib_version":"3.2.0",
      "user_id":"user_KeWrKVE8VifdML7qGL7Wv",
      "service_id":"service_s7dfrw6",
      "template_id":"template_e8sw2bg",
      "template_params": {
          "email": data.email,
          "to_name": data.name,
          "from_name": data.email,
          "answer_1": props.answers[0].answer,
          "answer_2": props.answers[1].answer,
          "answer_3": props.answers[2].answer,
          "answer_4": props.answers[3].answer,
          "answer_5": props.answers[4].answer,
          "answer_6": props.answers[5].answer,
          "message":"As suas respostas indicam que, no momento presente, poderá estar a experienciar sintomas observados em pessoas com perturbações alimentares. É essencial que não ignore isto. Em breve ser-lhe-á enviado o questionário para concluir a sua avaliação."
      }
    }

    submitSurvey(payload).then(response => {
      notify('success', 'Enviado com sucesso.')
      toggleModal()
    }).catch(response => {
      throwErrorMessage(response, setError)
    })
  }

  const urlButton = require("assets/img/buttons/right-orange@2x.png");

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
        <Input 
          type="text" 
          placeholder="nome completo" 
          label="Nome *" 
          id="name" 
          name="name" 
          error={errors.name} 
          register={register({ required: "Campo é obrigatório" })}
        />

        <Input 
          type="email" 
          placeholder="e-mail" 
          label="E-mail *" 
          id="email" 
          name="email" 
          error={errors.email} 
          register={register({
            required: 'Campo é obrigatório!',
            pattern: {
              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
              message: "email é inválido"
            }
          })} 
          />

        <div className="button-form">
            <button type="submit" className={`button-submit ${color}`}>
              <img src={urlButton} alt="submit" />
              <div className="title">Enviar</div>
            </button>
        </div>
    </form>
  )
}

export default SubmitSurveyForm;