import React, { useEffect } from 'react'
import { FormGroup, Input as InputStrap, Label, FormText } from "reactstrap";

const InputCheckbox = ({ info, label, id, name, value, error, register, defaultValue }) => {

    useEffect(() => {
        document.getElementById(id).checked = defaultValue;
    }, [defaultValue, id]);

    return (
        <FormGroup >
            {label &&
                <Label for={id}>{label}</Label>
            }
            <InputStrap type="checkbox" name={name} value={value} id={id} innerRef={register} />
            {info &&
                <>
                    <br />
                    <Label style={{fontWeight: "100", fontSize: "13px"}}>{info}</Label>
                </>
            }
            <FormText color="red">
                {typeof (error) !== 'undefined' &&
                    <span>{error.message}</span>
                }
            </FormText>
        </FormGroup>
    )
}

export { InputCheckbox };