import React, { useState } from 'react'
import useForm from 'react-hook-form'
import { Input } from "components/Common"
import axios from 'axios'
import { loginUrl, recoverUrl } from 'services';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { SetLoginSuccess } from "store/actions/auth.actions";
import { Collapse, Fade } from 'reactstrap';
import { notify } from 'helpers'

const LoginForm = (props) => {
  const { register, handleSubmit, errors, setValue, setError, unregister } = useForm()
  const { color, changeDetails, details, fromAdmin, toggleLogin, sectionLogin } = props;
  const [recoverIsOpen, setRecoverIsOpen] = useState(true);
  const [recoverFadeIn, setRecoverFadeIn] = useState(true);
  const [isRecover, setIsRecover] = useState(false);
  const [buttonLabel, setButtonLabel] = useState('Entrar');

  const onSubmit = (data) => {

    if (isRecover) {
      axios.get(recoverUrl + '/' + data.email).then(response => { 

        notify('success', 'Recuperação de password registada. Verifique o seu email.')

        if(toggleLogin)
          toggleLogin();
        else
        {
          setTimeout(() => {
            window.location.href = window.location.origin;
          }, 2000);
        }

      });
    }
    else {

      data.section = sectionLogin;
      axios.post(loginUrl, data).then(response => {
        let super_admin = false;
        //dispatch Login Success on store and set the session auth
        props.SetLoginSuccess(
          true,
          super_admin,
          response.data.access_token,
          response.data.user.id,
          response.data.user.name,
          response.data.user.email,
          response.data.user.avatar,
          response.data.user.info,
          response.data.user.address,
          response.data.user.color,
          response.data.user.permissions,
          response.data.user.roles[0]
        );

        super_admin = response.data.user.roles.includes('super_admin');

        if (super_admin && fromAdmin)
          props.history.push('/admin');
        else
          props.history.push('/app');

      }).catch(error => {
        setError("email", "unauthorized", "Dados de autenticação inválidos!");
        setValue('password', '')
      })
    }
  }

  const urlButton = require("assets/img/buttons/right-" + color + "@2x.png");

  const handleRecover = () => {

    setIsRecover(true);
    setButtonLabel('enviar');
    unregister('password');
    document.getElementById('password').value = "-";
    setRecoverFadeIn(!recoverFadeIn);
    setRecoverIsOpen(!recoverIsOpen);
    changeDetails({
      color: details.color,
      title: 'Recuperar Password',
      subtitle: details.title.toString().toLowerCase()
    });

  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Input type="email" placeholder="email de utilizador" label="Utilizador *" id="email" name="email" error={errors.email} register={register({
        required: 'Campo é obrigatório!',
        pattern: {
          value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
          message: "email é inválido"
        }
      })} />

      <Collapse isOpen={recoverIsOpen}>

        <Fade in={recoverFadeIn}>

          <Input type="password" placeholder="código definido do registo" label="Password *" id="password" name="password" error={errors.password} register={register({ required: "Campo é obrigatório" })} />

          <div onClick={handleRecover} className={`recover-password ${color}`}><span> • </span>recuperar password</div>

        </Fade>

      </Collapse >

      <div className="button-form">
        <button type="submit" className={`button-submit ${color}`}>
          <img src={urlButton} alt="login" />
          <div className="title">{buttonLabel}</div>
        </button>
      </div>


    </form>
  )
}

const mapStateToProps = (state, ownProps) => {
  return {}
}
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ SetLoginSuccess }, dispatch);
}

let LoginPage = connect(mapStateToProps, mapDispatchToProps)(LoginForm);
export default withRouter(LoginPage);
