import React, { memo } from 'react';

const AddTherapist = memo(() => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="51" height="51" viewBox="0 0 51 51">
            <path fill="#b3021c" fillRule="evenodd" d="M31.708 27.041c6.36 0 11.534 5.085 11.534 11.337 0 .946-.782 1.715-1.748 1.715H14.932c-.966 0-1.748-.769-1.748-1.715 0-6.252 5.174-11.337 11.534-11.337h6.99zm0 3.435h-6.99c-3.833 0-7.047 2.648-7.848 6.182h22.686c-.8-3.534-4.016-6.182-7.848-6.182zM13.214 17.1c.962 0 1.758.769 1.758 1.72v2.39h2.406c.967 0 1.75.778 1.75 1.728 0 .947-.783 1.729-1.75 1.729h-2.406v2.395c0 .946-.796 1.715-1.758 1.715-.967 0-1.759-.769-1.759-1.715v-2.395H8.991c-.967 0-1.75-.782-1.75-1.729 0-.95.783-1.728 1.75-1.728h2.464v-2.39c0-.951.792-1.72 1.759-1.72zm14.999-7.007c4.24 0 7.689 3.391 7.689 7.556 0 4.17-3.45 7.56-7.69 7.56s-7.689-3.39-7.689-7.56c0-4.165 3.45-7.556 7.69-7.556zm0 3.435c-2.313 0-4.194 1.849-4.194 4.121 0 2.277 1.881 4.126 4.194 4.126 2.313 0 4.194-1.85 4.194-4.126 0-2.272-1.881-4.121-4.194-4.121z"/>
        </svg>
    )
})

export { AddTherapist }