import React from "react";

// core components
import PrideNavHomepage from "components/Navbars/PrideNavHomepage.jsx";
import PrideFooter from "components/Footers/PrideFooter.jsx";
import scrollToComponent from "react-scroll-to-component";

// index page sections
import Hero from "./IndexSections/Hero.jsx";
import AnimatedSection from "./IndexSections/AnimatedSection.jsx";
import Highlights from "./IndexSections/Highlights.jsx";
import Events from "./IndexSections/Events.jsx";
import Team from "./IndexSections/Team.jsx";
import Faq from "./IndexSections/Faq.jsx";
import Newsletter from "./IndexSections/Newsletter.jsx";
import LoginModal from "views/modals/LoginModal.jsx";
import Login from "views/IndexSections/Login.jsx";
import AddTherapist from "views/modals/AddTherapist.jsx";
import SupportProject from "views/modals/SupportProject.jsx";
import TermsAndConditionsModal from "views/modals/TermsAndConditionsModal.jsx";
import PrivacyPolicyModal from "views/modals/PrivacyPolicyModal.jsx";
import { getSection } from "services/requests_homepage";

import ReactNotification from "react-notifications-component";

const loginMessages = {
  therapist: {
    title: "Área do Terapeuta",
    subtitle: "terapia e cuidado médico",
    color: "red"
  },
  patient: {
    title: "Área do Utente",
    subtitle: "orgulhosamente a cuidar de mim",
    color: "blue"
  },
  scientific_community: {
    title: "Área da Comunidade Científica",
    subtitle: null,
    color: "orange"
  }
};

class Index extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modalLogin: false,
      modalTherapist: false,
      modalSupport: false,
      modalTermsAndConditions: false,
      modalPrivacyPolicy: false,
      sectionLogin: null,
      loading: true,
      content: {}
    };
  }
  componentDidMount() {
    this._bootApp();
  }
  _bootApp = async () => {
    await getSection(
      "main,section_1,section_2,section_3,newsletter,team,events,notices",
      true
    ).then(response => {
      this.setState({ content: response, loading: false });
    });
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.main.scrollTop = 0;
  };
  _toggleLogin = (section = null) => {
    this.setState({
      modalLogin: !this.state.modalLogin,
      sectionLogin: section
    });
  };
  _toggleAddTherapist = () => {
    this.setState({ modalTherapist: !this.state.modalTherapist });
  };
  _toggleAddSupport = () => {
    this.setState({ modalSupport: !this.state.modalSupport });
  };
  _toggleTermsAndConditions = () => {
    this.setState({ modalTermsAndConditions: !this.state.modalTermsAndConditions });
  };
  _togglePrivacyPolicy = () => {
    this.setState({ modalPrivacyPolicy: !this.state.modalPrivacyPolicy });
  };
  _scrollSection = section => {
    scrollToComponent(this[section], {
      offset: -70,
      align: "top",
      duration: 500,
      ease: "linear"
    });
  };
  render() {
    const shapes = {
      orange: ["span-300 top-left two-circles"],
      red: [
        "span-300 top-left two-circles",
        "span-300 top-right three-circles"
      ],
      blue: [
        "span-300 top-right three-circles",
        "span-300 bottom-left three-circles"
      ]
    };
    const {
      modalLogin,
      modalTherapist,
      modalSupport,
      modalTermsAndConditions,
      modalPrivacyPolicy,
      sectionLogin,
      content,
      loading
    } = this.state;
    if (loading) {
      return null;
    }
    return (
      <>
        <ReactNotification className={`notification-pride`} />
        <PrideNavHomepage
          scrollSection={this._scrollSection}
          addTherapist={this._toggleAddTherapist}
          showMenus={true}
        />
        <main ref="main">
          <Hero
            content={content.main}
            ref={section => {
              this.platform = section;
            }}
            supportProject={this._toggleAddSupport}
          />
          <AnimatedSection
            content={content.section_1}
            color="orange"
            shapes={shapes.orange}
            labels={{ label1: "comunidade<br/>científica", label2: "terapeuta" }}
          />
          <Highlights
            content={content.notices}
            ref={section => {
              this.highlights = section;
            }}
          />
          <AnimatedSection
            content={content.section_2}
            color="red"
            shapes={shapes.red}
            labels={{ label1: "terapeuta", label2: "paciente" }}
          />
          <Events
            content={content.events}
            ref={section => {
              this.events = section;
            }}
          />
          <AnimatedSection
            content={content.section_3}
            color="blue"
            shapes={shapes.blue}
            labels={{ label1: "paciente<br/>", label2: "comunidade<br/>científica" }}
          />
          <Team
            content={content.team}
            ref={section => {
              this.team = section;
            }}
          />
          <Faq
            ref={section => {
              this.faq = section;
            }}
          />
          <Newsletter 
            content={content.newsletter} 
          />
          {/* Login SHORTCUT BUTTON */}
          <Login toggleLogin={this._toggleLogin} />
        </main>
        <PrideFooter 
          supportProject={this._toggleAddSupport} 
          viewTermsAndConditions={this._toggleTermsAndConditions} 
          viewPrivacyPolicy={this._togglePrivacyPolicy} 
        />
        {/* Modals */}
        <LoginModal
          visible={modalLogin}
          toggleLogin={this._toggleLogin}
          details={loginMessages[sectionLogin]}
          sectionLogin={sectionLogin}
        />
        <AddTherapist
          visible={modalTherapist}
          toggleModal={this._toggleAddTherapist}
        />
        <SupportProject
          visible={modalSupport}
          toggleModal={this._toggleAddSupport}
        />
        <TermsAndConditionsModal
          visible={modalTermsAndConditions}
          toggleModal={this._toggleTermsAndConditions}
        />
        <PrivacyPolicyModal
          visible={modalPrivacyPolicy}
          toggleModal={this._togglePrivacyPolicy}
        />
      </>
    );
  }
}

export default Index;
