//function save a single item on storage of smartphone
const _saveItem = async(item, value, user = 0) => {
    try {
        await window.localStorage.setItem(item, JSON.stringify(value));
        if(user) {
            await window.localStorage.setItem("user", JSON.stringify(user));
        }
    } catch(error) {
        throw error;
    }
} 
//dispatch success function, should add token on storage
export function SetLoginSuccess(authenticated, super_admin, access_token, id_user, name, email, img_url, job, address, color, permissions, role) {
    return function action(dispatch) {
        //put on storage
        _saveItem('access_token', access_token).then(() => {
            //once storage success
            dispatch(LoginSuccess(authenticated, super_admin, access_token, id_user, name, email, img_url, job, address, color, permissions, role))
        }).catch(error => {
            console.log(error); //redux errors
        })
    }
}
//refresh token
export function SetRefreshToken(token) {
    return function action(dispatch) {
        return _saveItem('access_token', token)
        .then(() => {
            dispatch(RefreshToken(token))
        }).catch(error => {
            console.log(error); //redux errors
        })
    }
}
export function SetLogout() {
    return function action(dispatch) {
        window.localStorage.removeItem('access_token')
        dispatch(LogoutAuth())
    }
}
export function LoginSuccess(authenticated, super_admin, access_token, id_user, name, email, img_url, job, address, color, role) {
    return {
        type: 'LOGIN_SUCCESS',
        payload: {
            authenticated,
            super_admin,
            access_token,
            id_user,
            name,
            email,
            img_url,
            job,
            address,
            color,
            role
        }
    }
}
export function SetProfile(authenticated, super_admin, id_user, name, email, img_url, job, address, color, role) {
    return {
        type: 'SET_USER',
        payload: {
            authenticated,
            super_admin,
            id_user,
            name,
            email,
            img_url,
            job,
            address,
            color,
            role
        }
    }
}
export function UpdateProfile(name, img_url, job) {
    return {
        type: 'UPDATED_PROFILE',
        payload: {
            name,
            img_url,
            job
        }
    }
}
export function RefreshToken(refreshed_token) {
    return {
        type: 'TOKEN_REFRESHED',
        payload: {
            refreshed_token
        }
    }
} 
export function AppLoaded(app_started) {
    return {
        type: 'APP_LOADED',
        payload: {
            app_started,
        }
    }
}
export function LogoutAuth() {
    return {
        type: 'LOGOUT',
    }
}