import React from "react";
import { Container, Row, Col, ListGroup } from "reactstrap";
import { Paginator, Filters, Search } from "components/Common"
import { AddAdmin } from "components/icons"
import { getFaqs } from "services/requests_admin";
import { throwErrorMessage } from 'helpers'
import Fade from 'react-reveal/Fade';
import FaqModal from 'views/modals/admin/FaqModal';
import FaqItem from 'views/admin/contents/FaqItem';
import DeleteModal from 'views/modals/admin/DeleteModal';

class Faqs extends React.Component {
    state = {
        modalFaq: false,
        modalDelete: false,
        action: null,
        title: '',
        faqs: [],
        itemDelete: null,
        faq: null,
        current_page: 1,
        max_page: 1,
        loading: false,
        filters: [ // order flags
            { order: 'asc', orderBy: 'title', name: 'questão', selected: true },
            { order: 'asc', orderBy: 'detail', name: 'resposta', selected: false }
        ],
        search: ""
    }
    componentDidMount() {
        this._fetchFaqs()
    }
    _toggleModalFaq = (title, action = null, faq) => {
        this.setState({ title: title, action: action, modalFaq: !this.state.modalFaq, faq: faq, search: "" });
    }
    _toggleModalDelete = (faq = null) => {
        this.setState({ modalDelete: !this.state.modalDelete });
        if(faq)
            this.setState({ itemDelete: { id: faq.id, name: 'FAQ' } });
    }   
    _fetchFaqs = async (showBar = true) => {
        const { startPB, finishPB } = this.props;
        await this.setState({ loading: true }, () => {
            if (showBar) { startPB() }
        })
        const { current_page, filters, search } = this.state;
        const filter = filters.find(el => el.selected === true);
        // get faqs
        await getFaqs(current_page, filter.order, filter.orderBy, search).then(response => {
            this.setState({
                faqs: response.data,
                max_page: response.meta.last_page,
                loading: false
            }, () => {
                if (showBar) { finishPB() }
            })
        }).catch(error => {
            throwErrorMessage(error)
        })
    }
    _movePage = (dir) => {
        let { current_page } = this.state;
        let value = (dir === 'left') ? (current_page - 1) : (current_page + 1);
        this.setState({ current_page: value }, () => {
            this._fetchFaqs(false) // should not simulate progress bar
        })
    }
    _filterGrid = (key) => {
        let { filters } = this.state;
        const filterIndex = filters.findIndex(el => el.orderBy === key);
        // find current selected index
        const selectedIndex = filters.findIndex(el => el.selected === true)

        filters[selectedIndex].selected = false;
        filters[filterIndex].order = (filters[filterIndex].order === 'asc') ? 'desc' : 'asc';
        filters[filterIndex].selected = true;

        this.setState({ filters }, () => {
            this._fetchFaqs(false)
        }) // update state
    }
    _searchGird = (search) => {
        this.setState({ search });
        this._fetchFaqs(false);
    }
    render() {
        const { faqs, filters, modalFaq, current_page, max_page } = this.state;
        const { color, isLoading } = this.props;
        if (isLoading) {
            return (<div className="loading-div"></div>)
        }
        return (
            <div className="position-relative">
                {/* shape Hero */}
                <section className={`section section-shaped section-app`}>
                    <div className={`shape shape-style-1 shape-app ${color}`}>
                        <span className="bottom-left" />
                        <span className="bottom-right" />
                    </div>
                    <Fade>
                        <Container className="d-flex container-xl">
                            <Row style={{ width: '100%' }}>
                                <Col lg="10" className="col-admin" md="12">
                                    <div className="filters-search">
                                        <div className={`filters-admin ${color}`}>
                                            <strong>ordenar por:</strong>
                                            <Filters filters={filters} applyFilter={this._filterGrid} />
                                        </div>

                                        <div className={`search-admin`}>
                                            <strong>pesquisar por:</strong>
                                            <Search placeholder="questão" applySearch={this._searchGird} />
                                        </div>
                                    </div>
                                    <ListGroup className="list-admin" flush>
                                        {faqs.map(faq => {
                                            return (<FaqItem faq={faq} key={faq.id} toggleModalDelete={this._toggleModalDelete} toggleModal={this._toggleModalFaq} action="edit" title={`Editar FAQ`}/>)
                                        })}
                                    </ListGroup>
                                    <Paginator color={color} currentPage={current_page} maxPage={max_page} movePage={this._movePage} />
                                </Col>
                            </Row>
                        </Container>
                    </Fade>
                    <div className="menu-app-admin">
                        <Fade>
                            <button className="button-menu" onClick={() => this._toggleModalFaq('Adicionar FAQ', 'create')}>
                                <div className="container-icon">
                                    <div className="svg-icon"><AddAdmin /></div>
                                </div>
                                <div className="text-button">
                                    Adicionar FAQ
                                </div>
                            </button>
                        </Fade>
                    </div>
                </section>
                {/* Modals */}
                <FaqModal faq={this.state.faq} action={this.state.action} title={this.state.title} color={color} visible={modalFaq} toggleModal={this._toggleModalFaq} reloadData={this._fetchFaqs} />
                <DeleteModal data={this.state.itemDelete} name="FAQ" object="contents" visible={this.state.modalDelete} toggleModalDelete={this._toggleModalDelete} reloadData={this._fetchFaqs}/>
            </div>
        )
    }
}

export default Faqs;