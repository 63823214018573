import React from "react";

// core components
import PrideNavKnowmore from "components/Navbars/PrideNavKnowmore.jsx";
import scrollToComponent from "react-scroll-to-component";

// index page sections
import { getSection } from "services/requests_homepage";
import ReactNotification from "react-notifications-component";
import SubmitSurveyModal from "views/modals/SubmitSurveyModal.jsx";
import SurveySection from "views/SurveySections/SurveySection.jsx";

class PageSurvey extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modalSubmitSurvey: false,
      loading: false,
      answers: null,
      showMenus: true,
      feedbackTotal: 0,
      feedbackA: true
    };
  }
  _scrollSection = section => {
    scrollToComponent(this[section], {
      offset: -70,
      align: "top",
      duration: 500,
      ease: "linear"
    });
  };

  _toggleSubmitSurvey = (answers) => {
    this.setState({ modalSubmitSurvey: !this.state.modalSubmitSurvey });
    this.setState({ answers: answers });
  };

  _updateFeedbackTotal = (value, step) => {
    if((step == 0 || step == 1) && value == 1) {
      this.setState({ feedbackTotal: this.state.feedbackTotal });
    } else {
      this.setState({ feedbackTotal: this.state.feedbackTotal + value });
    }

    if(this.state.feedbackTotal > 0) {
      this.setState({ feedbackA: false });
    }
  };

  _hideMenus = () => {
    this.setState({ showMenus: false });
  };
  
  render() {
    const {
      modalSubmitSurvey,
      loading,
      showMenus,
      feedbackA
    } = this.state;
    if (loading) {
      return null;
    }
    return (
      <>
        <ReactNotification className={`notification-pride`} />
        <PrideNavKnowmore
          scrollSection={this._scrollSection}
          addTherapist={this._toggleAddTherapist}
          showMenus={showMenus}
          noSelected={true}
        />
        <main ref="survey">
          <div className="survey-page">
            <SurveySection 
              submitSurvey={this._toggleSubmitSurvey}
              hideMenus={this._hideMenus}
              feedbackA={feedbackA}
              updateFeedbackTotal={this._updateFeedbackTotal}
            />
          </div>
          <div className="footer-label">
            <p className="mt-5"></p>
          </div>
        </main>
        <SubmitSurveyModal
          visible={modalSubmitSurvey}
          toggleModal={this._toggleSubmitSurvey}
          answers={this.state.answers}
        />
      </>
    );
  }
}

export default PageSurvey;
