import React from "react";
import { Modal } from "reactstrap";
import PropTypes from 'prop-types';
import Fade from 'react-reveal/Fade';
import SubmitSurveyForm from "views/forms/SubmitSurveyForm";

class SubmitSurveyModal extends React.Component {
    state = {
        visible: false,
    }
    static getDerivedStateFromProps(nextProps, prevState) {
        if(prevState.visible !== nextProps.visible) {
            return ({ visible: nextProps.visible })
        }
        return null;
    }
    render() {
        if(this.state.visible === false) {
            return null;
        }
        const { toggleModal, answers } = this.props;
        return (
            <>
            {this.state.visible &&
                <Fade duration={300}>
                <button className="button-close-modal-pride" aria-label="Close" data-dismiss="modal" type="button" onClick={() => toggleModal()}>
                    <img src={require("assets/img/buttons/close.png")} alt="close" width={97} height={92} />
                </button>
                </Fade>
            }
            <Modal 
                className="modal-dialog-centered modal-app" 
                modalClassName="animated fadeIn" 
                isOpen={this.state.visible} 
                fade={false} 
                backdrop="static" 
                toggle={() => toggleModal()}>
                <div className="modal-header">
                    <h5 className="modal-title"><span className="orange">•</span>Completar Avaliação</h5>
                </div>
                <div className="modal-body mx-0 mt-3 px-3">
                    <h6 className="mt-3 font-weight-900">
                        Após introduzir os seus dados irá receber o questionário para completar a sua avaliação
                    </h6>
                    <SubmitSurveyForm 
                        color="orange" 
                        toggleModal={toggleModal}
                        answers={answers} />
                </div>
            </Modal>
            </>
        )
    }
}

SubmitSurveyModal.propTypes = {
    visible: PropTypes.bool.isRequired,
    toggleModal: PropTypes.func.isRequired,
}

export default SubmitSurveyModal