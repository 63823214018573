import React from 'react'
import { FormGroup, Input as InputStrap, Label, FormText } from "reactstrap";
import classnames from 'classnames'
import ReactHtmlParser from "react-html-parser";

const Input = ({ type, placeholder, label, id, name, rows, value, onChange, error, register, invisible, defaultValue, step, disabled = false, min, max, readOnly = false, data }) => {
    const classNames = classnames({
        "input-pride": true,
        "is-textarea": (type === 'textarea'),
        "has-error": typeof (error) !== 'undefined'
    })
    const classNameFormGroup = classnames({
        "invisible": invisible,
    })
    return (

        <FormGroup className={classNameFormGroup}>
            {label &&
                <Label for={id}>{label}</Label>
            }
            {readOnly &&
                <div>{ReactHtmlParser(data)}</div>
            }
            {!readOnly &&
                <InputStrap id={id} name={name} type={type} value={value} className={classNames} rows={rows} step={step} placeholder={placeholder} onChange={onChange} innerRef={register} defaultValue={defaultValue} disabled={disabled} min={min} max={max} />
            }
            <FormText color="red">
                {typeof (error) !== 'undefined' &&
                    <span>{error.message}</span>
                }
            </FormText>
        </FormGroup>
    )
}

export { Input };