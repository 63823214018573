import React from "react";
import PropTypes from 'prop-types';
import { ListGroupItem } from "reactstrap";
import { Avatar } from "components/Common"
import { formatPrideDate } from 'helpers'
import EllipsisText from "react-ellipsis-text";
import { withRouter } from 'react-router-dom';

class EventItem extends React.Component {

    render() {
        const { event, toggleModal, action, title, toggleModalDelete } = this.props;
        return (
            <ListGroupItem>
                <div className="main-column long-text">
                    <div className="avatar-admin">
                        <Avatar url={event.thumbnail} color={event.color} size={63} />
                    </div>
                    <div className="admin-details">
                        <div className="name"><EllipsisText text={event.title} length={40} /></div>
                        <div className="label"><EllipsisText text={event.subject} length={20} /></div>
                    </div>
                </div>
                <div className="separator-list"><span>•</span></div>
                <div className="date">
                    <div className="value">{formatPrideDate(event.date)}</div>
                    <div className="label">data da ação</div>
                </div>
                <div className="separator-list"><span>•</span></div>
                <div className="state number">
                    <div className="state-container">
                        <div className="value">{!isNaN(event.cost) ? event.cost + ` ` + event.currency : event.cost}</div>
                        <div className="label">inscrição</div>
                    </div>
                </div>
                <div className="separator-list"><span>•</span></div>
                <div className="state number">
                    <div className="state-container">
                        <div className="value">{event.openings}</div>
                        <div className="label">vagas</div>
                    </div>
                </div>
                <div className="separator-list"><span>•</span></div>
                <div className="state">
                    <div className="state-container">
                        <div className="value">{event.active_label}</div>
                        <div className="label">estado</div>
                    </div>
                </div>
                <div className="button">
                    <button title="Editar" className="button-admin" onClick={() => toggleModal(title, action, event.id)} />
                </div>
                <div className="button">
                    <button title="Eliminar" className="button-admin delete" onClick={() => toggleModalDelete(event)} />
                </div>
            </ListGroupItem>
        )
    }

}
EventItem.propTypes = {
    event: PropTypes.object.isRequired,
}

export default withRouter(EventItem);