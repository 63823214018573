import React from "react";
import PropTypes from 'prop-types';
import { ListGroupItem } from "reactstrap";
import { Avatar } from "components/Common"
import { formatPrideDate } from 'helpers'
import { withRouter } from 'react-router-dom';

class PatientItem extends React.Component {
    _navPatient = (id) => {
        this.props.history.push(`/app/patients/${id}`)
    }
    render() {
        const { patient } = this.props;
        return (
            <ListGroupItem style={{cursor:"pointer"}} onClick={() => this._navPatient(patient.id)}>
                <div className="user" style={{width: "60%"}}>
                    <div className="icon-grid">
                        <Avatar url={patient.avatar} color="blue" size={63} />
                    </div>
                    <div className="user-details">
                        <div className="name"> <span>•</span> {patient.name}</div>
                        <div className="job">{patient.occupation}</div>
                    </div>
                </div>
                <div className="separator-list"><span>•</span></div>
                <div className="date">
                    <div className="value">{formatPrideDate(patient.date)}</div>
                    <div className="label">data de início</div>
                </div>
                <div className="separator-list"><span>•</span></div>
                {/* <div className="state">
                    <div className="state-container">
                        <div className="value"><span>•</span> quadro estável</div>
                        <div className="label">quadro clínico</div>
                    </div>
                </div> */}
                <div className="button-add">
                    <button className="button-patient" onClick={() => this._navPatient(patient.id)} />
                </div>
            </ListGroupItem>
        )
    }

}
PatientItem.propTypes = {
    patient: PropTypes.object.isRequired,
}

export default withRouter(PatientItem);