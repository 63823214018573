import React, { memo } from 'react';

const Pencil = memo((props) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" xmlns="http://www.w3.org/1999/xlink" width="20" height="20" viewBox="0 0 50 50">
            <path fill={props.fill == null ? "#B3021C" : props.fill} fillRule="evenodd" d="M41.096 14.618l-4.734-4.743 3.154-3.161 4.74 4.742-3.16 3.162zM8.455 37.796l23.952-23.97 4.741 4.742-23.952 23.97-6.462 1.724 1.721-6.466zM50.178 9.48L41.49.787c-1.046-1.05-2.9-1.05-3.947 0L3.98 34.375c-.042.04-.07.088-.106.13-.07.073-.133.145-.19.224-.048.077-.098.156-.147.237-.042.075-.084.15-.12.231-.041.095-.077.19-.112.288-.014.05-.042.095-.049.146L.097 47.486c-.26.963.013 1.99.723 2.695a2.782 2.782 0 0 0 2.69.725l11.85-3.162c.049-.014.098-.039.147-.056a2.399 2.399 0 0 0 .52-.233c.077-.045.155-.09.232-.143a1.97 1.97 0 0 0 .232-.194c.042-.035.084-.061.126-.098L50.178 13.43a2.784 2.784 0 0 0 0-3.95z" />
        </svg>
    )
})

export { Pencil }