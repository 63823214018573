import React from "react";
import { Row, Col } from "reactstrap";

export default class ReferencesSection extends React.Component {
    render() {
        return(
            <>
            <section>
                <div className="know-more-references">
                    <div className="d-flex align-items-center justify-content-between">
                        <h2 className="font-weight-bold">Referências</h2>
                        <svg 
                            xmlns="http://www.w3.org/2000/svg" 
                            width="35" 
                            height="35" 
                            viewBox="0 0 24 24" 
                            fill="none" 
                            stroke="currentColor" 
                            strokeWidth="2" 
                            strokeLinecap="round" 
                            strokeLinejoin="round">
                            <polyline points="6 9 12 15 18 9"></polyline>
                        </svg>
                    </div>
                    <div className="line"></div>
                    <div className="mt-4">
                        <ul>
                            <li>
                                <span className="font-weight-bold">
                                    Conceição, E. M., Gomes, F. V. S. S., Vaz, A. R., Pinto-Bastos, A., &amp; Machado, P. P. P. P.
                                </span> (2017).
                                Prevalence of eating disorders and picking/nibbling in elderly women. International Journal of Eating Disorders, 50(7). https://doi.org/10.1002/eat.22700
                            </li>
                            <li className="mt-3">
                                <span className="font-weight-bold">
                                    Machado, P. P., Gonçalves, S., &amp; Hoek, H. W.
                                </span> (2013). 
                                DSM-5 reduces the proportion of EDNOS cases: Evidence from community samples. International Journal of Eating Disorders, 46(1), 60-65. https://doi.org/10.1002/eat.22040
                            </li>
                            <li className="mt-3">
                                <span className="font-weight-bold">
                                    Machado, P. P., Machado, B., Gonçalves, S., &amp; Hoek, H. W.
                                </span> (2007). 
                                The prevalence of eating disorders not otherwise specified. International Journal of Eating Disorders, 40(3), 212-217. https://doi.org/10.1002/eat.20358
                            </li>
                        </ul>
                    </div>
                </div>
            </section>
            </>
        )
    }
}