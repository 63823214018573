import AppLayout from './AppLayout';

import Clinic from "views/platform/Therapist/Clinic";
import Patient from "views/platform/Patient/Patient";
import Library from "views/platform/Library";
import Manual from "views/platform/Manual";
import Monitor from "views/platform/Monitor/Monitor";
import Content from "views/platform/Community/Content";
import Colloquium from "views/platform/Shared/Colloquium";
import AdminLayout from 'components/AdminLayout/AdminLayout';
import Profile from "views/platform/Shared/Profile";
import Questionnaires from "views/platform/Therapist/Questionnaires";

// const Clinic = React.lazy(() => import('views/platform/Clinic'));

// https://github.com/ReactTraining/react-router/tree/master/packages/react-router-config
const routes = [
  { path: '/app', exact: true, name: 'app', component: AppLayout },
  { path: '/app/patients/', exact: true, name: 'consultório', component: Clinic, role:['therapist']},
  { path: '/app/patients/:id', exact: true, nome: 'paciente', component: Patient, role:['therapist']},
  { path: '/app/library', name: 'biblioteca', component: Library, role: ['therapist', 'patient'] },
  { path: '/app/manual', name: 'manual', component: Manual, role: ['therapist', 'patient'] },
  { path: '/app/content', name: 'conteúdo', component: Content, role: ['scientific_community'] },
  { path: '/app/colloquium', name: 'colóquio científico', component: Colloquium, role: ['therapist', 'scientific_community'] },
  { path: '/app/profile', name: 'profile', component: Profile, role: ['therapist', 'scientific_community', 'patient'] },
  // { path: '/app/monitor', name: 'monitor', component: Monitor,  role: ['patient'] },
  { path: '/app/questionnaires', name: 'questionnaires', component: Questionnaires,  role: ['therapist'] },
  { path: '/admin', exact: true, name: 'admin', component: AdminLayout, role: ['super_admin'] },
  //{ path: '/users/:id', exact: true, name: 'User Details', component: User },
];

export default routes;