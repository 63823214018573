import React from "react";
import { Row, Col } from "reactstrap";

export default class SymptomsSection extends React.Component {
    render() {
        return(
            <>
            <section>
                <div className="know-more-symptoms">
                    <img src={require("assets/img/know-more/top-left.png")} className="top-left" alt="top-left" />
                    <img src={require("assets/img/know-more/bottom-right.png")} className="bottom-right" alt="bottom-right" />
                    <h3 className="text-left">
                        Apesar de não ser necessária a presença de todos os sintomas numa perturbação alimentar, os sintomas mais comuns são: 
                    </h3>

                    <div className="container-fluid">
                        <Row>
                            <Col>
                                <ul>
                                    <li>
                                        <img src={require("assets/img/know-more/bullet.png")} alt="shape-white" width="30" height="30" />
                                        <span className="ml-2">Pensar constantemente em comida</span>
                                    </li>
                                    <li>
                                        <img src={require("assets/img/know-more/bullet.png")} alt="shape-white" width="30" height="30" />
                                        <span className="ml-2">Dietas</span>
                                    </li>
                                    <li>
                                        <img src={require("assets/img/know-more/bullet.png")} alt="shape-white" width="30" height="30" />
                                        <span className="ml-2">Saltar refeições</span>
                                    </li>
                                    <li>
                                        <img src={require("assets/img/know-more/bullet.png")} alt="shape-white" width="30" height="30" />
                                        <span className="ml-2">Comer sozinho/a</span>
                                    </li>
                                    <li>
                                        <img src={require("assets/img/know-more/bullet.png")} alt="shape-white" width="30" height="30" />
                                        <span className="ml-2">Contar obsessivamente as calorias dos alimentos</span>
                                    </li>
                                    <li>
                                        <img src={require("assets/img/know-more/bullet.png")} alt="shape-white" width="30" height="30" />
                                        <span className="ml-2">Culpa excessiva após as refeições</span>
                                    </li>
                                </ul>
                            </Col>
                            <Col>
                                <ul>
                                    <li>
                                        <img src={require("assets/img/know-more/bullet.png")} alt="shape-white" width="30" height="30" />
                                        <span className="ml-2">Preocupação com a perda de controlo sobre a alimentação</span>
                                    </li>
                                    <li>
                                        <img src={require("assets/img/know-more/bullet.png")} alt="shape-white" width="30" height="30" />
                                        <span className="ml-2">Perda acentuada de peso</span>
                                    </li>
                                    <li>
                                        <img src={require("assets/img/know-more/bullet.png")} alt="shape-white" width="30" height="30" />
                                        <span className="ml-2">Oscilações frequentes de peso</span>
                                    </li>
                                    <li>
                                        <img src={require("assets/img/know-more/bullet.png")} alt="shape-white" width="30" height="30" />
                                        <span className="ml-2">Ausência ou irregularidade nos períodos menstruais</span>
                                    </li>
                                    <li>
                                        <img src={require("assets/img/know-more/bullet.png")} alt="shape-white" width="30" height="30" />
                                        <span className="ml-2">Vómito autoinduzido após as refeições</span>
                                    </li>
                                </ul>
                            </Col>
                            <Col>
                                <ul>
                                    <li>
                                        <img src={require("assets/img/know-more/bullet.png")} alt="shape-white" width="30" height="30" />
                                        <span className="ml-2">Preocupação excessiva com a forma e o peso corporal</span>
                                    </li>
                                    <li>
                                        <img src={require("assets/img/know-more/bullet.png")} alt="shape-white" width="30" height="30" />
                                        <span className="ml-2">Verificação corporal frequente</span>
                                    </li>
                                    <li>
                                        <img src={require("assets/img/know-more/bullet.png")} alt="shape-white" width="30" height="30" />
                                        <span className="ml-2">Evitar olhar para o seu corpo</span>
                                    </li>
                                    <li>
                                        <img src={require("assets/img/know-more/bullet.png")} alt="shape-white" width="30" height="30" />
                                        <span className="ml-2">Exercício físico excessivo</span>
                                    </li>
                                    <li>
                                        <img src={require("assets/img/know-more/bullet.png")} alt="shape-white" width="30" height="30" />
                                        <span className="ml-2">Evitamento de situações sociais que envolvem refeições</span>
                                    </li>
                                </ul>
                            </Col>
                        </Row>
                    </div>
                </div>
            </section>
            </>
        )
    }
}