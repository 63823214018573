import React from "react";
import { Modal } from "reactstrap";
import PropTypes from 'prop-types';
import Fade from 'react-reveal/Fade';
import { Link } from "react-router-dom";
import AddSupportForm from "views/forms/AddSupportForm";

class SupportProject extends React.Component {
    state = {
        visible: false,
    }
    static getDerivedStateFromProps(nextProps, prevState) {
        if(prevState.visible !== nextProps.visible) {
            return ({ visible: nextProps.visible })
        }
        return null;
    }
    render() {
        if(this.state.visible === false) {
            return null;
        }
        const { toggleModal } = this.props;
        return (
            <>
            {this.state.visible &&
                <Fade duration={300}>
                    <button 
                        className="button-close-modal-pride" 
                        aria-label="Close" 
                        data-dismiss="modal" 
                        type="button" 
                        onClick={() => toggleModal()}>
                        <img src={require("assets/img/buttons/close.png")} alt="close" width={97} height={92} />
                    </button>
                </Fade>
            }
            <Modal 
                className="modal-dialog-centered modal-app" 
                modalClassName="animated fadeIn" 
                isOpen={this.state.visible} 
                fade={false} 
                backdrop="static" 
                toggle={() => toggleModal()}>
                <div className="modal-header">
                    <h5 className="modal-title">
                        <span className="orange">•</span>Saiba + Como Apoiar
                    </h5>
                </div>
                <div className="modal-body">
                    <div className="description">
                        <p>
                            O PRIDE é um projeto desenvolvido pelo Grupo de Estudos das Perturbações Alimentares da Escola de Psicologia da Universidade do Minho.
                        </p>
                        <p>
                            Assentes nos princípios do rigor clínico, entusiasmo pela procura de conhecimento baseado em evidência, e na colaboração entre investigadores, cuidadores e profissionais de saúde, pretendemos contribuir para o desenvolvimento do conhecimento na área das perturbações alimentares, na sua prevenção, diagnóstico e tratamento.
                        </p>
                        <p>
                            Nesta secção poderá contactar-nos para saber mais sobre o PRIDE, obter resposta a qualquer questão relacionada com o projeto, ou procurar auxílio para encontrar recursos na sua área de residência relativos a Perturbações Alimentares. 
                            Poderá também contactar-nos através do nosso email <a href="mailto:pride_geral@psi.uminho.pt" title="pride_geral@psi.uminho.pt">pride_geral@psi.uminho.pt</a>. Além disso, neste espaço poderá apoiar esta causa com o seu donativo ou outro tipo de iniciativa, contribuindo para os seguintes pilares de atuação do PRIDE:
                        </p>
                        <p>
                            <strong>
                                Investigação Científica • Intervenção Clínica • Educação • Políticas Públicas
                            </strong>
                        </p>
                    </div>
                    <AddSupportForm color="orange" toggleModal={toggleModal} />
                </div>
            </Modal>
            </>
        )
    }
}

SupportProject.propTypes = {
    visible: PropTypes.bool.isRequired,
    toggleModal: PropTypes.func.isRequired,
}

export default SupportProject