import React from "react";
import { Container, Row, Col, ListGroup } from "reactstrap";
import { getSections } from "services/requests_admin";
import { throwErrorMessage } from 'helpers'
import Fade from 'react-reveal/Fade';
import SectionModal from 'views/modals/admin/SectionModal';
import SectionItem from 'views/admin/contents/SectionItem';

class Sections extends React.Component {
    state = {
        modalSection: false,
        action: null,
        title: '',
        sections: [],
        itemDelete: null,
        section: null,
        current_page: 1,
        max_page: 1,
        loading: false,
        filters: [ // order flags
            { order: 'asc', orderBy: 'title', name: 'cabeçalho', selected: true },
            { order: 'asc', orderBy: 'detail', name: 'descrição', selected: false }
        ],
        search: ''
    }
    componentDidMount() {
        this._fetchSections()
    }
    _toggleModalSection = (title, action = null, section) => {
        this.setState({ title: title, action: action, modalSection: !this.state.modalSection, section: section });
    }  
    _fetchSections = async (showBar = true) => {
        const { startPB, finishPB } = this.props;
        await this.setState({ loading: true }, () => {
            if (showBar) { startPB() }
        })
        const { current_page, filters, search } = this.state;
        const filter = filters.find(el => el.selected === true);
        // get sections
        await getSections(current_page, filter.order, filter.orderBy, search).then(response => {
            this.setState({
                sections: response.data,
                max_page: response.meta.last_page,
                loading: false
            }, () => {
                if (showBar) { finishPB() }
            })
        }).catch(error => {
            throwErrorMessage(error)
        })
    }
    _movePage = (dir) => {
        let { current_page } = this.state;
        let value = (dir === 'left') ? (current_page - 1) : (current_page + 1);
        this.setState({ current_page: value }, () => {
            this._fetchSections(false) // should not simulate progress bar
        })
    }
    _filterGrid = (key) => {
        let { filters } = this.state;
        const filterIndex = filters.findIndex(el => el.orderBy === key);
        // find current selected index
        const selectedIndex = filters.findIndex(el => el.selected === true)

        filters[selectedIndex].selected = false;
        filters[filterIndex].order = (filters[filterIndex].order === 'asc') ? 'desc' : 'asc';
        filters[filterIndex].selected = true;

        this.setState({ filters }, () => {
            this._fetchSections(false)
        }) // update state
    }
    _searchGird = (search) => {
        this.setState({ search });
        this._fetchSections(false);
    }
    render() {
        const { sections, modalSection } = this.state;
        const { color, isLoading } = this.props;
        if (isLoading) {
            return (<div className="loading-div"></div>)
        }
        return (
            <div className="position-relative">
                {/* shape Hero */}
                <section className={`section section-shaped section-app`}>
                    <div className={`shape shape-style-1 shape-app ${color}`}>
                        <span className="bottom-left" />
                        <span className="bottom-right" />
                    </div>
                    <Fade>
                        <Container className="d-flex container-xl">
                            <Row style={{ width: '100%' }}>
                                <Col lg="10" className="col-admin" md="12">
                                    <ListGroup className="list-admin" flush>
                                        {sections.map(section => {
                                            return (<SectionItem section={section} key={section.id} toggleModalDelete={this._toggleModalDelete} toggleModal={this._toggleModalSection} action="edit" title={`Editar Secção - ` + section.header}/>)
                                        })}
                                    </ListGroup>
                                </Col>
                            </Row>
                        </Container>
                    </Fade>
                </section>
                {/* Modals */}
                <SectionModal section={this.state.section} action={this.state.action} title={this.state.title} color={color} visible={modalSection} toggleModal={this._toggleModalSection} reloadData={this._fetchSections} />
            </div>
        )
    }
}

export default Sections;