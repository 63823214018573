import React from "react";
// reactstrap components
import { Modal } from "reactstrap";
import PropTypes from 'prop-types';
import Fade from 'react-reveal/Fade';
import EventForm from "views/forms/admin/EventForm";
import { Avatar } from "components/Common"

class EventModal extends React.Component {
    state = {
        visible: false,
        thumbnail: null
    }
    static getDerivedStateFromProps(nextProps, prevState) {
        if (prevState.visible !== nextProps.visible) {
            return ({ visible: nextProps.visible })
        }
        return null;
    }
    _updateThumbnail = (thumbnail) => {
        this.setState({ thumbnail })
    }
    _openDialogThumbnail = () => {
        document.getElementById('thumbnail-event').click();
    }
    render() {
        if (this.state.visible === false) {
            return null;
        }
        const { toggleModal, color, reloadData, title, action, event } = this.props;
        return (
            <>
                {this.state.visible &&
                    <Fade duration={300}>
                        <button className="button-close-modal-pride" aria-label="Close" data-dismiss="modal" type="button" onClick={() => toggleModal()}>
                            <img src={require("assets/img/buttons/close.png")} alt="close" width={97} height={92} />
                        </button>
                    </Fade>
                }
                <Modal className="modal-dialog-centered modal-app" modalClassName="animated fadeIn" isOpen={this.state.visible} fade={false} backdrop="static" toggle={() => toggleModal()}>
                    <div className="modal-icon">
                        <div className="avatar-container admin clickable" onClick={this._openDialogThumbnail}>
                            <Avatar url={this.state.thumbnail} color="gray" size={182} />
                        </div>
                    </div>
                    <div className="modal-header">
                        <h5 className="modal-title"><span className={color}>•</span>{title}</h5>
                    </div>
                    <div className="modal-body">
                        <EventForm action={action} event={event} color="gray" toggleModal={toggleModal} reloadData={reloadData} updateThumbnail={this._updateThumbnail}/>
                    </div>
                </Modal>
            </>
        )
    }
}

EventModal.propTypes = {
    visible: PropTypes.bool.isRequired,
    toggleModal: PropTypes.func.isRequired,
    reloadData: PropTypes.func.isRequired
}

export default EventModal