import React, { useState, useEffect } from "react";
import useForm from "react-hook-form";
import { Input, RichTextEditor, InputCheckbox } from "components/Common";
import { saveNotice } from "services/requests_admin";
import moment from "moment";
import { notify, throwErrorMessage } from "helpers";

const NoticeForm = (props) => {
  const { register, handleSubmit, errors, setError, } = useForm();
  const {
    color,
    toggleModal,
    reloadData,
    action,
    notice,
    updateThumbnail,
  } = props;
  const [submited, setSubmited] = useState(false);
  const [thumbnail, setThumbnail] = useState(null);

  useEffect(() => {
    if (notice != null) {
      setThumbnail(notice.thumbnail);
      updateThumbnail(notice.thumbnail);
    } else {
      setThumbnail(null);
      updateThumbnail(null);
    }
  }, [updateThumbnail, notice]);

  const onSubmit = (data, event) => {
    event.preventDefault();
    setSubmited(true);
    data["thumbnail"] = thumbnail;
    let detail = document.getElementById("detail").value;
    if (action === "edit")
      data["detail"] = detail === "" ? notice.detail : detail;
    else data["detail"] = detail === "" ? detail : detail;

    if (detail.length > 1000000) {
      setSubmited(false);
      notify("error", 'O conteúdo do detalhe é demasiado grande.<br/>Evite colocar várias imagens de tamanho extenso.');
      return;
    }

    if (action === "edit") data["id"] = notice.id;
    data["action"] = action;
    data["active"] = document.getElementById("active").checked ? 1 : 0;
    saveNotice(data)
      .then((response) => {
        reloadData(); // reload data on list view
        notify("success", response.message);
        toggleModal();
      })
      .catch((response) => {
        throwErrorMessage(response, setError);
        setSubmited(false);
      });
  };

  const handleThumbnail = (files) => {
    if (files[0]) {
      setThumbnail(files[0]);
      updateThumbnail(URL.createObjectURL(files[0]));
    }
  };

  const urlButton = require("assets/img/buttons/add-admin-2@2x.png");

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Input
        type="date"
        placeholder="selecionar data"
        label="Data de publicação *"
        id="publish_at"
        name="publish_at"
        error={errors.publish_at}
        register={register}
        defaultValue={
          action === "edit"
            ? moment(notice.publish_at, "DD-MM-YYYY").format("YYYY-MM-DD")
            : ""
        }
      />

      <Input
        type="text"
        placeholder="título"
        label="Título *"
        id="title"
        name="title"
        error={errors.title}
        register={register}
        defaultValue={action === "edit" ? notice.title : ""}
      />

      <RichTextEditor
        hiddenId="detail"
        id="detail-rte"
        name="detail-rte"
        label="Detalhe *"
        error={errors.detail}
        register={register}
        defaultValue={
          action === "edit" ? (notice.detail === null ? "" : notice.detail) : ""
        }
      />

      <InputCheckbox
        label="Ativo"
        id="active"
        name="active"
        error={errors.active}
        register={register}
        defaultValue={
          action === "edit" ? (notice.active === 1 ? true : false) : true
        }
      />

      <input type="hidden" id="detail" name="detail" />

      <Input
        id="thumbnail-notice"
        type="file"
        name="file"
        className="input-file"
        onChange={(e) => {
          handleThumbnail(e.target.files);
        }}
        accept="image/jpg, image/jpeg, image/png"
        invisible={true}
      />

      <div className="button-form">
        <button
          disabled={submited}
          type="submit"
          className={`button-submit ${color}`}
        >
          <img src={urlButton} alt="guardar" />
          <div className="title">Guardar</div>
        </button>
      </div>
    </form>
  );
};

export default NoticeForm;
