import React from "react";
import { Container, Row, Col } from "reactstrap";
import Fade from "react-reveal/Fade";
import { getManuals } from "services/requests";
import { throwErrorMessage } from "helpers";
import { Download } from "components/icons";
import { downloadFile } from "services/requests";
import ReactHtmlParser from "react-html-parser";

class Library extends React.Component {
  state = {
    current_page: 1,
    max_page: 1,
    manual: {},
    loading: false
  };
  componentDidMount() {
    this._fetchManual();
  }
  _fetchManual = async (showBar = true) => {
    const { startPB, finishPB } = this.props;
    const { current_page } = this.state;

    await this.setState({ loading: true }, () => {
      if (showBar) {
        startPB();
      }
    });

    getManuals("patient", current_page)
      .then(response => {
        this.setState(
          {
            manual: response.data[0],
            max_page: response.meta.last_page,
            loading: false
          },
          () => {
            if (showBar) {
              finishPB();
            }
          }
        );
      })
      .catch(error => {
        throwErrorMessage(error);
      });
  };
  _downloadManual = async (fileName, fileUrl) => {
    await downloadFile(null, fileName, fileUrl).catch(error => {
      throwErrorMessage(error);
    });
  };
  _movePage = async dir => {
    let { current_page } = this.state;
    let value = dir === "left" ? current_page - 1 : current_page + 1;
    this.setState({ current_page: value }, () => {
      this._fetchManual(false); // should not simulate progress bar
    });
  };
  render() {
    const { max_page, current_page, manual } = this.state;
    const { color, isLoading, history } = this.props;

    // if (process.env.NODE_ENV === "production") {
    //   history.push("/soon");
    // }

    if (isLoading) {
      return <div className="loading-div"></div>;
    }
    return (
      <div className="position-relative">
        {/* shape Hero */}
        <section className={`section section-shaped section-app`}>
          <div className={`shape shape-style-1 shape-app ${color}`}>
            <span className="bottom-left-2" />
            <span className="bottom-right" />
          </div>
          <Fade>
            <Container className="d-flex container-lg">
              <Row style={{ width: "100%" }}>
                <Col lg="9" className={`col-manual ${color}`} md="12">
                  <h1 className="title">
                  <span>•</span> {manual.title}
                  </h1>
                  {/* <h5 className="legend">Manua do Terapeuta</h5> */}
                  <div className="content-manual m-t-10" style={{marginTop: "54px"}}>
                    {ReactHtmlParser(manual.content)}
                  </div>
                  {/* <div className="paginator">
                    <Paginator
                      color={color}
                      currentPage={current_page}
                      maxPage={max_page}
                      movePage={this._movePage}
                    />
                  </div> */}
                </Col>
              </Row>
            </Container>
            <Container className="d-flex container-lg">
              <Row className="download-file red">
                <div className="svg-icon">
                  <Download />
                </div>{" "}
                <span
                  onClick={() =>
                    this._downloadManual('Manual do Terapeuta.pdf', manual.files.url)
                  }
                >
                  Descarregar Manual do Terapeuta
                </span>
              </Row>
            </Container>
          </Fade>
          <div className={`menu-app ${color}`}>
            <Fade>
              <div className="button-menu sub-menu active">
                <div className="container-icon">
                  <div className="img-icon">
                    <img
                      src={require("assets/img/buttons/rawpixel-760063-unsplash-1-copy.png")}
                      alt="icon"
                    />
                    <div className="overlay" />
                  </div>
                </div>
                <div className="text-button">
                  <span>•</span> Manual <br /> do Terapeuta
                  {/* <div className="sub-menu">
                    <ul>
                      <li>
                        <a>Bulimia Nervosa</a>
                      </li>
                      <li className="active">
                        <a>Transtorno de Compulsão Alimentar</a>
                      </li>
                      <li>
                        <a>BN e SEO subclinica</a>
                      </li>
                    </ul>
                  </div> */}
                </div>
              </div>

              {/* <button className="button-menu">
                <div className="container-icon">
                  <div className="img-icon">
                    <img
                      src={require("assets/img/test/avatar.png")}
                      alt="icon"
                    />
                    <div className="overlay" />
                  </div>
                </div>
                <div className="text-button">
                  <span>•</span> Exercícios <br /> em Vídeo
                </div>
              </button>
              <button className="button-menu">
                <div className="container-icon">
                  <div className="img-icon">
                    <img
                      src={require("assets/img/test/avatar.png")}
                      alt="icon"
                    />
                    <div className="overlay" />
                  </div>
                </div>
                <div className="text-button">
                  <span>•</span> Material <br /> de Apoio
                </div>
              </button> */}
            </Fade>
          </div>
        </section>
      </div>
    );
  }
}

export default Library;
