import React from 'react'
import { Input, InputGroupAddon, InputGroup, InputGroupText } from "reactstrap";

const renderButton = (color) => {
    if(typeof color === 'undefined') {
        return (<img src={require('assets/img/buttons/right.png')} alt="submit"/>)
    } else {
        if(color === "red") {
            return (<img src={require('assets/img/buttons/right-red.png')} alt="submit" />)
        } else if(color === "blue") {
            return (<img src={require('assets/img/buttons/right-blue.png')} alt="submit" />)
        } else {
            return (<img src={require('assets/img/buttons/right-orange.png')} alt="submit" />)
        }
    }
}
const InputSubmit = ({id, type, placeholder, value, color, size, onChange, onFocus, onBlur, onKeyDown, classNames, onClick, MainRef}) => {
    return (
        <InputGroup className={`input-group-pride ${color || ''} ${classNames || ''} ${size || ''}`}>
            <Input id={id} type={type || 'text'} placeholder={placeholder} value={value} onFocus={onFocus} onBlur={onBlur} onKeyDown={onKeyDown} innerRef={MainRef} onChange={onChange} />
            <InputGroupAddon addonType="append">
                <InputGroupText>
                    <button onClick={onClick}>
                        {renderButton(color)}
                    </button>
                </InputGroupText>
            </InputGroupAddon>
        </InputGroup>
    )
}

export { InputSubmit };