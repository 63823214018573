import React from "react";
import { Container, Row, Col, ListGroup } from "reactstrap";
import { Paginator, Filters } from "components/Common";
import { throwErrorMessage } from "helpers";
import Fade from "react-reveal/Fade";
import { getArticles, getArticle } from "services/requests";
import { AddPlus } from "components/icons";
import ArticleItem from "views/platform/Community/ArticleItem";
import AddArticle from "views/modals/AddArticle";

class Content extends React.Component {
  state = {
    modalAddArticle: false,
    articles: [],
    current_page: 1,
    max_page: 1,
    loading: false,
    mode: null,
    article: null,
    filters: [
      // order flags
      { order: "desc", orderBy: "title", name: "título", selected: false },
      { order: "desc", orderBy: "id", name: "número", selected: false },
      {
        order: "desc",
        orderBy: "created_at",
        name: "data de entrada",
        selected: true
      }
    ]
  };
  componentDidMount() {
    this._fetchArticles();
  }
  _toggleModalContent = async (mode, article = null) => {
    if (mode === "edit") {
      if (!this.state.modalUser) {
        await getArticle(article.id).then(response => {
          this.setState({ article: response });
        });
      }
    } else {
      this.setState({ article: null });
    }

    this.setState({ modalAddArticle: !this.state.modalAddArticle, mode: mode });
  };
  _fetchArticles = async (showBar = true) => {
    const { startPB, finishPB } = this.props;
    await this.setState({ loading: true }, () => {
      if (showBar) {
        startPB();
      }
    });
    const { current_page, filters } = this.state;
    const filter = filters.find(el => el.selected === true);
    // get articles
    await getArticles(current_page, filter.order, filter.orderBy)
      .then(response => {
        this.setState(
          {
            articles: response.data,
            max_page: response.meta.last_page,
            loading: false
          },
          () => {
            if (showBar) {
              finishPB();
            }
          }
        );
      })
      .catch(error => {
        throwErrorMessage(error);
      });
  };
  _movePage = dir => {
    let { current_page } = this.state;
    let value = dir === "left" ? current_page - 1 : current_page + 1;
    this.setState({ current_page: value }, () => {
      this._fetchArticles(false); // should not simulate progress bar
    });
  };
  _filterGrid = key => {
    let { filters } = this.state;
    const filterIndex = filters.findIndex(el => el.orderBy === key);
    // find current selected index
    const selectedIndex = filters.findIndex(el => el.selected === true);

    filters[selectedIndex].selected = false;
    filters[filterIndex].order =
      filters[filterIndex].order === "asc" ? "desc" : "asc";
    filters[filterIndex].selected = true;

    this.setState({ filters }, () => {
      this._fetchArticles(false);
    }); // update state
  };
  render() {
    const {
      articles,
      filters,
      modalAddArticle,
      current_page,
      max_page,
      mode,
      article
    } = this.state;
    const { color, isLoading } = this.props;
    if (isLoading) {
      return <div className="loading-div"></div>;
    }
    return (
      <div className="position-relative">
        {/* shape Hero */}
        <section className={`section section-shaped section-app`}>
          <div className={`shape shape-style-1 shape-app ${color}`}>
            <span className="bottom-left" />
            <span className="bottom-right" />
          </div>
          <Fade>
            <Container className="d-flex container-xl">
              <Row style={{ width: "100%" }}>
                <Col lg="10" className="col-patients" md="12">
                  <div className={`filters-list orange`}>
                    <strong>ordenar por:</strong>
                    <Filters filters={filters} applyFilter={this._filterGrid} />
                  </div>
                  <ListGroup className={`list-patients ${color}`} flush>
                    {articles.map(article => {
                      return (
                        <ArticleItem
                          article={article}
                          key={article.id}
                          toggleArticle={this._toggleModalContent}
                          reloadData={this._fetchArticles}
                          mode="edit"
                        />
                      );
                    })}
                  </ListGroup>
                  <Paginator
                    color={"orange"}
                    currentPage={current_page}
                    maxPage={max_page}
                    movePage={this._movePage}
                  />
                </Col>
              </Row>
            </Container>
          </Fade>
          <div className={`menu-app ${color}`}>
            <Fade>
              <button
                className="button-menu"
                onClick={() => this._toggleModalContent("add")}
              >
                <div className="container-icon">
                  <div className="svg-icon">
                    <AddPlus />
                  </div>
                </div>
                <div className="text-button">
                  <span>•</span> Adicionar Novo Conteúdo
                </div>
              </button>
            </Fade>
          </div>
        </section>
        {/* Modals */}
        <AddArticle
          visible={modalAddArticle}
          toggleModal={this._toggleModalContent}
          color={color}
          reloadData={this._fetchArticles}
          mode={this.state.mode}
          article={article}
        />
      </div>
    );
  }
}

export default Content;
