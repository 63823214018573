import React from "react";

// core components
import PrideNavKnowmore from "components/Navbars/PrideNavKnowmore.jsx";
import scrollToComponent from "react-scroll-to-component";
import PrideFooter from "components/Footers/PrideFooter.jsx";

// index page sections
import SupportProject from "views/modals/SupportProject.jsx";
import TermsAndConditionsModal from "views/modals/TermsAndConditionsModal.jsx";
import PrivacyPolicyModal from "views/modals/PrivacyPolicyModal.jsx";
import AddTherapist from "views/modals/AddTherapist.jsx";
import { getSection } from "services/requests_homepage";
import ProjectSection from "views/KnowMoreSections/ProjectSection.jsx";
import AboutSection from "views/KnowMoreSections/AboutSection.jsx";
import WhatIsSection from "views/KnowMoreSections/WhatIsSection.jsx";
import WhatIsSlider from "views/KnowMoreSections/WhatIsSlider.jsx";
import SymptomsSection from "views/KnowMoreSections/SymptomsSection.jsx";
import StatsSection from "views/KnowMoreSections/StatsSection.jsx";
import TypesSection from "views/KnowMoreSections/TypesSection.jsx";
import TreatmentSection from "views/KnowMoreSections/TreatmentSection.jsx";
import FacilitiesSection from "views/KnowMoreSections/FacilitiesSection.jsx";
import ReferencesSection from "views/KnowMoreSections/ReferencesSection.jsx";
import ReactNotification from "react-notifications-component";

class PageKnowMore extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modalSupport: false,
      modalTermsAndConditions: false,
      modalPrivacyPolicy: false,
      modalTherapist: false,
      loading: false,
    };
  }
  _toggleAddSupport = () => {
    this.setState({ modalSupport: !this.state.modalSupport });
  };
  _toggleTermsAndConditions = () => {
    this.setState({ modalTermsAndConditions: !this.state.modalTermsAndConditions });
  };
  _togglePrivacyPolicy = () => {
    this.setState({ modalPrivacyPolicy: !this.state.modalPrivacyPolicy });
  };
  _toggleAddTherapist = () => {
    this.setState({ modalTherapist: !this.state.modalTherapist });
  };
  _handleClickScroll = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };
  _scrollSection = section => {
    scrollToComponent(this[section], {
      offset: -70,
      align: "top",
      duration: 500,
      ease: "linear"
    });
  };
  render() {
    const {
      modalSupport,
      modalTermsAndConditions,
      modalPrivacyPolicy,
      modalTherapist,
      loading
    } = this.state;
    if (loading) {
      return null;
    }
    return (
      <>
        <ReactNotification className={`notification-pride`} />
        <PrideNavKnowmore
          scrollSection={this._scrollSection}
          addTherapist={this._toggleAddTherapist}
          showMenus={true}
        />
        <main ref="know-more">
          <ProjectSection
            handleClickScroll={this._handleClickScroll}
          />
          <AboutSection
            supportProject={this._toggleAddSupport}
          />
          <WhatIsSection/>
          <WhatIsSlider/>
          <SymptomsSection/>
          <StatsSection/>
          <TypesSection/>
          <TreatmentSection/>
          <FacilitiesSection
            supportProject={this._toggleAddSupport}
          />
          <ReferencesSection/>
        </main>
        <PrideFooter 
          supportProject={this._toggleAddSupport} 
          viewTermsAndConditions={this._toggleTermsAndConditions} 
          viewPrivacyPolicy={this._togglePrivacyPolicy}
          />
        {/* Modals */}
        <SupportProject
          visible={modalSupport}
          toggleModal={this._toggleAddSupport}
        />
        <TermsAndConditionsModal
          visible={modalTermsAndConditions}
          toggleModal={this._toggleTermsAndConditions}
        />
        <PrivacyPolicyModal
          visible={modalPrivacyPolicy}
          toggleModal={this._togglePrivacyPolicy}
        />
        <AddTherapist
          visible={modalTherapist}
          toggleModal={this._toggleAddTherapist}
        />
      </>
    );
  }
}

export default PageKnowMore;
