import React from "react";
import { Modal, Row } from "reactstrap";
import PropTypes from "prop-types";
import Fade from "react-reveal/Fade";
import ReactHtmlParser from "react-html-parser";
import { Paginator, Input, InputSelect, Chart } from "components/Common";
import { notify, throwErrorMessage, formatPrideDate } from "helpers";
import { submitQuestionnaire } from "services/requests";
import { Download } from "components/icons";
import moment from "moment";
import html2canvas from "html2canvas";
import pdfMake from "pdfmake/build/pdfmake";
import htmlToImage from "html-to-image";

const ref = React.createRef();

class QuestionnaireModal extends React.Component {
  state = {
    visible: false,
    current_page: 1,
    questions: [],
    max_page: null,
    total_answers: 0,
    answers: [],
    show_chart: false,
  };
  static getDerivedStateFromProps(nextProps, prevState) {
    if (prevState.visible !== nextProps.visible) {
      return { visible: nextProps.visible };
    }
    if (prevState.questions !== nextProps.questionnaire.questions) {
      return { questions: nextProps.questionnaire.questions };
    }
    if (prevState.currentPage !== nextProps.questionnaire_page) {
      return { questionnaire_page: nextProps.questionnaire_page };
    }
    return null;
  }
  _selectAnswer = (e, answer, question) => {
    switch (question.answer_type) {
      case "input-number":
      case "input-decimal":
        if (e.target.value < answer.min) {
          notify(
            "error",
            "O valor tem que ser maior ou igual a " + answer.min + "."
          );
          return;
        } else if (answer.max !== null && e.target.value > answer.max) {
          notify(
            "error",
            "O valor tem que ser menor ou igual a " + answer.max + "."
          );
          return;
        }
        break;
    }

    let _question = this.state.questions.find(
      (x) =>
        x.questionnaire_reference === question.questionnaire_reference &&
        x.question_reference === question.question_reference
    );

    let _answers = null;
    switch (_question.answer_type) {
      case "dropdown":
        _answers = _question.answers.filter(function (a) {
          return (a["selected"] = 0);
        });
        answer = answer.find((x) => x.id == e.target.value);
        answer["selected"] = 1;
        break;
      case "radio":
        _answers = _question.answers.filter(function (a) {
          return (a["selected"] = 0);
        });
        answer["selected"] = 1;
        break;
      case "input-number":
      case "input-decimal":
        _answers = _question.answers.filter(function (a) {
          return (a["value"] = 0);
        });
        answer["value"] = e.target.value;
        break;
    }

    _answers = _answers.concat(answer);
    let questions = { ...this.state.questions };

    questions.question = _question;

    this.setState({ questions });

    this._setAnswers(question, answer);
  };

  _setAnswers = (question, answer) => {
    let { answers } = this.state;
    let _answers = answers.filter(function (a) {
      return a["question_reference"] !== question.question_reference;
    });
    if (answer.value !== "" && answer.value !== null) {
      _answers = _answers.concat({
        question_reference: question.question_reference,
        value: answer.value,
      });
    }
    this.setState({ answers: _answers });
  };

  _movePage = () => {
    let {
      current_page,
      max_page,
      questions,
      total_answers,
      answers,
    } = this.state;

    let { action, started_time } = this.props;

    const _started_time = moment(started_time);
    const _current_time = moment(new Date());

    if (
      action === "todo" &&
      moment.duration(_current_time.diff(_started_time)).minutes() >= 60
    ) {
      this._closeQuestionnaire(false);
      notify(
        "error",
        "Passou o limite de tempo<br/>(1 hora) para responder ao questionário!<br/>Este questionário não será válido e deverá responder novamente.",
        10000
      );
      return;
    }

    let _questions = questions.filter((x) => x.page === current_page);

    let _answers = answers;

    let _total_answers = _questions.length + total_answers;

    _questions
      .filter((x) => x.answer_type === "dropdown")
      .forEach((_q) => {
        let _a = _q.answers.find((x) => x.selected === 1);
        if (!_a) _a = _q.answers[0];

        if (
          !_answers.find((x) => x.question_reference === _q.question_reference)
        )
          _answers = _answers.concat({
            question_reference: _q.question_reference,
            value: _a.value,
          });
      });

    if (action === "todo") {
      if (_answers.length < _total_answers || _answers.length === 0) {
        notify("error", "Tem que responder a todas as questões.");
        return;
      }
    }

    this.setState({
      total_answers: _total_answers,
    });

    let next_page = current_page + 1;
    let questionnaire = this.props.questionnaire;

    if (next_page > questionnaire.infos.number_pages) {
      if (action === "results") {
        this.setState({ current_page: 1 });
        return;
      }

      let { q_todo_id, reloadData } = this.props;
      submitQuestionnaire(questionnaire.infos.reference, q_todo_id, _answers)
        .then((response) => {
          reloadData(true, false).then(() => this._closeQuestionnaire(true));
          notify("success", response.message);
          return;
        })
        .catch((response) => {
          throwErrorMessage(response);
        });
    } else this.setState({ current_page: next_page });
  };
  _closeQuestionnaire = (submit) => {
    this.setState({ current_page: 1, questions: [], answers: [] });
    this.props.toggleModal(true, -1);
  };
  _render_answer_header = (question_reference, answer_header) => {
    switch (answer_header) {
      case "EDE-Q-1":
        return (
          <>
            <table className="table table-borderless">
              <thead>
                <tr>
                  <th scope="col">
                    Nenhum
                    <br />
                    dia
                  </th>
                  <th scope="col">
                    1-5
                    <br />
                    dias
                  </th>
                  <th scope="col">
                    6-12
                    <br />
                    dias
                  </th>
                  <th scope="col">
                    13-15
                    <br />
                    dias
                  </th>
                  <th scope="col">
                    16-22
                    <br />
                    dias
                  </th>
                  <th scope="col">
                    23-27
                    <br />
                    dias
                  </th>
                  <th scope="col">
                    Todos
                    <br />
                    os dias
                  </th>
                </tr>
              </thead>
            </table>
          </>
        );
      case "EDE-Q-2":
        return (
          <>
            <table className="table table-borderless">
              <thead>
                <tr>
                  <th scope="col">Nenhuma</th>
                  <th scope="col">Algumas</th>
                  <th scope="col">
                    Menos de
                    <br />
                    metade
                  </th>
                  <th scope="col">Metade</th>
                  <th scope="col">
                    Mais de
                    <br />
                    metade
                  </th>
                  <th scope="col">A maioria</th>
                  <th scope="col">Sempre</th>
                </tr>
              </thead>
            </table>
          </>
        );
      case "EDE-Q-3":
        return (
          <>
            <table className="table table-borderless">
              <thead>
                <tr>
                  <th scope="col">Nada</th>
                  <th scope="col"></th>
                  <th scope="col">
                    Ligeira-
                    <br />
                    mente
                  </th>
                  <th scope="col"></th>
                  <th scope="col">
                    Modera-
                    <br />
                    damente
                  </th>
                  <th scope="col"></th>
                  <th scope="col">
                    Extrema-
                    <br />
                    mente
                  </th>
                </tr>
              </thead>
            </table>
          </>
        );
      case "ED-15-1":
        return (
          <>
            <table className="table table-borderless">
              <thead>
                <tr>
                  <th scope="col">Nada</th>
                  <th scope="col">
                    Rara-
                    <br />
                    mente
                  </th>
                  <th scope="col">
                    Ocasional-
                    <br />
                    mente
                  </th>
                  <th scope="col">Às vezes</th>
                  <th scope="col">
                    Frequente-
                    <br />
                    mente
                  </th>
                  <th scope="col">
                    A maior
                    <br />
                    parte do tempo
                  </th>
                  <th scope="col">Sempre</th>
                </tr>
              </thead>
            </table>
          </>
        );
      case "ED-15-2":
        return (
          <>
            <table
              className="table table-borderless ED-15-2"
              style={{ width: "100px" }}
            >
              <thead>
                <tr>
                  <th scope="col">
                    Número de <b>vezes</b>
                  </th>
                </tr>
              </thead>
            </table>
          </>
        );
      case "ED-15-3":
        return (
          <>
            <table
              className="table table-borderless ED-15-3"
              style={{ width: "100px" }}
            >
              <thead>
                <tr>
                  <th scope="col">
                    Número de <b>DIAS</b>
                  </th>
                </tr>
              </thead>
            </table>
          </>
        );
      case "CIA-1":
        return (
          <>
            <table className="table table-borderless">
              <thead>
                <tr>
                  <th scope="col">Nada</th>
                  <th scope="col">Um pouco</th>
                  <th scope="col">Bastante</th>
                  <th scope="col">Muito</th>
                </tr>
              </thead>
            </table>
          </>
        );
      case "EADS-21-1":
        return (
          <>
            <table className="table table-borderless">
              <thead>
                <tr>
                  <th scope="col">
                    Não se aplicou
                    <br />
                    nada a mim
                  </th>
                  <th scope="col">
                    Aplicou-se
                    <br />a mim
                    <br />
                    algumas vezes
                  </th>
                  <th scope="col">
                    Aplicou-se
                    <br />a mim
                    <br />
                    muitas vezes
                  </th>
                  <th scope="col">
                    Aplicou-se
                    <br />a mim maior
                    <br />
                    parte das vezes
                  </th>
                </tr>
              </thead>
            </table>
          </>
        );
      case "EDRE-1":
        return (
          <>
            <table className="table table-borderless">
              <thead>
                <tr>
                  <th scope="col">
                    Quase
                    <br />
                    nunca
                    <br />
                    (0-10%)
                  </th>
                  <th scope="col">
                    Algumas
                    <br />
                    vezes
                    <br />
                    (11-35%)
                  </th>
                  <th scope="col">
                    Metade
                    <br />
                    das vezes
                    <br />
                    (36-65%)
                  </th>
                  <th scope="col">
                    A maioria
                    <br />
                    das vezes
                    <br />
                    (66-90%)
                  </th>
                  <th scope="col">
                    Quase
                    <br />
                    sempre
                    <br />
                    (91-100%)
                  </th>
                </tr>
              </thead>
            </table>
          </>
        );
      case "WAI-SR-1":
        return (
          <>
            <table className="table table-borderless">
              <thead>
                <tr>
                  <th scope="col">Raramente</th>
                  <th scope="col">
                    Ocasional-
                    <br />
                    mente
                  </th>
                  <th scope="col">
                    Muitas
                    <br />
                    vezes
                  </th>
                  <th scope="col">
                    Frequente-
                    <br />
                    mente
                  </th>
                  <th scope="col">Sempre</th>
                </tr>
              </thead>
            </table>
          </>
        );
      case "WAI-SR-2":
        return (
          <>
            <table className="table table-borderless">
              <thead>
                <tr>
                  <th scope="col">Sempre</th>
                  <th scope="col">
                    Frequente-
                    <br />
                    mente
                  </th>
                  <th scope="col">
                    Muitas
                    <br />
                    vezes
                  </th>
                  <th scope="col">
                    Ocasional-
                    <br />
                    mente
                  </th>
                  <th scope="col">Raramente</th>
                </tr>
              </thead>
            </table>
          </>
        );
      case "IIP-32-1":
        return (
          <>
            <table className="table table-borderless">
              <thead>
                <tr>
                  <th scope="col">
                    De forma
                    <br />
                    alguma
                  </th>
                  <th scope="col">Um pouco</th>
                  <th scope="col">
                    Moderada-
                    <br />
                    mente
                  </th>
                  <th scope="col">Bastante</th>
                  <th scope="col">
                    Extrema-
                    <br />
                    mente
                  </th>
                </tr>
              </thead>
            </table>
          </>
        );
    }
  };
  _render_answer_section_header_style = (
    side,
    question_reference,
    current_page
  ) => {
    if (side === "left") {
      switch (question_reference) {
        case "EDE-Q":
          return { marginBottom: "-35px" };
        case "ED-15":
          switch (current_page) {
            case 1:
              return { marginBottom: "-52px" };
          }
          return {};
        case "CIA":
          return { marginBottom: "-45px" };
        case "IIP-32":
          switch (current_page) {
            case 1:
              return { marginBottom: "-30px" };
            case 2:
              return { marginBottom: "-33px" };
          }
          return {};
      }
    } else if (side === "right") {
      switch (question_reference) {
        case "CIA":
          return { marginBottom: "-103px" };
      }
    }
  };
  _render_answer_section_header = (questionnaire, question_reference) => {
    let { current_page } = this.state;
    let render_answer_section_header = questionnaire.descriptions.find(
      (x) =>
        x.type === "render_answer_section_header" &&
        x.page === current_page &&
        x.position === question_reference
    );
    if (render_answer_section_header) {
      return (
        <>
          <div className="row">
            <div
              style={this._render_answer_section_header_style(
                "left",
                questionnaire.infos.reference,
                current_page
              )}
              className={
                render_answer_section_header.additional_data
                  ? `col-6 answer-section-header ${questionnaire.infos.reference}`
                  : `col-12 answer-section-header ${questionnaire.infos.reference}`
              }
            >
              <span style={{ display: "inline-block" }}>
                {ReactHtmlParser(render_answer_section_header.description)}
                <hr />
              </span>
            </div>
            {render_answer_section_header.additional_data && (
              <>
                <div
                  style={this._render_answer_section_header_style(
                    "right",
                    questionnaire.infos.reference,
                    current_page
                  )}
                  className="col-6"
                >
                  {this._render_answer_header(
                    null,
                    render_answer_section_header.additional_data.answer_type
                  )}
                </div>
              </>
            )}
          </div>
        </>
      );
    }
  };
  _render_questionnaire = (data) => {
    let { action } = this.props;
    return (
      <>
        <div className="container">
          {data.questions_current_page
            .filter((x) => x.page === data.current_page)
            .map((question) => {
              return (
                <React.Fragment key={question.id}>
                  {this._render_answer_section_header(
                    data.questionnaire,
                    question.question_reference
                  )}
                  <div
                    className={
                      [1].includes(question.render_answer_header)
                        ? `row render_answer_header`
                        : `row`
                    }
                  >
                    <div
                      className="col-6" /*{question.answer_type === "radio" ? `col-7` : `col-7`}*/
                    >
                      {question.show_question_reference === 1 && (
                        <>
                          {question.question_reference}
                          <span> • </span>
                        </>
                      )}
                      {ReactHtmlParser(question.question)}
                    </div>
                    {question.answer_type === "radio" && question.answers && (
                      <>
                        <div className="col-6">
                          {[2].includes(question.render_answer_header) && (
                            <>
                              {this._render_answer_header(
                                question.reference,
                                question.answer_header
                              )}
                            </>
                          )}
                          <table className="table table-borderless">
                            <tbody>
                              <tr>
                                {question.answers.map((answer) => {
                                  return (
                                    <td
                                      key={answer.id}
                                      className={`${action}${
                                        answer.selected === 1
                                          ? ` answer-selected`
                                          : ``
                                      }`}
                                      onClick={
                                        action === "results"
                                          ? null
                                          : (e) =>
                                              this._selectAnswer(
                                                e,
                                                answer,
                                                question
                                              )
                                      }
                                    >
                                      {answer.value}
                                    </td>
                                  );
                                })}
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </>
                    )}
                    {question.answer_type === "input-number" && (
                      <>
                        <div
                          className={
                            `row answers-` +
                            question.reference +
                            ` ` +
                            question.answer_type
                          }
                        >
                          <Input
                            disabled={action == "results" ? true : false}
                            type="number"
                            min={question.answers[0].min}
                            max={question.answers[0].max}
                            value={question.answers[0].value}
                            className={`col-sm`}
                            onChange={(e) =>
                              this._selectAnswer(
                                e,
                                question.answers[0],
                                question
                              )
                            }
                          />
                        </div>
                      </>
                    )}
                    {question.answer_type === "input-decimal" && (
                      <>
                        <div
                          className={
                            `row answers-` +
                            question.reference +
                            ` ` +
                            question.answer_type
                          }
                        >
                          <Input
                            disabled={action == "results" ? true : false}
                            type="number"
                            step="0.1"
                            min="0"
                            value={question.answers[0].value}
                            className={`col-sm`}
                            onChange={(e) =>
                              this._selectAnswer(
                                e,
                                question.answers[0],
                                question
                              )
                            }
                          />
                        </div>
                      </>
                    )}
                    {question.answer_type === "dropdown" && (
                      <>
                        <div
                          className={
                            `row answers-` +
                            question.reference +
                            ` ` +
                            question.answer_type
                          }
                        >
                          {action === "results" && (
                            <Input
                              disabled
                              type="text"
                              value={
                                question.answers.find((x) => x.selected === 1)
                                  .name
                              }
                              style={{
                                height: "36px",
                                paddingTop: "6px",
                              }}
                            />
                          )}
                          {action === "todo" && (
                            <InputSelect
                              onChange={(e) =>
                                this._selectAnswer(
                                  e,
                                  question.answers,
                                  question
                                )
                              }
                              defaultValue={0}
                              options={question.answers}
                              style={{
                                height: "36px",
                                paddingTop: "6px",
                              }}
                            />
                          )}
                        </div>
                      </>
                    )}
                  </div>
                </React.Fragment>
              );
            })}
        </div>
      </>
    );
  };
  _render_paginator = () => {
    let { questionnaire, role, action } = this.props;

    let render_paginator = true,
      number_pages = questionnaire.infos.number_pages;

    if (role === "therapist" && number_pages <= 1) render_paginator = false;

    if (action === "chart") render_paginator = false;

    return render_paginator;
  };
  _download = () => {
    const { questionnaire, role } = this.props;
    html2canvas(
      document
        .getElementById("download-questionnaire")
        .getElementsByTagName("div")[0]
    ).then((canvas) => {
      var data = canvas.to.toDataURL();
      var pdfExportSetting = {
        pageSize: "A5",
        pageBreakBefore: function (
          currentNode,
          followingNodesOnPage,
          nodesOnNextPage,
          previousNodesOnPage
        ) {
          return (
            currentNode.headlineLevel === 1 && followingNodesOnPage.length === 0
          );
        },
        content: [
          {
            image: data,
            width: 500,
          },
        ],
      };
      pdfMake
        .createPdf(pdfExportSetting)
        .download(
          "Questionário " +
            questionnaire.infos.title +
            " " +
            formatPrideDate(questionnaire.finished_at) +
            (role == "patient" ? "" : " - " + questionnaire.patient.name)
        );
    });
  };
  render() {
    if (this.state.visible === false) {
      return null;
    }

    const { current_page, questions, show_chart } = this.state;
    const { action, color, questionnaire, role } = this.props;

    let instructions = null,
      after_question = null,
      questions_current_page = null,
      render_answer_section_header = null;

    if (show_chart === false) {
      instructions = questionnaire.descriptions.find(
        (x) => x.page === current_page && x.type === "instructions"
      );

      after_question = questionnaire.descriptions.find(
        (x) => x.page === current_page && x.type === "after_question"
      );

      questions_current_page = questions.filter((x) => x.page === current_page);

      render_answer_section_header = questionnaire.descriptions.find(
        (x) =>
          x.page === current_page && x.type === "render_answer_section_header"
      );
    }

    return (
      <>
        {this.state.visible && (
          <Fade duration={300}>
            <button
              className="button-close-modal-pride"
              aria-label="Close"
              data-dismiss="modal"
              type="button"
              onClick={() => this._closeQuestionnaire(false)}
              style={{ marginLeft: "23px", marginTop: "48px" }}
            >
              <img
                src={require("assets/img/buttons/close.png")}
                alt="close"
                width={65}
                height={63}
              />
            </button>
          </Fade>
        )}
        <Modal
          id="download-questionnaire"
          className="modal-questionnaire"
          modalClassName="animated fadeIn"
          isOpen={this.state.visible}
          fade={false}
          backdrop="static"
          toggle={
            action !== "results" ? null : () => this._closeQuestionnaire(false)
          }
        >
          <div className="modal-header">
            <h5 className="modal-title">
              <span className={color}>•</span>Questionário{" "}
              {questionnaire.infos.title}
            </h5>
          </div>
          <div ref={ref} className="modal-body">
            {current_page === 1 && ["results", "chart"].indexOf(action) > -1 && (
              <>
                {/* <Row className={`download-file questionnaire ${color}`}>
                  <div className="svg-icon">
                    <Download
                      fill={role === "patient" ? "#25AFC4" : "#b3021c"}
                    />
                  </div>{" "}
                  <span onClick={() => this._download()}>
                    {role === "therapist"
                      ? `Descarregar questionário`
                      : `Descarregar gráfico`}
                  </span>
                </Row> */}
                <div className="title">
                  Resultado do questionário <span>•</span>{" "}
                  {formatPrideDate(questionnaire.finished_at)}
                </div>{" "}
                <Chart
                  chart={questionnaire.chart}
                  questionnaire_reference={questionnaire.infos.reference}
                />
              </>
            )}

            {action == "todo" && instructions && (
              <>
                <div className="instructions">
                  {ReactHtmlParser(instructions.description)}
                </div>
              </>
            )}

            {current_page === 1 && action == "results" && (
              <>
                {questionnaire.results && (
                  <>
                    <div className="title">{questionnaire.results.title}</div>
                    {questionnaire.results.data && (
                      <>
                        {questionnaire.results.data.map((i, index) => {
                          return (
                            <p key={index} style={{ textIndent: "20px" }}>
                              {i.question_reference} <span>•</span> {i.name} -{" "}
                              {i.value}
                            </p>
                          );
                        })}
                      </>
                    )}
                  </>
                )}

                <hr />
              </>
            )}
            {after_question && (
              <>
                <div className="after_question">
                  {ReactHtmlParser(after_question.description)}
                </div>
              </>
            )}
            {questions_current_page && (
              <>
                {this._render_questionnaire({
                  questionnaire: questionnaire,
                  questions: questions,
                  render_answer_section_header: render_answer_section_header,
                  current_page: current_page,
                  questions_current_page: questions_current_page,
                })}
              </>
            )}
            {this._render_paginator() && (
              <Paginator
                color={color}
                currentPage={current_page}
                maxPage={questionnaire.infos.number_pages}
                movePage={this._movePage}
                render_icon={true}
                classProps={"m-b-30"}
                icon={{
                  url: require(`assets/img/buttons/right-${color}.png`),
                  label:
                    current_page === questionnaire.infos.number_pages
                      ? role === "therapist"
                        ? "Voltar ao início"
                        : "Submeter"
                      : "Próximo",
                }}
              />
            )}
          </div>
        </Modal>
      </>
    );
  }
}

QuestionnaireModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  toggleModal: PropTypes.func.isRequired,
  questionnaire: PropTypes.object,
};

export default QuestionnaireModal;
