import React from "react";
import PropTypes from 'prop-types';
import { ListGroupItem } from "reactstrap";
import EllipsisText from "react-ellipsis-text";
import { withRouter } from 'react-router-dom';

class SectionItem extends React.Component {

    render() {
        const { title, action, section, toggleModal } = this.props;
        return (
            <ListGroupItem>
                <div className="main-column">
                    <div className="admin-details">
                        <div className="name"><EllipsisText text={section.header} length={50} /></div>
                    </div>
                </div>
                {!['footer'].includes(section.content_for)
                    ?
                    <div className="separator-list"><span>•</span></div>
                    :
                    null
                }
                <div className="main-column">
                    <div className="state-container">
                        <div className="name">
                            {!['footer'].includes(section.content_for)
                                ?
                                <EllipsisText text={section.description} length={200} />
                                :
                                ``
                            } </div>
                    </div>
                </div>
                <div className="button">
                    <button title="Editar" className="button-admin" onClick={() => toggleModal(title, action, section)} />
                </div>
            </ListGroupItem>
        )
    }
}
SectionItem.propTypes = {
    section: PropTypes.object.isRequired,
}

export default withRouter(SectionItem);