import React from "react";
import { Container, Row, Col, ListGroup } from "reactstrap";
import { Paginator, Filters } from "components/Common"
import { throwErrorMessage } from 'helpers'
import Fade from 'react-reveal/Fade';
import { getRecords } from "services/requests";
import { AddPlus } from "components/icons"
import RecordItem from 'views/platform/Monitor/RecordItem';
import AddRecordPatient from "views/modals/AddRecordPatient";

class Monitor extends React.Component {
    state = {
        modalAddRecord: false,
        records: [],
        current_page: 1,
        max_page: 1,
        loading: false,
        modeRecord: 'add',
        record: {},
        filters: [ // order flags
            {order: 'desc', orderBy: 'id', name: 'nome', selected: false},
            {order: 'desc', orderBy: 'created_at', name: 'data de início', selected: true}
        ]
    }
    componentDidMount() {
        this._fetchRecords()
    }
    _toggleModalRecord = (mode, record = null) => {
        this.setState({modalAddRecord: !this.state.modalAddRecord, modeRecord: mode, record});
    }
    _fetchRecords = async(showBar = true) => {
        const { startPB, finishPB, user } = this.props;
        await this.setState({loading: true}, () => {
            if(showBar) { startPB() }
        })
        const { current_page, filters } = this.state;
        const filter = filters.find(el => el.selected === true);
        // get records
        await getRecords(user.id, current_page, filter.order, filter.orderBy).then(response => {
            this.setState({
                records: response.data, 
                max_page: response.meta.last_page, 
                loading: false
            }, () => {
                if(showBar) { finishPB() }
            })
        }).catch(error => {
            throwErrorMessage(error)
        })
    }
    _movePage = (dir) => {
        let { current_page } = this.state;
        let value = (dir === 'left') ? (current_page - 1) : (current_page + 1);
        this.setState({current_page: value}, () => {
            this._fetchRecords(false) // should not simulate progress bar
        })
    }
    _filterGrid = (key) => {
        let { filters } = this.state;
        const filterIndex = filters.findIndex(el => el.orderBy === key);
        // find current selected index
        const selectedIndex = filters.findIndex(el => el.selected === true)
        
        filters[selectedIndex].selected = false;
        filters[filterIndex].order = (filters[filterIndex].order === 'asc') ? 'desc' : 'asc';
        filters[filterIndex].selected = true;

        this.setState({filters}, () => {
            this._fetchRecords(false)
        }) // update state
    }
    render() {
        const { records, filters, modalAddRecord, current_page, max_page, modeRecord, record } = this.state;
        const { color, isLoading, user } = this.props;
        if(isLoading) {
            return ( <div className="loading-div"></div> )
        }
        return (
            <div className="position-relative">
            {/* shape Hero */}
            <section className={`section section-shaped section-app`}>
              <div className={`shape shape-style-1 shape-app ${color}`}>
                <span className="bottom-left" />
                <span className="bottom-right" />
              </div>
              <Fade>
              <Container className="d-flex container-xl">
                <Row style={{width: '100%'}}>
                    <Col lg="10" className="col-patients" md="12">
                        <div className={`filters-list blue`}>
                            <strong>ordenar por:</strong> 
                            <Filters filters={filters} applyFilter={this._filterGrid} />
                        </div>
                        <ListGroup className={`list-patients ${color}`} flush>
                            {records.map(record => {
                                return ( <RecordItem record={record} key={record.id} toggleModalRecord={this._toggleModalRecord} /> )
                            })}
                        </ListGroup>
                        <Paginator color={"blue"} currentPage={current_page} maxPage={max_page} movePage={this._movePage} />
                    </Col>
                </Row>
              </Container>
              </Fade>
              <div className={`menu-app ${color}`}>
                <Fade>
                <button className="button-menu" onClick={() => this._toggleModalRecord('add')}>
                    <div className="container-icon">
                        <div className="svg-icon"><AddPlus /></div>
                    </div>
                    <div className="text-button">
                        <span>•</span> Adicionar Novo Registo
                    </div>
                </button>
                </Fade>
              </div>
            </section>
            {/* Modals */}
            <AddRecordPatient visible={modalAddRecord} patient={user} toggleModal={this._toggleModalRecord} color={"blue"} reloadData={() => this._fetchRecords(false)} mode={modeRecord} record={record} />
          </div>
        )
    }
}

export default Monitor;