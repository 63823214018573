import React, { memo } from 'react';

const Box = memo(() => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="51" height="51" viewBox="0 0 51 51">
            <g fill="#B3021C" fillRule="evenodd">
                <path d="M37.908 19.012v18.443c0 .821-.666 1.487-1.487 1.487H13.516a1.487 1.487 0 0 1-1.488-1.487V19.012h2.974v16.956h19.932V19.012h2.974z"/>
                <path d="M13.216 17.524H36.72v-3.456H13.216v3.456zM38.207 20.5H11.73a1.488 1.488 0 0 1-1.487-1.487V12.58c0-.821.665-1.487 1.487-1.487h26.478c.822 0 1.488.666 1.488 1.487v6.432c0 .821-.666 1.487-1.488 1.487zM28.091 25.382h-6.544a1.495 1.495 0 0 1-1.487-1.496c0-.821.666-1.497 1.487-1.497h6.544c.822 0 1.488.676 1.488 1.497 0 .821-.666 1.496-1.488 1.496"/>
            </g>
        </svg>
    )
})

export { Box }