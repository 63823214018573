import React from "react";
import { Link } from "react-router-dom";
import { connect } from 'react-redux'
import classnames from 'classnames';
import { UncontrolledCollapse, NavbarBrand, Navbar, NavLink, Nav, Row, Col } from "reactstrap";
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { isMobile } from 'react-device-detect';

class PrideNavAdmin extends React.Component {
  state = {}
  componentDidMount() {
  }
  _renderNavElements = () => {
    const { pathname } = this.props.location;

    return (
      <React.Fragment>

        <NavLink to="/admin/notices" tag={Link} className={classnames({ active: (pathname === '/admin/notices') })}> destaques </NavLink>
        <span className="shape-circle"> • </span>
        <NavLink to="/admin/events" tag={Link} className={classnames({ active: (pathname === '/admin/events') })}> ações </NavLink>
        <span className="shape-circle"> • </span>
        <NavLink to="/admin/faqs" tag={Link} className={classnames({ active: (pathname === '/admin/faqs') })}> faqs </NavLink>
        <span className="shape-circle"> • </span>
        <NavLink to="/admin/sections" tag={Link} className={classnames({ active: (pathname === '/admin/sections') })}> secções </NavLink>
        <span className="shape-circle"> • </span>
        <NavLink to="/admin/users" tag={Link} className={classnames({ active: (pathname === '/admin/users') })}> utilizadores </NavLink>
      </React.Fragment>
    )
  }
  render() {

    if (isMobile) { // redirect forbidden
      this.props.history.push('/forbidden');
    }

    const { user, signOut, isLoading } = this.props;
    return (
      <>
        <header className="header-global">
          <Navbar
            className={`navbar-main navbar-light navbar-app fixed-top admin`} expand="lg" id="navbar-app">
            {!isLoading ? (
              <React.Fragment>
                <Nav className="navbar-nav-hover align-items-lg-center navbar-user" navbar>
                  <NavLink className="avatar-container" style={{ cursor: "unset" }}>
                    <div className="profile-details">
                      <div className="name">{user.name}</div>
                      <div className="job">Administrador</div>
                    </div>
                  </NavLink>
                </Nav>
                <button className="navbar-toggler" id="navbar_global">
                  <span className="navbar-toggler-icon" />
                </button>
                <UncontrolledCollapse navbar toggler="#navbar_global">
                  <div className="navbar-collapse-header">
                    <Row>
                      <Col className="collapse-brand" xs="6">
                        <Link to="/">
                          <img alt="logo" src={require("assets/img/brand/logo-bold/logotipo.png")} />
                        </Link>
                      </Col>
                      <Col className="collapse-close" xs="6">
                        <button className="navbar-toggler" id="navbar_global">
                          <span />
                          <span />
                        </button>
                      </Col>
                    </Row>
                  </div>
                  <Nav className="navbar-nav navbar-center">
                    <this._renderNavElements />
                  </Nav>
                  <Nav className="align-items-lg-center ml-lg-auto navbar-right" navbar>
                    <NavbarBrand to={"/"} onClick={() => signOut()} tag={Link}>
                      <img alt="logo" src={require("assets/img/brand/logo-bold/logotipo.png")} />
                    </NavbarBrand>
                  </Nav>
                </UncontrolledCollapse>
              </React.Fragment>
            ) : (
                null
              )}
          </Navbar>
        </header>
      </>
    );
  }
}

PrideNavAdmin.propTypes = {
  user: PropTypes.object,
  signOut: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired
}

const mapStateToProps = (state, ownProps) => {
  return {
    auth: state.auth,
  }
}
const mapDispatchToProps = (dispatch) => {
  return {}
}

let NavbarPride = connect(mapStateToProps, mapDispatchToProps)(PrideNavAdmin);
export default withRouter(NavbarPride);
