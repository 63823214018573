import React from "react";
import PropTypes from "prop-types";
import Fade from "react-reveal/Fade";
import { withRouter } from "react-router-dom";
import { isMobile } from "react-device-detect";

class Login extends React.Component {
  state = {
    shortcut: true,
  };
  _toggleShortcut = () => {
    if (isMobile) {
      // redirect forbidden
      this.props.history.push("/forbidden");
    } else {
      this.setState({ shortcut: !this.state.shortcut });
    }
  };
  render() {
    const { toggleLogin } = this.props;
    const { shortcut } = this.state;
    //if (isMobile) return null;
    return (
      <>
        {shortcut ? (
          <div className="login-shortcut">
            <button
              className="clickable"
              onClick={() => this._toggleShortcut()}
            >
              {" "}
              Login
            </button>
          </div>
        ) : (
          <Fade right duration={500}>
            <div className="login-section">
              <div className="header">
                <h2 className="title">Login</h2>
                <button
                  className="close-button"
                  onClick={() => this._toggleShortcut()}
                >
                  <img
                    src={require("assets/img/buttons/close.png")}
                    srcSet={require("assets/img/buttons/close@2x.png")}
                    alt="close"
                    width={45}
                    height={45}
                  />
                </button>
              </div>
              <div className="area-login">
                <a
                  className="section m-t-25"
                  role="button"
                  onClick={() => toggleLogin("therapist")}
                >
                  <div className="circle-container red">
                    <div className="circle"></div>
                  </div>
                  <div className="details-login">
                    <div className="section-title red">
                      <span>área do terapeuta</span>
                    </div>
                    <div className="section-text">terapia e cuidado médico</div>
                  </div>
                </a>
                <a
                  className="section m-t-30"
                  role="button"
                  onClick={() => toggleLogin("patient")}
                >
                  <div className="circle-container blue">
                    <div className="circle"></div>
                  </div>
                  <div className="details-login">
                    <div className="section-title blue">
                      <span>área do utente</span>
                    </div>
                    <div className="section-text">
                      orgulhosamente <br /> a cuidar de mim
                    </div>
                  </div>
                </a>
                <a
                  className="section m-t-30"
                  role="button"
                  onClick={() => toggleLogin("scientific_community")}
                >
                  <div className="circle-container orange">
                    <div className="circle"></div>
                  </div>
                  <div className="details-login">
                    <div className="section-title orange">
                      <span>
                        área da comunidade <br /> científica
                      </span>
                    </div>
                  </div>
                </a>
              </div>
            </div>
          </Fade>
        )}
      </>
    );
  }
}
Login.propTypes = {
  toggleLogin: PropTypes.func.isRequired,
};

export default withRouter(Login);
