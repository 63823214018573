import React from "react";
import { Container, Row, Col } from "reactstrap";
import Fade from 'react-reveal/Fade';
import { getProfile, getNotifications } from "services/requests";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux'
import { UpdateProfile } from "store/actions/auth.actions";
import { throwErrorMessage } from 'helpers'
import { Avatar } from "components/Common"
import EditProfileForm from "views/forms/EditProfileForm";
import { Close } from "components/icons"

class Profile extends React.Component {
    state = {
        profile: {},
        loading: false,
        notifications: []
    }
    componentDidMount() {
        this._fetchProfile();
    }
    _fetchProfile = async(showBar = true) => {
        const { startPB, finishPB, user } = this.props;

        await this.setState({loading: true}, () => {
            if(showBar) { startPB() }
        })

        await getProfile(user.id).then(response => {
            this.setState({
                profile: response,
            })
        }).catch(error => {
            throwErrorMessage(error)
        })

        getNotifications().then(response => {
            this.setState({notifications: response, loading: false}, () => {
                if(showBar) { finishPB() }
            })
        })

    }
    _updateAvatar = (avatar) => {
        const { profile } = this.state;
        profile.avatar = avatar;
        this.setState({profile})
    }
    _updateProfile = (profile) => {
        this.props.UpdateProfile(profile.name, profile.avatar, profile.info)
    }
    _renderNotifications = () => {
        const { notifications } = this.state;
        return notifications.map(notification => {
            return (
                <li key={notification.id}> 
                    <button className="text">{notification.description}</button>
                    <button className="destroy"><Close /></button>
                </li>
            )
        })
    }
    openDialogAvatar = () => {
        document.getElementById('avatar-dialog').click();
    }
    render() {
        const { color, isLoading, history, role } = this.props;
        const { profile } = this.state;
        if(isLoading) {
            return ( <div className="loading-div"></div> )
        }
        return (
            <div className="position-relative">
                {/* shape Hero */}
                <section className={`section section-shaped section-app`}>
                <div className={`shape shape-style-1 shape-app ${color}`}>
                    <span className="bottom-right-profile" />
                </div>
                <Fade>
                    <Container className="d-flex">
                        <Row style={{width: '100%'}}>
                            <button className="button-back-profile" type="button" onClick={() => history.goBack()}>
                                <img src={require("assets/img/buttons/back.png")} alt="close" width={91} height={89} />
                            </button>
                            <Col lg="10" className={`col-profile ${color}`} md="12">
                                <Row>
                                    <Col lg="3" sm="12" className={`header-profile ${color}`}>
                                        <div className="avatar-container clickable" onClick={this.openDialogAvatar}>
                                            <Avatar url={profile.avatar} color={profile.color} size={182} />
                                        </div>
                                        {/* <div className="notifications">
                                            <h2>Notificações</h2>
                                            <ul>
                                                <this._renderNotifications/>
                                            </ul>
                                        </div> */}
                                    </Col>
                                    <Col lg="9" sm="12" className={`profile-right ${color}`}>
                                        <div className="profile-details">
                                            <h1><span>•</span>{profile.name}</h1>
                                            <p className="job">{profile.info} </p>
                                        </div>
                                        <EditProfileForm color={color} profile={profile} role={role} updateAvatar={this._updateAvatar} updateProfile={this._updateProfile} />
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Container>
                </Fade>
                </section>
            </div>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return {}
}
const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({UpdateProfile}, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(Profile);